import { useEffect } from "react";

function innerPopStateFunction() {
  // when the user goes back in the history stack,
  // move back to the current page
  window.history.forward();
}

const BackButtonDisabler = (props) => {
  useEffect(() => {
    if (window.onpopstate !== innerPopStateFunction) {
      const savedOnPopState = window.onpopstate;
      window.onpopstate = () => innerPopStateFunction();

      return () => {
        window.onpopstate = savedOnPopState || null;
      };
    }
  });

  return props.children;
};

export default BackButtonDisabler;
