const PREFIX = "/jasmine";
const FLOW = "jas";

const JasminePages = {
  prefix: PREFIX,
  flow: FLOW,

  Main: PREFIX + "/main",
  PendingActivation: PREFIX + "/pendingActivation",
  ActivationFailed: PREFIX + "/activationFailed",
  Retry: PREFIX + "/retryPage",
  TestPages: {
    ErrorPageTest: PREFIX + "/errorPageTest",
    ResultPageTest: PREFIX + "/resultPageTest",
    TestSubscriptionCard: PREFIX + "/testSubscriptionCard",
  },
  OnDeviceTest: PREFIX + "/onDeviceTests",
  GetDeviceDetails: PREFIX + "/getDeviceDetails",
  SubmitDeviceDetails: PREFIX + "/submitDeviceDetails",
  NewSubscription: {
    path: PREFIX + "/newSubscription/",
    SelectPlan: {
      relative: "selectPlan",
      full: PREFIX + "/newSubscription/selectPlan",
    },
    PersonalInformation: {
      relative: "personalInformation",
      full: PREFIX + "/newSubscription/personalInformation",
    },
    HomeAddress: {
      relative: "homeAddress",
      full: PREFIX + "/newSubscription/homeAddress",
    },
    ContactDetails: {
      relative: "contactDetails",
      full: PREFIX + "/newSubscription/contactDetails",
    },
    OTPVerification: {
      relative: "verifyOTP",
      full: PREFIX + "/newSubscription/verifyOTP",
    },
    CreatePassword: {
      relative: "createPassword",
      full: PREFIX + "/newSubscription/createPassword",
    },
    PaymentDetails: {
      relative: "paymentDetails",
      full: PREFIX + "/newSubscription/paymentDetails",
    },
    Result: {
      relative: "result",
      full: PREFIX + "/newSubscription/result",
    },
    ErrorPage: {
      relative: "errorPage",
      full: PREFIX + "/newSubscription/errorPage",
    },
  },
};
JasminePages.EntryPoints = {
  Welcome: {
    full: PREFIX + "/welcome",
    hasToken: true,
    redirectTo: JasminePages.NewSubscription.SelectPlan.full,
    restoreCookiePreferences: true,
  },
  Pending: {
    full: PREFIX + "/waiting",
    redirectTo: JasminePages.PendingActivation,
    restoreCookiePreferences: true,
  },
  Failed: {
    full: PREFIX + "/error",
    redirectTo: JasminePages.ActivationFailed,
    restoreCookiePreferences: true,
  },
  Retry: {
    full: PREFIX + "/retry",
    hasToken: true,
    redirectTo: JasminePages.Retry,
    restoreCookiePreferences: true,
  },
  TestPages: {
    full: PREFIX + "/tests",
    redirectTo: PREFIX + "/testPages",
    defaultCountry: "de",
  },
  GetDeviceDetails: {
    full: PREFIX + "/get_device_details",
    redirectTo: JasminePages.GetDeviceDetails,
  },
  TestJasde: {
    //no prefix for this entry point, just to test ODA in staging
    full: "/testJasde",
    defaultCountry: "de",
    redirectTo: JasminePages.OnDeviceTest,
  },
};

export default JasminePages;
