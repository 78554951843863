import { useState } from "react";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import style from "../../styles/form.module.css";
import Button from "../button";

export default function GoogleLoader({ children, apiKey }) {
  const [googleStatus, setGoogleStatus] = useState(Status.LOADING);
  return (
    <Wrapper
      apiKey={apiKey}
      libraries={["places"]}
      callback={(status, loader) => {
        console.log(`Status is ${status}`);
        if (status !== googleStatus) {
          setGoogleStatus(status);
        }
      }}
    >
      {googleStatus === Status.LOADING ? (
        <Loading />
      ) : googleStatus === Status.FAILURE ? (
        <div className={style.container}>
          <h1>Error loading google places service</h1>
          <p>Please check your connectivity and retry</p>
          <Button
            data-link-id="google-error-retry"
            onClick={() => {
              window.location.reload();
            }}
          >
            Retry
          </Button>
        </div>
      ) : (
        children
      )}
    </Wrapper>
  );
}

function Loading() {
  throw new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 5000);
  });
}
