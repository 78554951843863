/**
 * Instana helper
 *
 * Will configure (and load) the instana lib and then will load it by adding
 * dynamically the script tag to the head of the document
 *
 */

import ConfigHelper from "../../config/config-helper";
import Logger from "../logger/logger";
import ScriptHelper from "../network/script-helper";

const INSTANA_SCRIPT_URL = "https://eum.instana.io/eum.min.js";

function getInstanaConfig(config) {
  return config?.tracking?.instana;
}

function getReportingUrl(config) {
  return getInstanaConfig(config)?.reportingUrl;
}

function getKey(config) {
  return getInstanaConfig(config)?.key;
}

function getIgnoredUrls(config) {
  return getInstanaConfig(config)?.ignoreUrls;
}

function getCaptureHeaders(config) {
  return getInstanaConfig(config)?.captureHeaders;
}

function getTrackSessions(config) {
  return getInstanaConfig(config)?.trackSessions;
}

function getSecrets(config) {
  return getInstanaConfig(config)?.secrets;
}

function parseRegexes(input) {
  let result = input;
  if (input) {
    const tbp = Array.isArray(input) ? input : [input];
    result = [];
    let tmp;
    for (const str of tbp) {
      tmp = str;
      if (str.startsWith("/")) {
        const last = str.lastIndexOf("/");
        if (last > 0) {
          const pattern = str.substring(1, last);
          const flags =
            last < str.length - 1 ? str.substring(last + 1) : undefined;
          try {
            tmp = new RegExp(pattern, flags);
          } catch (error) {
            Logger.d(`invalid regex while parsing instana conf: "${str}"`);
          }
        }
      }
      result.push(tmp);
    }
  }
  return result;
}

function executeCommand(...args) {
  // eslint-disable-next-line no-undef
  if (ConfigHelper.isInstanaEnabled() && typeof ineum === "function") {
    Logger.d("Execute ineum with parameters: ", ...args);
    // eslint-disable-next-line no-undef
    ineum(...args);
    return true;
  }
}

export const enableTracking = (config) => {
  Logger.d("Setting up in-session...");
  getTrackSessions(config) && executeCommand("trackSessions");
  getTrackSessions(config)
    ? Logger.d("in-session is set up!")
    : Logger.d("in-session disabled by config");
};

export function setupInstana(config, flow, callback) {
  //check if instana is configured
  const reportingUrl = getReportingUrl(config);
  const key = getKey(config);
  const ignoreUrls = parseRegexes(getIgnoredUrls(config));
  const captureHeaders = parseRegexes(getCaptureHeaders(config));
  const secrets = parseRegexes(getSecrets(config));

  if (ConfigHelper.isInstanaEnabled() && reportingUrl && key) {
    //check if any instana script has already been loaded
    if (ScriptHelper.isLoaded(INSTANA_SCRIPT_URL)) {
      return false;
    } else if (ScriptHelper.isLoading(INSTANA_SCRIPT_URL)) {
      //already existing, so the setup should have been done earlier
      return true;
    } else {
      //add the inline script
      (function (s, t, a, n) {
        // eslint-disable-next-line no-unused-expressions
        s[t] ||
          ((s[t] = a),
          (n = s[a] =
            function () {
              n.q.push(arguments);
            }),
          (n.q = []),
          (n.v = 2),
          (n.l = 1 * new Date()));
      })(window, "InstanaEumObject", "ineum");

      //setup the values and start script loading
      executeCommand("reportingUrl", reportingUrl);
      executeCommand("key", key);
      executeCommand("meta", "version", ConfigHelper.packageVersion);
      if (ignoreUrls) {
        executeCommand("ignoreUrls", ignoreUrls);
      }
      if (captureHeaders) {
        executeCommand("captureHeaders", captureHeaders);
      }
      if (secrets) {
        executeCommand("secrets", secrets);
      }
      if (flow) {
        setMetaFlow(flow);
      }

      //create the script loading tag and appends it to the head of the document
      ScriptHelper.loadScript(INSTANA_SCRIPT_URL, callback, true, "anonymous");
      return true;
    }
  }
}

export function setMetaFlow(flow) {
  Logger.d(`Setting flow to "${flow}"`);
  return executeCommand("meta", "flow", flow);
}

/**
 *
 * @param  {...any} data eventName, {eventData}
 */
export function reportEvent(eventName, data) {
  Logger.d("Reporting event", eventName, JSON.stringify(data));
  executeCommand("reportEvent", eventName, data);
}

/**
 *
 * @param  {...any} data error, {opts}
 */
export function reportError(error, opts) {
  Logger.d("Reporting error", error, JSON.stringify(opts));
  executeCommand("reportError", error, opts);
}

export function reportPageEvent(pageName) {
  Logger.d("Reporting page event", pageName);
  executeCommand("page", pageName);
}
