import createDefaultTextValidator from "./default-text-validator";
import createValidator from "./validator";

export function createNameValidator(kind, fullName) {
  const textValidator = createDefaultTextValidator(
    `Short_${kind}_name_input_error`,
    `Invalid_${kind}_name_input_error`
  );

  return createValidator(
    (value, focused, minLength, maxLength, required, { fullName, overlay }) => {
      const defaultRes = textValidator.validationFunction(
        value,
        focused,
        minLength,
        maxLength,
        required
      );

      if (overlay) return;
      if (!fullName || defaultRes) return defaultRes;
      else {
        const names = fullName.split(" ");
        const name =
          kind === "last"
            ? names[names.length - 1]
            : names.slice(0, names.length - 1).join(" ");

        if (!focused && name !== value)
          return {
            messageId: `${
              kind === "first" ? "First" : "Last"
            }_name_not_matching_input_error`,
          };
        else return;
      }
    },
    textValidator.submittableFunction,
    { fullName, overlay: false }
  );
}
