import Card from "../../../components/card";
import NavBar from "../../../components/navbar";

import pageStyle from "../../../styles/page.module.css";
import Footer from "../../../components/footer";
import Button from "../../../components/button";
import { useState } from "react";
import { ResultPage } from "../newSubscription/result";

/**
 * Show a navbar, content div, footer and switch between Content and Suspense spinner
 * @returns error page test component
 */
export default function TestResultPage({ pages }) {
  const statuses = {
    pending: "pending",
    requestSuccessful: "success",
    error: "error",
  };

  const [status, setStatus] = useState();
  if (status) return <ResultPage pages={pages} status={status} />;
  return (
    <div className={pageStyle.page} data-testid="fetchTestPage">
      <NavBar />
      <div className={`${pageStyle.content}`}>
        <h2>Result page test</h2>
        <h3> Refresh the page to return here </h3>
        <Card>
          {Object.keys(statuses).map((s) => (
            <Button
              style={{ marginBottom: "8px" }}
              onClick={() => setStatus(statuses[s])}
              key={s}
            >
              {s} ({statuses[s]})
            </Button>
          ))}
        </Card>
      </div>
      <Footer />
    </div>
  );
}
