import { useContext } from "react";
import NetworkContext from "../../contexts/network-context";

const GLOBAL_CONTEXT_ID = "globalCtx";

export default function useNetwork() {
  const { addNetworkCall, removeNetworkCall, isLoading, contextId } =
    useContext(NetworkContext);
  return {
    checkNetworkCall(tag, global, throwPromise = true) {
      const call = addNetworkCall(
        global ? GLOBAL_CONTEXT_ID : contextId,
        tag,
        null,
        false
      );
      if (call === null && throwPromise) throw new Promise();
      return call;
    },
    startNetworkCall(tag, fun, global, cache) {
      return addNetworkCall(
        global ? GLOBAL_CONTEXT_ID : contextId,
        tag,
        fun,
        true,
        cache
      );
    },
    removeNetworkCall(tag, global) {
      return removeNetworkCall(global ? GLOBAL_CONTEXT_ID : contextId, tag);
    },
    isLoading(tag, global) {
      return isLoading(global ? GLOBAL_CONTEXT_ID : contextId, tag);
    },
  };
}
