import PropTypes from "prop-types";
import React from "react";

import style from "../styles/buttons.module.css";
import EventsHelper from "./tracking/events-helper";

const Button = (props) => {
  let buttonClass;
  const {
    className,
    variant,
    page,
    pageParams,
    onClick,
    to,
    smapiPageName,
    disablePrevent,
    href,
    external,
    eventMeta,
    ...others
  } = props;

  const disabled =
    variant === "disabled" ||
    (others?.disabled && others.disabled !== "false") ||
    (className &&
      className
        .split(/\s+/)
        .filter((value) => value?.toLowerCase() === "disabled").length > 1);
  let button = true;
  if (disabled) {
    //button disabled
    buttonClass = style.disabled;
    if (variant?.toLowerCase() === "link") {
      buttonClass += ` ${style.link}`;
      button = false;
    }
  } else {
    switch (variant?.toLowerCase()) {
      case "link":
        buttonClass = style.link;
        button = false;
        break;
      case "secondary":
        buttonClass = style.secondary;
        break;
      case "outline":
      case "tertiary":
        buttonClass = style.outline;
        break;
      case "primary":
      default:
        //default is red
        buttonClass = style.primary;
    }
  }
  const clickHandler = (event) => {
    let shouldPreventDisable;
    if (disabled) {
      shouldPreventDisable = true;
    } else {
      // generate click event
      const id = props["data-link-id"] || props["data-testid"] || "no-id";
      EventsHelper.clickEvent(
        id,
        eventMeta || (to ? { href: to } : href ? { href } : null)
      );
      // generate SMAPI click event
      // const dataLink = props["data-link-id"];
      // Smapi.generateClickEvent(
      //   "A",
      //   window.location.toString(),
      //   smapiPageName || Helper.getUrlParameter(RoutingPars.PAGE),
      //   dataLink
      // );
      if (onClick) {
        //custom onclick event
        // Logger.v("custom onclick", onClick, event);
        onClick(event);
        shouldPreventDisable = true;
      }
      if (!event.defaultPrevented) {
        if (to) {
          //redirect to url
          // Logger.v("LinkButton redirecting to url " + to);
          // Helper.redirectUrl(to);
          shouldPreventDisable = true;
          // } else if (page) {
          //   //redirect to page
          //   Logger.v(
          //     "LinkButton redirecting to page " +
          //       page +
          //       " with params " +
          //       pageParams
          //   );
          //   Helper.redirectPage(page, pageParams);
          //   shouldPreventDisable = true;
        }
      }
    }
    if (
      shouldPreventDisable &&
      event &&
      event.preventDefault &&
      !disablePrevent
    ) {
      // Logger.v("Preventing default behavior");
      event.preventDefault();
    }
  };
  return (
    <a
      className={`${button ? style.button : ""} ${buttonClass} ${
        className || ""
      }`}
      onClick={clickHandler}
      href={href || "#"}
      disabled={disabled}
      download={props.download}
      rel={external ? "noopener noreferrer" : ""}
      target={external ? "_blank" : "_self"}
      {...others}
    >
      {props.children}
    </a>
  );
};
Button.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  "data-link-id": PropTypes.string,
  smapiPageName: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  page: PropTypes.string,
  href: PropTypes.string,
  external: PropTypes.bool,
  pageParams: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disablePrevent: PropTypes.bool,
  children: PropTypes.node,
};
export default Button;
