import React from "react";
import { useLocation } from "react-router-dom";
import Logger from "../components/logger/logger";
import wrapPromise from "../components/network/wrapper";
import NetworkContext from "../contexts/network-context";

const callsMap = new Map();

const callsHandler = {
  lastContextId: null,
  addCall: function (contextId, tag, fun, start, cache = true) {
    Logger.v(
      (start ? "Adding" : "Checking") + " network call...",
      contextId,
      tag,
      start ? (cache ? "cache" : "no-cache") : ""
    );
    if (fun instanceof Promise) {
      throw new Error(
        "Promise not supported, addCall should receive a function that returns a Promise"
      );
    }
    let calls = callsMap.get(contextId);
    if (!calls) {
      Logger.v("Creating new call map for context id", contextId);
      //create new calls map for that contextId
      calls = new Map();
      callsMap.set(contextId, calls);
    }
    //get the call with that tag for that contextId
    const entry = calls.get(tag);
    if (entry && cache) {
      Logger.v(
        `Network entry already found ${contextId} ${tag} ${JSON.stringify(
          entry.wrapped
        )}`
      );
      //Check if already started, otherwise rewrap with the new promise
      if (start && !entry.wrapped.isStarted()) {
        entry.wrapped.rewrap(fun(), start);
        Logger.v(
          `Starting new network entry ${contextId} ${tag} ${entry.wrapped.getStatus()}`
        );
      }
      //return the wrapped promise (already existing)
      return entry.wrapped;
    } else {
      try {
        Logger.v(`Creating new network entry ${contextId} ${tag}`);
        const newEntry = {};
        calls.set(tag, newEntry);
        newEntry.wrapped = wrapPromise(
          fun ? fun() : new Promise(() => {}),
          start
        );
        Logger.v(
          `New network entry ${contextId} ${tag} ${newEntry.wrapped.getStatus()}`
        );
        return newEntry.wrapped;
      } catch (error) {
        Logger.e("Error adding call " + tag, error?.message);
        throw new Error(`Error adding call: ${tag} : ${error.message}`);
      }
    }
  },
  removeCall: function (contextId, tag) {
    Logger.v("Removing network call...", contextId, tag);
    const calls = callsMap.get(contextId);
    if (calls?.size > 0) {
      calls.delete(tag);
      if (calls.size === 0) {
        callsMap.delete(contextId);
      }
    }
  },
  removeCalls: function (contextId) {
    Logger.v("Removing all network calls...", contextId);
    callsMap.delete(contextId);
  },
  isCallsLoading(calls) {
    if (calls) {
      for (const [, c] of calls) {
        try {
          c.wrapped.read();
        } catch (error) {
          if (error instanceof Promise) {
            return true;
          }
        }
      }
    }
    return false;
  },
  isLoading: function (contextId, tag) {
    if (tag) {
      return callsHandler.isCallsLoading(callsMap.get(contextId));
    } else {
      for (const [, calls] of callsMap) {
        if (callsHandler.isCallsLoading(calls)) {
          return true;
        }
      }
      return false;
    }
  },
};

export default function NetworkHandler(props) {
  const location = useLocation();
  const contextId = location.pathname + location.search;
  if (callsHandler.lastContextId !== contextId) {
    callsHandler.lastContextId = contextId;
    callsHandler.removeCalls(contextId);
  }
  return (
    <NetworkContext.Provider
      value={{
        addNetworkCall: (...args) => callsHandler.addCall(...args),
        removeNetworkCall: (...args) => callsHandler.removeCall(...args),
        isLoading: (...args) => !!callsHandler.isLoading(...args),
        contextId,
      }}
    >
      {props.children}
    </NetworkContext.Provider>
  );
}
