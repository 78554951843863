import React, { useEffect, useState } from "react";
import FlowContext from "../contexts/flow-context";
import EventsHelper from "../components/tracking/events-helper";
import Flows from "../config/flows";
import Logger from "../components/logger/logger";
import { useLocation } from "react-router-dom";

function findPage(location, pages) {
  return Object.keys(pages).find((key) => {
    const page = pages[key];
    if (typeof page === "string") {
      if (location.pathname === page) {
        return true;
      } else return false;
    } else return findPage(location, page);
  });
}

function detectFlow(location) {
  //try to find a specific page as declared in the pages of the flow
  const flowFromPages = Object.keys(Flows).find((key) => {
    const page = findPage(location, Flows[key].pages);
    if (page) {
      //found a specific page
      Logger.d(`Found flow "${key}" from specific page "${page}"`);
      return true;
    } else return false;
  });
  if (flowFromPages) {
    //found a specific page as defined in a flow pages
    return flowFromPages;
  } else {
    //not found, try to infer from the prefix
    const flow = Object.keys(Flows).find((key) => {
      const pages = Flows[key]?.pages;
      if (pages) {
        if (pages.prefix) {
          return location?.pathname?.startsWith(pages.prefix);
        } else {
          //if no prefix specified, it's the default flow
          return true;
        }
      } else return false;
    });
    if (flow) {
      Logger.d(`Found flow "${flow}" based on prefix`);
    }
    return flow;
  }
}

function loadFlow(newFlow) {
  //handle css themes loading
  const flowTheme = getComputedStyle(document.documentElement).getPropertyValue(
    "--flow-theme"
  );
  if (flowTheme !== newFlow) {
    if (flowTheme) {
      //SHOULD NEVER HAPPEN!!!
      //fire an event and schedule a hard refresh
      EventsHelper.error(
        new Error(
          `Changing flow theme to "${newFlow}" but found a pre-existing theme "${flowTheme}"`
        )
      );
      setTimeout(() => {
        //hard refresh
        window.location.reload();
      });
    } else {
      //load new theme
      if (Flows[newFlow]?.loadTheme) {
        Flows[newFlow]?.loadTheme();
      } else {
        Logger.d("Cannot load a theme for " + newFlow);
      }
    }
  }
}

export default function FlowHandler(props) {
  const [flow, setFlow] = useState(null);
  const location = useLocation();

  useEffect(() => {
    //detect flow
    const found = detectFlow(location);
    //if needed, change flow based on location
    if (found !== flow) {
      Logger.d("Changing flow based on location", flow, found);
      setFlow(found);
      EventsHelper.setFlow(found);
      loadFlow(found);
    }
  }, [location, flow]);
  if (flow === null) {
    Logger.d("Flow handler setup in progress");
  }
  return (
    <FlowContext.Provider
      value={{
        flow,
        setFlow: (newFlow) => {
          if (newFlow !== flow) {
            Logger.d("Changing flow", flow, newFlow);
            setFlow(newFlow);
            EventsHelper.setFlow(newFlow);
            loadFlow(newFlow);
          }
        },
        getFlowPages: () => {
          return Flows[flow]?.pages;
        },
      }}
    >
      {props.children}
    </FlowContext.Provider>
  );
}
