import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "normalize.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ConfigHelper from "./config/config-helper";
import "focus-within-polyfill";

//lazy load package json and when finished modify meta version.
//Could be lazy because it's not important to have this information quickly
import("../package.json").then((packageJson) => {
  // this snippet will inject the product version into the HTML meta tag with name version
  try {
    Array.from(document.getElementsByTagName("meta")).find((meta) => {
      return meta && meta.name === "version";
    }).content = packageJson.version;
  } catch (error) {
    console.log("Cannot override meta element with version", error);
  }
  // Saves the version in the config helper statically
  ConfigHelper.packageVersion = packageJson.version;
});

/* istanbul ignore file */
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
