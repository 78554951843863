import React, { useContext, useEffect, useRef, useState } from "react";

import style from "../styles/input-text.module.css";
import { ThemeContext } from "../contexts/theme-context";
import XIndicator from "./icons/x-indicator";

const InputText = (props, ref) => {
  let disabledClass, image;
  const myRef = useRef(null);
  const {
    className,
    type,
    inputClassName,
    hideIcons,
    hint,
    label,
    forceShowLabel,
    hidePlaceholder,
    showPencilIcon,
    value,
    action,
    onChange,
    focusedHint,
    minLength,
    maxLength,
    showErrorsWhenFocused,
    keepIconOnBlur,
    xIndicatorVariant,
    onClear,
    onKeyDown,
    wrapperRef,
    "data-testid": dataTestId,
    errorMessage,
    onBlur,
    onBlurCapture,
    ...others
  } = props;
  const { theme } = useContext(ThemeContext);
  let showLabel = useRef(value?.length === 0);
  const [showIcon, setShowIcon] = useState(true);
  const [keyRegister, setKeyRegister] = useState([null, null, null]);
  const keyTimeout = useRef(null);

  useEffect(() => {
    showLabel.current = value.length > 0 || forceShowLabel;
  }, [value, showLabel, forceShowLabel]);

  const disabled =
    (others?.disabled && others.disabled !== "false") ||
    (className &&
      className
        .split(/\s+/)
        .filter((value) => value?.toLowerCase() === "disabled").length > 1);
  if (disabled) {
    //button disabled
    disabledClass = style.disabled;
  }
  const focused = document.activeElement === (ref?.current || myRef.current);
  const errorClass = disabled || errorMessage ? style.error : undefined;

  // show the icon only if focused and non-empty
  const imageVisible = !hideIcons && showIcon && focused && value;
  if (imageVisible) {
    image = (
      <XIndicator
        className={style.icon}
        variant={
          xIndicatorVariant ??
          (theme === "dark" ? "white" : errorMessage ? "red" : "grey")
        }
        data-testid="x-indicator-icon"
        onPointerDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onChange({ target: { value: "" } });
          onClear?.();
        }}
      />
    );
  }

  const hintMessage = errorMessage || (focused && focusedHint) || hint;

  return (
    <div
      className={`${style.inputText} ${className || ""} ${
        disabledClass || ""
      } ${errorClass || ""}`}
      data-testid={dataTestId}
      onFocus={() => {
        setShowIcon(true);
      }}
      onBlur={() => {
        if (!keepIconOnBlur) setShowIcon(false);
      }}
    >
      <div className={style.inputWrapper}>
        <div className={style.input} data-testid="input-wrapper">
          {action && action.position === "left" && (
            <div className={style.leftIconContainer}> {action.content}</div>
          )}
          <input
            aria-autocomplete="none"
            ref={ref || myRef}
            type={type || "text"}
            value={value}
            minLength={minLength?.toString()}
            maxLength={maxLength?.toString()}
            className={`${disabledClass || ""} ${inputClassName || ""}`}
            placeholder={hidePlaceholder ? "" : label}
            error={errorMessage ? "true" : null}
            onChange={onChange}
            // use the capture variant,
            // since the blur event might prevent a click event to be fired
            // on another element
            onBlurCapture={(e) => {
              e.stopPropagation();
              onBlurCapture?.(e);
              onBlur?.(e);
            }}
            onKeyDown={(e) => {
              clearTimeout(keyTimeout.current);
              const reg = [keyRegister[1], keyRegister[2], e.key];
              const cursor = (ref || myRef).current.selectionStart;

              const handleCombination = (char) => {
                const value =
                  e.target.value.slice(0, cursor) +
                  char +
                  e.target.value.slice(cursor);
                (ref || myRef).current.value = value;
                onChange({
                  ...new Event("input", { bubbles: true }),
                  target: (ref || myRef).current,
                });

                (ref || myRef).current.setSelectionRange(
                  cursor + 1,
                  cursor + 1
                );
              };

              // capture Ctrl+Alt / AltGr + ... combinations
              if (
                (reg[0] === "Control" && reg[1] === "Alt") ||
                (reg[0] === "Alt" && reg[1] === "Control")
              ) {
                switch (reg[2]) {
                  // equivalent to @
                  case "q":
                    handleCombination("@");
                    break;

                  // equivalent to €
                  case "4":
                  case "e":
                    handleCombination("€");
                    break;

                  default:
                    break;
                }
              }
              setKeyRegister(reg);
              // clear the register to avoid
              // unwanted key combinations
              keyTimeout.current = setTimeout(
                () => setKeyRegister([null, null, null]),
                500
              );
              onKeyDown?.(e);
            }}
            {...others}
            data-testid="input"
          />
          <div className={style.iconContainer}>
            {!hideIcons && image}
            {action && action.position !== "left" && action.content}
          </div>
        </div>
        {label && (value || forceShowLabel) && (
          <div className={style.label} data-testid="label">
            {label}
          </div>
        )}
      </div>
      {hintMessage ? (
        <div className={style.hint} data-testid="hint">
          {hintMessage}
        </div>
      ) : null}
    </div>
  );
};
export default React.forwardRef(InputText);
