import React, { useContext } from "react";
import Logger from "../components/logger/logger";
import fetchConfigData from "../components/network/config";
import useNetwork from "../components/network/use-network";
import EventsHelper from "../components/tracking/events-helper";
import ConfigHelper from "../config/config-helper";
import ConfigContext from "../contexts/config-context";
import { ErrorPage } from "../pages/common/newSubscription/errorPage";
import FlowContext from "../contexts/flow-context";

const updateLocalConfig = (config) => {
  //good result, apply changes to LocalConfig
  const localConfig = ConfigHelper.getLocalConfig(true);
  if (config.local) {
    for (const key of Object.keys(config.local)) {
      const value = config.local[key];
      if (
        value !== undefined &&
        ["string", "boolean", "number"].includes(typeof value)
      ) {
        localConfig[key] = value;
        Logger.v(`Configuration ${key} changed to ${value}`);
      }
    }
  }
};

export default function ConfigHandler(props) {
  const { startNetworkCall } = useNetwork();
  const { flow } = useContext(FlowContext);
  const data = startNetworkCall(
    "config",
    () => fetchConfigData(),
    true,
    true,
    (result) => {
      Logger.d("Config call finished, got", result);
      if (!(result instanceof Error)) {
        updateLocalConfig(result);
      }
    }
  );
  let config;
  try {
    config = data?.read();
    if (config instanceof Error) {
      throw config;
    }
    //update local config
    updateLocalConfig(config);
    //Setup events handler based on the configuration
    EventsHelper.setup(config, flow);
  } catch (err) {
    if (err instanceof Promise) {
      throw err;
    } else {
      Logger.e(err);
      return <ErrorPage status={404} />;
    }
  }
  return (
    <ConfigContext.Provider
      value={{
        config,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
}
