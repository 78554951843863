import Logger from "../logger/logger";
import {
  setupInstana,
  setMetaFlow,
  reportEvent,
  reportError,
  reportPageEvent,
} from "./instana";

const status = {
  loading: false,
  loadingFlow: null,
};

export default class EventsHelper {
  static setup(config, flow) {
    //setup instana if we have instana config url and key
    status.loading = setupInstana(
      config,
      flow || status.loadingFlow,
      (event) => {
        status.loading = false;
        if (status.loadingFlow) {
          setMetaFlow(status.loadingFlow);
        }
      }
    );

    if (status.loading) {
      //set the loading status with 100ms intervals
      Logger.d("Events handler setup in progress");
      throw new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, 100);
      });
    }
  }

  static setFlow(flow) {
    if (status.loading) {
      status.loadingFlow = flow;
    } else {
      if (!setMetaFlow(flow)) {
        //not yet loaded or disabled, try to preset the loading flow just in case
        status.loadingFlow = flow;
      }
    }
  }

  static clickEvent(id, metadata) {
    const meta = metadata || {};
    meta.id = id;
    meta.location = window.location.toString().split("?")[0];
    //use instana
    reportEvent("click", {
      timestamp: Date.now(),
      meta: meta,
    });
  }

  static pageEvent(page) {
    reportPageEvent(page);
  }

  static debugEvent(message) {
    reportEvent("debug", {
      timestamp: Date.now(),
      meta: {
        message: message,
        location: window.location.toString().split("?")[0],
      },
    });
  }

  static error(error, message) {
    //use instana
    const opts = {
      timestamp: Date.now(),
      meta: {
        location: window.location.toString().split("?")[0],
      },
    };
    if (message) {
      opts.meta.message = message;
    }
    reportError(error, opts);
  }
}
