import React from "react";

const NetworkContext = React.createContext({
  // eslint-disable-next-line no-unused-vars
  addNetworkCall: (context, tag, fun, start, cache, callback) => {
    throw new Error("Invalid NetworkContext");
  },
  // eslint-disable-next-line no-unused-vars
  removeNetworkCall: (context, tag, fun) => {
    throw new Error("Invalid NetworkContext");
  },
  // eslint-disable-next-line no-unused-vars
  isLoading: (context, tag) => {
    throw new Error("Invalid NetworkContext");
  },
  contextId: null,
});

export default NetworkContext;
