import React, { forwardRef, useContext, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import LocaleContext from "../contexts/locale-context";
import { ThemeContext } from "../contexts/theme-context";
import FormattedMsg from "../locale/components/formatted-msg";
import style from "../styles/dropdown-list.module.css";
import Chevron from "./chevron";
import Divider from "./divider";
import LensIcon from "./icons/lens-icon";
import inputStyle from "../styles/input-text.module.css";

export const DropdownList = forwardRef((props, ref) => {
  const {
    value,
    label,
    onChange,
    options,
    className,
    onFocus,
    filter,
    selectedValueToShow,
    defaultValue,
    searchFieldLabelId,
    "data-testid": dataTestId,
    mode,
  } = props;
  const { theme } = useContext(ThemeContext);
  const { formatMessage } = useContext(LocaleContext);
  const [focused, setFocus] = useState(false);

  const [displayList, setDisplayList] = useState(false);
  const [selected, setSelected] = useState(value ?? defaultValue ?? "");

  const inputRef = useRef();

  useEffect(() => {
    if (ref) ref.current = inputRef.current;
  }, [ref, inputRef]);

  useEffect(() => {
    if (focused) onFocus?.();
    // close dropdown list when clicking outside
    // listening on pointerup to prevent firing on elements of this component
    const listener = () => {
      setDisplayList(false);
      setSearchValue("");
    };
    document.addEventListener("pointerup", listener);

    return () => {
      document.removeEventListener("pointerup", listener);
    };
  }, [focused, onFocus]);

  const listContainer = useRef(null);

  const filterLambda =
    filter ??
    ((v, currentValue) =>
      !currentValue ||
      (v.label ?? v).toLowerCase().startsWith(currentValue.toLowerCase()));

  const generateOptions = (values, currentValue, onSelect) => {
    const res = values
      .filter((v) => filterLambda(v, currentValue))
      .map((v) => [
        <div
          className={style.listElement}
          key={v.label ?? v}
          // prevent firing events on underlying elements
          onPointerDownCapture={(e) => e.stopPropagation()}
          onPointerUpCapture={(e) => e.stopPropagation()}
          onClickCapture={(e) => {
            // prevent a click event to be fired on the underlying element
            onSelect(v);
            setDisplayList(false);
            setSearchValue("");
            setFocus(false);
            e.stopPropagation();
            e.preventDefault();
          }}
          data-testid={"dropdown-item-" + (v.value ?? v)}
        >
          {v.icon && <div className={style.iconContainer}> {v.icon} </div>}
          <p key={(v.label ?? v) + "-label"}>{v.label ?? v}</p>
        </div>,
        <Divider key={(v.label ?? v) + "-divider"} className={style.divider} />,
      ]);

    return res.length ? res : null;
  };

  const [searchValue, setSearchValue] = useState("");

  const wrapInInputStyle = (children) => (
    <div className={inputStyle.inputWrapper}>
      <div className={`${inputStyle.input} ${style.outline}`}>{children}</div>
      {label && <span className={inputStyle.label}>{label}</span>}
    </div>
  );

  const input = (
    <>
      <div className={style.controls}>
        <div className={style.iconContainer}>
          {selected.icon ?? selected.value ?? selected}
        </div>
        <Chevron
          className={style.icon}
          direction={displayList ? "up" : "down"}
          background={false}
          variant={theme === "dark" ? "white" : "grey"}
          key="chevron"
          data-testid="chevron-icon"
        />
        {selectedValueToShow && (
          <span className={style.showSelectedLabel} data-testid="selectedValue">
            {selectedValueToShow}
          </span>
        )}
      </div>
      <div className={`${displayList ? style.iconModeList : style.hide}`}>
        <div className={`${style.searchFieldContainer} ${style.listElement}`}>
          <LensIcon />
          <input
            aria-autocomplete="none"
            ref={inputRef}
            type="text"
            placeholder={formatMessage(searchFieldLabelId)}
            className={style.searchFieldInput}
            value={searchValue}
            data-testid="dropdown-list-search-input"
            onChange={(e) => {
              setSearchValue(e.target.value);
              listContainer.current.scrollTop = 0;
            }}
          />
        </div>
        <Divider className={style.divider} />
        <div
          className={style.iconModeElementsContainer}
          data-testid="dropdown-items-container"
          ref={listContainer}
        >
          {generateOptions(options, searchValue, (v) => {
            setSelected(v);
            setDisplayList(false);
            setFocus(false);
            setSearchValue("");
            onChange(v);
          }) ?? (
            <div
              data-testid="no-results-element"
              className={`${style.searchFieldContainer} ${style.listElement} ${style.noResultListElement}`}
            >
              <span className={style.noResultListElementText}>
                <FormattedMsg id="Dropdown_menu_error_text" />
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
  return (
    <div
      className={`${inputStyle.inputText} ${style.container} ${
        mode === "icon" ? style.iconContainer : ""
      } ${className ?? ""}`}
      data-testid={dataTestId}
      // use pointerup event for focusing issues on the input field,
      // which interfere with the list
      onPointerUp={(e) => {
        if (displayList) {
          setSearchValue("");
        }
        setDisplayList((prev) => !prev);
        setFocus((prev) => !prev);
        e.stopPropagation();
        setTimeout(() => inputRef.current.focus(), 1);
      }}
      onFocus={() => {
        setFocus(true);
        listContainer.current.scrollTop = 0;
      }}
      onBlur={(e) => {
        setFocus(false);
      }}
    >
      {mode === "icon" ? input : wrapInInputStyle(input)}
    </div>
  );
});
