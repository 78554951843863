const PREFIX = "";
const FLOW = "sf";

const SiegfriedPages = {
  prefix: PREFIX,
  flow: FLOW,

  Main: PREFIX + "/main",
  TestPages: {
    ErrorPageTest: PREFIX + "/errorPageTest",
    TestSubscriptionCard: PREFIX + "/testSubscriptionCard",
    TestResultPage: PREFIX + "/testResultPage",
  },
  NewSubscription: {
    path: PREFIX + "/newSubscription/",
    SelectPlan: {
      relative: "selectPlan",
      full: PREFIX + "/newSubscription/selectPlan",
    },
    PersonalInformation: {
      relative: "personalInformation",
      full: PREFIX + "/newSubscription/personalInformation",
    },
    HomeAddress: {
      relative: "homeAddress",
      full: PREFIX + "/newSubscription/homeAddress",
    },
    ContactDetails: {
      relative: "contactDetails",
      full: PREFIX + "/newSubscription/contactDetails",
    },
    OTPVerification: {
      relative: "verifyOTP",
      full: PREFIX + "/newSubscription/verifyOTP",
    },
    CreatePassword: {
      relative: "createPassword",
      full: PREFIX + "/newSubscription/createPassword",
    },
    PaymentDetails: {
      relative: "paymentDetails",
      full: PREFIX + "/newSubscription/paymentDetails",
    },
    Result: {
      relative: "result",
      full: PREFIX + "/newSubscription/result",
    },
    ErrorPage: {
      relative: "errorPage",
      full: PREFIX + "/newSubscription/errorPage",
    },
  },
  PendingActivation: PREFIX + "/pendingActivation",
  DownloadProfile: PREFIX + "/downloadProfile",
  DownloadSuccessful: PREFIX + "/downloadSuccessful",
  DownloadFailed: PREFIX + "/downloadFailed",
  ActivationFailed: PREFIX + "/activationFailed",
  ActivateWithQRCode: PREFIX + "/activateWithQr",
  PrivacyPolicy: PREFIX + "/privacyPolicy",
  Retry: PREFIX + "/retryPage",
};
SiegfriedPages.EntryPoints = {
  Welcome: {
    full: PREFIX + "/welcome",
    hasToken: true,
    redirectTo: SiegfriedPages.Main,
  },
  Pending: {
    full: PREFIX + "/waiting",
    redirectTo: SiegfriedPages.PendingActivation,
  },
  Install: {
    full: PREFIX + "/install",
    hasToken: true,
    redirectTo: SiegfriedPages.DownloadProfile,
  },
  Failed: {
    full: PREFIX + "/error",
    redirectTo: SiegfriedPages.ActivationFailed,
  },
  Retry: {
    full: PREFIX + "/retry",
    hasToken: true,
    redirectTo: SiegfriedPages.Retry,
  },
  TestPages: {
    full: PREFIX + "/tests",
    redirectTo: "/testPages",
    defaultCountry: "de",
  },
};

export default SiegfriedPages;
