import React from "react";
import FormattedMsg from "../locale/components/formatted-msg";
import VFLogo from "./icons/vf-logo";

import style from "../styles/hero.module.css";

const Hero = (props) => {
  const {
    className,
    showLogo,
    logo,
    textId,
    textMessage,
    logoVariant,
    ...others
  } = props;
  return (
    <div className={`${style.hero} ${className || ""}`} {...others}>
      {showLogo &&
        (logo || (
          <VFLogo className={style.logo} variant={logoVariant || "white"} />
        ))}
      {(textId || textMessage) && (
        <>
          <p className={style.title}>
            {textId ? <FormattedMsg id={textId} /> : textMessage}
          </p>
        </>
      )}
      {props.children}
    </div>
  );
};
export default Hero;
