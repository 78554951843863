import React from "react";

import { ReactComponent as DEIcon } from "../../images/microsoft-badge-de.svg";
import { ReactComponent as ENIcon } from "../../images/microsoft-badge-en.svg";
import Button from "../button";

const MicrosoftStoreBadge = ({ variant: reqVariant, className, ...props }) => {
  const variants = {
    en: <ENIcon {...props} />,
    de: <DEIcon {...props} />,
    default: <DEIcon {...props} />,
  };
  const variant =
    reqVariant && Object.keys(variants).includes(reqVariant)
      ? reqVariant
      : "en";
  const icon = variants[variant];
  return (
    <Button
      className={className}
      data-testid={"microsoft-store-badge-" + variant}
      href="ms-windows-store://pdp/?productid=9NBLGGH5PNB1"
      variant="link"
      external
    >
      {icon}
    </Button>
  );
};

export default MicrosoftStoreBadge;
