import { useEffect } from "react";
import EventsHelper from "../components/tracking/events-helper";
import { useLocation } from "react-router-dom";

const lastReported = {
  location: null,
};

export const PageUpdater = () => {
  const location = useLocation();
  useEffect(() => {
    //when location changed, call events helper
    if (
      !lastReported.location ||
      lastReported.location.pathname !== location.pathname ||
      lastReported.location.key !== location.key ||
      lastReported.location.state !== location.state ||
      lastReported.location.search !== location.search
    ) {
      lastReported.location = { ...location };
      EventsHelper.pageEvent(location.pathname);
    }
  }, [location]);
};
