import React from "react";

import RedIcon from "../../images/alert-triangle-red.svg";
import GreyIcon from "../../images/alert-triangle-grey.svg";
import WhiteIcon from "../../images/alert-triangle-white.svg";
import WhiteFilledIcon from "../../images/alert-triangle-white-filled.svg";
import ImageVariants from "../image-variants";

const WarningIcon = ({ filled, ...props }) => {
  const variants = {
    white: filled ? WhiteFilledIcon : WhiteIcon,
    grey: GreyIcon,
    default: RedIcon,
  };
  return <ImageVariants variants={variants} {...props} />;
};

export default WarningIcon;
