import OverlayCard from "./overlay-card";
import style from "../styles/save-overlay.module.css";
import FormattedMsg from "../locale/components/formatted-msg";
import Button from "./button";

export const SaveOverlay = ({
  titleId,
  descriptionId,
  saveButtonId,
  discardButtonId,
  cancelButtonId,
  onMainAction,
  onSecondaryAction,
  onClose,
  className,
  "data-testid": dataTestId,
}) => {
  return (
    <OverlayCard
      title={<FormattedMsg id={titleId} />}
      data-testid={dataTestId ?? "alert-banner-container"}
      alignTitleToX={true}
      className={`${style.container} ${className}`}
      closeBoxClassName={style.closeBox}
      childrenClassName={style.content}
      close={onClose}
    >
      <p className={style.description}>
        <FormattedMsg id={descriptionId} />
      </p>
      <div className={style.buttonsContainer}>
        <Button
          variant="primary"
          data-testid="save-button"
          onClick={(e) => {
            e.preventDefault();
            onMainAction(e);
          }}
        >
          <FormattedMsg id={saveButtonId} />
        </Button>
        {discardButtonId && (
          <Button
            variant="secondary"
            data-testid="discard-button"
            onClick={(e) => {
              e.preventDefault();
              onSecondaryAction(e);
            }}
          >
            <FormattedMsg id={discardButtonId} />
          </Button>
        )}
        <Button
          variant="secondary"
          data-testid="cancel-button"
          onClick={(e) => {
            e.preventDefault();
            onClose?.(e);
          }}
        >
          <FormattedMsg id={cancelButtonId} />
        </Button>
      </div>
    </OverlayCard>
  );
};
