import { lazy, useMemo } from "react";
import { useEffect } from "react";
import { forwardRef, useContext } from "react";
import LocaleContext from "../contexts/locale-context";
import { DropdownList } from "./dropdown-list";
import Helpers from "./helpers";
const CountryFlagIcon = lazy(() => import("./icons/country-flag-icon"));

export const NationDropdown = forwardRef(
  ({ value, onChange, "data-testid": dataTestId, required }, ref) => {
    const { formatMessage, localeCountryList } = useContext(LocaleContext);
    const options = [];
    const country = Helpers.getSavedCountry().name.toUpperCase();
    const opco = useMemo(
      () => ({
        value: country,
        label: Object.keys(localeCountryList).find(
          (k) => localeCountryList[k] === country
        ),
        icon: <CountryFlagIcon code={country} />,
      }),
      [country, localeCountryList]
    );

    // set the default if there is no value
    useEffect(() => {
      if (!value) onChange(opco.value);
    }, [value, opco, onChange]);

    for (const countryName in localeCountryList) {
      options.push({
        value: localeCountryList[countryName],
        label: countryName,
        icon: <CountryFlagIcon code={localeCountryList[countryName]} />,
      });
    }

    const valueLabel = Object.keys(localeCountryList).find(
      (k) => localeCountryList[k] === value
    );
    options.sort((a, b) => a.label.localeCompare(b.label));
    options.unshift(opco);
    return (
      <DropdownList
        ref={ref}
        data-testid={dataTestId || "nationality-input"}
        value={
          value
            ? {
                value,
                label: valueLabel,
                icon: <CountryFlagIcon code={value} />,
              }
            : opco
        }
        searchFieldLabelId={"Dropdown_menu_search_text"}
        selectedValueToShow={valueLabel || opco.label}
        options={options}
        defaultValue={opco}
        label={formatMessage("Nationality_input_label") + "*"}
        hint={formatMessage("Invalid_country_name_input_error_message")}
        onChange={(v) => onChange(v.value)}
        required={required}
      />
    );
  }
);
