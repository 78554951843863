import { lazy } from "react";
import { Navigate, Route } from "react-router";
import SiegfriedPages from "./siegfried-pages";
import NewSubscription from "../pages/common/newSubscription/newSubscription";
import SelectPlan from "../pages/siegfried/newSubscription/selectPlan";
import ContactDetails from "../pages/siegfried/newSubscription/contactDetails";
import { OTPVerification } from "../pages/common/newSubscription/OTPVerification";
import PersonalInformation from "../pages/common/newSubscription/personalInformation";
import { CreatePassword } from "../pages/common/newSubscription/createPassword";
import HomeAddress from "../pages/common/newSubscription/homeAddress";
import PaymentDetails from "../pages/siegfried/newSubscription/paymentDetails";
import { ResultPage } from "../pages/siegfried/newSubscription/result";
import { DownloadProfile } from "../pages/siegfried/newSubscription/downloadProfile";
import { TestSubscriptionCardPage } from "../pages/common/testPages/test-subscription-card";
import ErrorPageTest from "../pages/common/testPages/test-error-page";
import TestPages from "../pages/siegfried/testPages/testPages";
import TestResultPage from "../pages/siegfried/testPages/test-result-page";
import { ErrorPage } from "../pages/siegfried/newSubscription/errorPage";
import getEntryPointRoutes from "./entry-points";
const Main = lazy(() => import("../pages/siegfried/main"));
const ActivateQR = lazy(() => import("../pages/siegfried/activateWithQr"));

export default function getSiegfriedRoutes(testPagesEnabled) {
  return (
    <>
      {
        /* Siegfried entry points */
        getEntryPointRoutes(SiegfriedPages.EntryPoints)
      }
      {testPagesEnabled && (
        <>
          <Route
            path={SiegfriedPages.EntryPoints.TestPages.redirectTo}
            element={<TestPages pages={SiegfriedPages} />}
          />
          <Route
            path={SiegfriedPages.TestPages.ErrorPageTest}
            element={<ErrorPageTest />}
          />
          <Route
            path={SiegfriedPages.TestPages.TestSubscriptionCard}
            element={<TestSubscriptionCardPage />}
          />
          <Route
            path={SiegfriedPages.TestPages.TestResultPage}
            element={<TestResultPage />}
          />
        </>
      )}
      <Route
        path={SiegfriedPages.Main}
        element={<Main pages={SiegfriedPages} />}
      />
      <Route
        path={SiegfriedPages.PendingActivation}
        element={
          <Navigate
            to={SiegfriedPages.NewSubscription.Result.full}
            state={{ status: "pending" }}
          />
        }
      />
      <Route
        path={SiegfriedPages.Retry}
        element={
          <Navigate
            to={SiegfriedPages.NewSubscription.Result.full}
            state={{ status: "retrySubscription" }}
          />
        }
      />
      <Route
        path={SiegfriedPages.ActivationFailed}
        element={
          <ResultPage status="subscriptionFailed" pages={SiegfriedPages} />
        }
      />
      <Route
        path={SiegfriedPages.DownloadSuccessful}
        element={
          <Navigate
            to={SiegfriedPages.NewSubscription.Result.full}
            state={{ status: "downloadSuccessful" }}
          />
        }
      />
      <Route
        path={SiegfriedPages.DownloadProfile}
        element={<DownloadProfile />}
      />
      <Route
        path={SiegfriedPages.ActivateWithQRCode}
        element={<ActivateQR />}
      />
      <Route
        path={SiegfriedPages.NewSubscription.path}
        element={<NewSubscription pages={SiegfriedPages} />}
      >
        <Route
          path={SiegfriedPages.NewSubscription.SelectPlan.relative}
          element={<SelectPlan />}
        />
        <Route
          path={SiegfriedPages.NewSubscription.PersonalInformation.relative}
          element={<PersonalInformation />}
        />
        <Route
          path={SiegfriedPages.NewSubscription.HomeAddress.relative}
          element={<HomeAddress />}
        />
        <Route
          path={SiegfriedPages.NewSubscription.ContactDetails.relative}
          element={<ContactDetails />}
        />
        <Route
          path={SiegfriedPages.NewSubscription.OTPVerification.relative}
          element={<OTPVerification />}
        />
        <Route
          path={SiegfriedPages.NewSubscription.CreatePassword.relative}
          element={<CreatePassword />}
        />
        <Route
          path={SiegfriedPages.NewSubscription.PaymentDetails.relative}
          element={<PaymentDetails />}
        />
        <Route
          path={SiegfriedPages.NewSubscription.Result.relative}
          element={<ResultPage pages={SiegfriedPages} />}
        />
        <Route
          path={SiegfriedPages.NewSubscription.ErrorPage.relative}
          element={<ErrorPage />}
        />
      </Route>
      <Route
        path={SiegfriedPages.NewSubscription.ErrorPage.relative}
        element={<ErrorPage />}
      />
    </>
  );
}
