import { forwardRef, useState } from "react";
import style from "../styles/password-input.module.css";
import iconStyle from "../styles/icon.module.css";
import EyeIcon from "./icons/eye-icon";
import { TickIcon } from "./icons/tick-icon";
import FormattedMsg from "../locale/components/formatted-msg";
import { useRef } from "react";
import ValidatedInputText from "./input-validators/validated-input-text";

const generateRules = (rules, value) => {
  // generate bar indicators
  const bars = [],
    ruleElements = [],
    nBars = 6,
    isNotWeakValue = value.length > 3,
    matchedCriterias = rules.filter((r) => r.respected).length,
    status =
      matchedCriterias === 0
        ? "weak"
        : matchedCriterias === rules.length
        ? "strong"
        : isNotWeakValue
        ? "fair"
        : "weak",
    lastActiveBar = value
      ? status === "weak"
        ? 2
        : status === "fair"
        ? 4
        : 6
      : 0,
    color =
      lastActiveBar > 2 ? (lastActiveBar > 4 ? "green" : "yellow") : "red";

  for (let i = 0; i < nBars; i++)
    bars.push(
      <div
        data-testid="rules-bar"
        className={`${style.rulesBar} ${i < lastActiveBar ? style[color] : ""}`}
        key={"bar-" + i}
      />
    );

  // generate rules' labels
  for (const [index, rule] of rules.entries()) {
    ruleElements.push(
      <div
        className={`${style.ruleContainer} ${
          rule.respected ? style.ruleRespected : ""
        }`}
        key={"rule-" + index}
      >
        <TickIcon
          className={style.ruleIcon}
          active={rule.respected}
          background={true}
          variant="white"
        />
        <p className={style.ruleLabel}>{rule.label}</p>
      </div>
    );
  }

  return (
    <div className={style.rulesContainer}>
      <div className={style.rulesBarsContainer}>
        {bars}
        <p className={style.rulesBarLabel} data-testid="rules-bar-label">
          {value ? (
            <FormattedMsg
              id={`Password_strength_indicator_${
                lastActiveBar > 2
                  ? lastActiveBar > 4
                    ? "strong"
                    : "fair"
                  : "weak"
              }`}
            />
          ) : (
            <></>
          )}
        </p>
      </div>
      <div className={style.rulesContainer}>{ruleElements}</div>
    </div>
  );
};

export const PasswordInput = forwardRef((props, ref) => {
  const {
    "data-testid": dataTestId,
    rules,
    value,
    validator,
    ...other
  } = props;
  const [showRules, setShowRules] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const inputRef = useRef(null);
  if (ref) {
    ref.current = inputRef.current;
  }

  return (
    <div
      className={style.container}
      data-testid={dataTestId ?? "password-input-container"}
    >
      <form>
        <ValidatedInputText
          ref={inputRef}
          type={showPassword ? "text" : "password"}
          value={value}
          autoComplete="off"
          hideIcons={true}
          validator={validator}
          {...other}
          onBlur={() => {
            if (
              value && validator
                ? // keep showing the criterias if there's an error
                  !validator.getError(
                    value,
                    // consider the focus false here,
                    // in order to always show the rules
                    // if there's an error
                    false,
                    props.minLength,
                    props.maxLength,
                    props.required
                  )
                : false
            )
              setTimeout(() => setShowRules(false)); // use setTimeout to avoid focus problems
          }}
          onFocus={() => {
            setShowRules(true);
          }}
          action={{
            content: value ? (
              <div
                className={`${style.iconContainer} ${iconStyle.icon}`}
                onPointerDown={(e) => {
                  inputRef.current.focus();
                  e.preventDefault();
                  e.stopPropagation();
                  setShowPassword(!showPassword);
                }}
                data-testid="show-password-icon"
              >
                <EyeIcon className={`${style.icon}`} opened={!showPassword} />
              </div>
            ) : null,
          }}
        />
      </form>
      {showRules &&
        rules &&
        generateRules(
          rules.map((r) => {
            r.respected =
              value && (r.check?.(value) ?? r.status?.(value) === "strong");
            return r;
          }),
          value
        )}
    </div>
  );
});
