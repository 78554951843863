import React, { useCallback, useState } from "react";
import Lottie from "lottie-web";

/**
 * React component used to wrap a lottie animation
 * @param {object} props
 * @returns
 */
export default function SimpleLottieAnimation(props) {
  const [, setAnimationInstance] = useState();
  const [, setPlaying] = useState(!!props.autoplay);
  const { "data-testid": dataTestId, "data-variant": dataVariant } = props;

  const containerRef = useCallback(
    (container) => {
      setAnimationInstance((animation) => {
        if (container != null) {
          const myAnimationInstance = Lottie.loadAnimation({
            container: container,
            renderer: "svg",
            loop: true,
            autoplay: !!props.autoplay,
            animationData: props.animation,
          });
          const intersectionRatio =
            props.intersectionRatio !== undefined
              ? props.intersectionRatio
              : 0.75;
          const animationObserver = new IntersectionObserver(
            (entries) => {
              const entry = entries[0];
              if (entry.intersectionRatio > intersectionRatio) {
                myAnimationInstance.play();
                setPlaying(true);
              } else {
                myAnimationInstance.pause();
                setPlaying(false);
              }
            },
            {
              root: null,
              threshold: intersectionRatio,
            }
          );
          animationObserver.observe(container);
          return myAnimationInstance;
        } else if (animation) {
          //Unmounting, just destroy the animation and free resources
          animation.destroy();
          return null;
        } else return null;
      });
    },
    [props.autoplay, props.animation, props.intersectionRatio]
  );
  return (
    <div
      className={`${props.containerClass} ${props.className}`}
      ref={containerRef}
      data-testid={dataTestId || "animationContainer"}
      data-variant={dataVariant || "none"}
    />
  );
}
