import React from "react";
import RedSpinnerAnimation from "../lottie/vf_spinner_red_small_75.json";
import SimpleLottieAnimation from "./simple-lottie-animation";
import style from "../styles/in-page-loading-spinner.module.css";
import FormattedMsg from "../locale/components/formatted-msg";

const InPageLoadingSpinner = ({ className, spinnerMessageId }) => (
  <div
    className={`${style.loadingContainer} ${className || ""}`}
    data-testid="in-page-loading-spinner"
  >
    <SimpleLottieAnimation
      animation={RedSpinnerAnimation}
      autoplay={true}
      containerClass={style.spinnerContainer}
    />
    {spinnerMessageId && (
      <p className={style.spinnerMessage} data-testid="spinner-message">
        <FormattedMsg id={spinnerMessageId} />
      </p>
    )}
  </div>
);

export default InPageLoadingSpinner;
