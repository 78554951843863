import React, { useContext } from "react";
import { ThemeContext } from "../contexts/theme-context";
import style from "../styles/chevron.module.css";
import { ReactComponent as DarkChevron } from "../images/chevron-dark.svg";
import { ReactComponent as LightChevron } from "../images/chevron-light.svg";
import { ReactComponent as WhiteChevron } from "../images/chevron-white.svg";
import { ReactComponent as RedChevron } from "../images/chevron-red.svg";
import { ReactComponent as GreyChevron } from "../images/chevron-grey.svg";
import { ReactComponent as LightGreyChevron } from "../images/chevron-light-grey.svg";
import ImageVariants from "./image-variants";

const Chevron = (props) => {
  const { theme } = useContext(ThemeContext);
  const { onClick, direction, className, background, disabled, ...others } =
    props;
  const variants = {
    dark: background ? DarkChevron : disabled ? LightGreyChevron : WhiteChevron,
    light: background ? LightChevron : disabled ? GreyChevron : RedChevron,
    grey: disabled ? GreyChevron : LightGreyChevron,
    white: disabled ? LightGreyChevron : GreyChevron,
    default: LightChevron,
  };

  return (
    <ImageVariants
      variant={theme}
      variants={variants}
      onClick={
        disabled
          ? () => {}
          : (e) => {
              onClick?.(e);
            }
      }
      className={`${style.chevron} ${style[direction]} ${className || ""}`}
      style={{ ...props.style }}
      {...others}
    />
  );
};

export default Chevron;
