import React from "react";
import Button from "../../components/button";
import Hero from "../../components/hero";
import { useNavigate } from "react-router-dom";
import JasminePages from "../../routes/jasmine-pages";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import style from "../../styles/pages/jasmine/details.module.css";
import FormattedMsg from "../../locale/components/formatted-msg";
import ConfigHelper from "../../config/config-helper";
import { ErrorPage } from "../common/newSubscription/errorPage";

export default function OnDeviceTestPage() {
  const navigate = useNavigate();

  return !ConfigHelper.isOnDeviceTestPageEnabled() ? (
    <ErrorPage status={404} />
  ) : (
    <>
      <NavBar />
      <Hero
        className={style.hero}
        textMessage={
          <>
            TEST <br />
            <b>On device</b>
          </>
        }
      />
      <div
        className={`${style.container} ${style.pageContainer}`}
        data-testid={"get-details-page-container"}
      >
        <div className={style.pageContent}>
          <h2>Test input boxes</h2>
          <div className={style.paragraphContainer}>
            <Button
              className={style.pageButton}
              data-testid="testJasde-hidden-button"
              onClick={() => {
                navigate(
                  JasminePages.SubmitDeviceDetails + "?hidden=true&test=true"
                );
              }}
            >
              Test HIDDEN input boxes
            </Button>
          </div>
          <div className={style.paragraphContainer}>
            <Button
              className={style.pageButton}
              data-testid="testJasde-disabled-button"
              onClick={() => {
                navigate(
                  JasminePages.SubmitDeviceDetails + "?disabled=true&test=true"
                );
              }}
            >
              Test DISABLED input boxes
            </Button>
          </div>
          <div className={style.paragraphContainer}>
            <Button
              className={style.pageButton}
              data-testid="testJasde-normal-button"
              onClick={() => {
                navigate(JasminePages.SubmitDeviceDetails + "?test=true");
              }}
            >
              Test NORMAL input boxes
            </Button>
          </div>
          <div className={style.paragraphContainer}>
            <p>Check proposed landing page (normal inputs)</p>
            <Button
              className={style.pageButton}
              data-testid="testJasde-landing-button"
              onClick={() => {
                navigate(JasminePages.GetDeviceDetails + "?test=true");
              }}
            >
              Test landing page
            </Button>
          </div>
          <div className={style.paragraphContainer}>
            <p>Check external links and pdf</p>
            <br />
            <p style={{ maxWidth: "420px" }}>
              <FormattedMsg
                id="Terms_and_conditions_copy_1"
                values={{
                  Price_list_link: (
                    <Button
                      data-testid="price-list-link"
                      href="https://www.vodafone.de/agb.html"
                      variant="link"
                      external
                    >
                      <u>
                        <FormattedMsg id="Price_list_label" />
                      </u>
                    </Button>
                  ),
                  AGB_link: (
                    <Button
                      data-testid="agb-link"
                      href="https://www.vodafone.de/media/downloads/pdf/20031_2110_adt09_21_v_agb_vf_dienstleistungen_900271.pdf"
                      variant="link"
                      external
                    >
                      <u>
                        <FormattedMsg id="AGB_label" />
                      </u>
                    </Button>
                  ),
                }}
              />
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
