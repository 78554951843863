import React from "react";

const FlowContext = React.createContext({
  flow: null,
  // eslint-disable-next-line no-unused-vars, no-empty-function
  setFlow: (flow) => {},
  // eslint-disable-next-line no-unused-vars, no-empty-function
  getFlowPages: () => {},
});

export default FlowContext;
