import React from "react";
import { Navigate, Route } from "react-router";
import { useLocation } from "react-router-dom";

function EntryPointRedirect({ entryPoint }) {
  const location = useLocation();
  const to =
    entryPoint.redirectTo + (entryPoint.copyParameters ? location.search : "");
  if (entryPoint.fullRedirect) {
    window.location.replace(to);
    throw new Promise(() => {});
  } else {
    return <Navigate to={to} replace={true} />;
  }
}

export default function getEntryPointRoutes(entryPoints) {
  return Object.keys(entryPoints).map((key) => {
    const entryPoint = entryPoints[key];
    return (
      <Route
        path={entryPoint.full}
        key={key}
        element={<EntryPointRedirect entryPoint={entryPoint} />}
      />
    );
  });
}
