import React from "react";

import { ReactComponent as RedIcon } from "../../images/clock-red.svg";
import { ReactComponent as WhiteIcon } from "../../images/clock-white.svg";
import ImageVariants from "../image-variants";

const ClockIcon = (props) => {
  const variants = {
    white: WhiteIcon,
    red: RedIcon,
    default: RedIcon,
  };
  return <ImageVariants variants={variants} {...props} />;
};

export default ClockIcon;
