import OverlayCard from "./overlay-card";
import { Accordion } from "./accordion";
import { useContext, useState } from "react";
import LocaleContext from "../contexts/locale-context";

import overlayStyle from "../styles/overlay.module.css";
import pageStyle from "../styles/page.module.css";
import style from "../styles/privacy-policy.module.css";
import Button from "./button";

const TITLE_REGEX = /_title$/;
const PAR_REGEX = /_paragraph$/;
const LINK_URI = "_link_uri";
const LINK_LABEL = "_link_label";
const LINK_URI_REGEX = new RegExp(LINK_URI + "$");
const LINK_LABEL_REGEX = new RegExp(LINK_LABEL + "$");
const ULIST_REGEX = /_ulist_\d(_span){0,1}(_link(_uri|_label){0,1}){0,1}$/;
const ALIST_REGEX = /_alist_\d(_span){0,1}(_link(_uri|_label){0,1}){0,1}$/;
const OLIST_REGEX = /_olist_\d(_span){0,1}(_link(_uri|_label){0,1}){0,1}$/;
const LIST_REGEX = /_\w{1}list_\d(_span){0,1}(_link(_uri|_label){0,1}){0,1}$/;
const LIST_SPAN_REGEX = /_\w{1}list_\d_span$/;
const TABLE_REGEX = /_table_(row_\d_column_\d|head_\d)$/;
const TABLE_HEAD_REGEX = /_table_head_(\d)$/;
const TABLE_CELL_REGEX = /_table_row_(\d)_column_(\d)$/;

const maxRowsAndSections = 25;

function renderMessage(ppMessageExists, formatPpMessage, id, renderComponent) {
  if (ppMessageExists(id)) {
    return renderComponent(formatPpMessage(id));
  } else return null;
}

function getList(list, whereToPush) {
  if (list?.type && list?.elements?.length > 0) {
    let ret = null;
    switch (list.type) {
      case "u":
        ret = (
          <ul className={style.list} key={list.key}>
            {list.elements}
          </ul>
        );
        break;
      case "o":
        ret = (
          <ol className={style.list} key={list.key} type="1">
            {list.elements}
          </ol>
        );
        break;
      case "a":
        ret = (
          <ol className={style.list} key={list.key} type="a">
            {list.elements}
          </ol>
        );
        break;
      default:
    }
    list.elements = [];
    list.type = null;
    if (ret) {
      whereToPush.push(ret);
    }
  }
}

function getElementValues(ppMessageExists, formatPpMessage, key) {
  const linkUri = ppMessageExists(key + LINK_URI)
    ? formatPpMessage(key + LINK_URI)
    : null;
  const linkLabel = ppMessageExists(key + LINK_LABEL)
    ? formatPpMessage(key + LINK_LABEL)
    : linkUri;
  let values;
  if (linkUri) {
    values = {
      link: (
        <Button
          data-testid={key + LINK_URI}
          href={linkUri}
          className={pageStyle.link}
          variant="link"
          external
        >
          {linkLabel}
        </Button>
      ),
    };
  }
  return values;
}

function getTable(table, whereToPush) {
  const headRows = [];
  const bodyRows = [];
  for (let i = 0; i < maxRowsAndSections; i++) {
    const row = table.elements?.[i];
    if (row && row.length > 0) {
      const element = (
        <tr key={table.key + "--row-" + i}>{row.map((value) => value)}</tr>
      );
      if (row === 0) {
        headRows.push(element);
      } else {
        bodyRows.push(element);
      }
    }
  }
  if (headRows.length > 0 || bodyRows.length > 0) {
    whereToPush.push(
      <table className={style.table} key={table.key}>
        {headRows.length > 0 ? (
          <thead key={table.key + "--thead"}>{headRows}</thead>
        ) : null}
        {bodyRows.length > 0 ? (
          <tbody key={table.key + "--tbody"}>{bodyRows}</tbody>
        ) : null}
      </table>
    );
  }
  table.elements = [];
  table.key = null;
}

function renderSections(
  ppMessageExists,
  formatPpMessage,
  keys,
  bigTitles = false
) {
  const sections = [];
  const list = { elements: [] };
  const table = { elements: [] };
  for (let i = 1; i < maxRowsAndSections; i++) {
    const sectionKeys = keys.filter((k) =>
      new RegExp("section_" + i + "_").test(k)
    );
    //ok, start form the beginning
    for (const key of sectionKeys) {
      if (list.elements && !LIST_REGEX.test(key)) {
        //close the last list
        getList(list, sections);
      }
      if (table.elements && !TABLE_REGEX.test(key)) {
        //close the last table
        getTable(table, sections);
      }
      if (TITLE_REGEX.test(key)) {
        const string = formatPpMessage(
          key,
          getElementValues(ppMessageExists, formatPpMessage, key)
        );
        sections.push(
          bigTitles ? (
            <h2 className={style.sectionTitle} key={key}>
              {string}
            </h2>
          ) : (
            <h3 className={style.subsectionTitle} key={key}>
              {string}
            </h3>
          )
        );
      } else if (PAR_REGEX.test(key)) {
        //standard paragraph
        sections.push(
          <p className={style.paragraph} key={key}>
            {formatPpMessage(
              key,
              getElementValues(ppMessageExists, formatPpMessage, key)
            )}
          </p>
        );
      } else if (LIST_REGEX.test(key)) {
        let type = ULIST_REGEX.test(key)
          ? "u"
          : OLIST_REGEX.test(key)
          ? "o"
          : ALIST_REGEX.test(key)
          ? "a"
          : null;
        if (type) {
          if (list.type !== type) {
            //close previous list
            getList(list, sections);
            //initialize list
            list.type = type;
            list.key = key + "--" + type;
          }
          //if we got a link, skip
          if (!LINK_URI_REGEX.test(key) && !LINK_LABEL_REGEX.test(key)) {
            //check if we got an element or a span
            const string = formatPpMessage(
              key,
              getElementValues(ppMessageExists, formatPpMessage, key)
            );
            if (LIST_SPAN_REGEX.test(key)) {
              list.elements.push(
                <span className={style.paragraph} key={key}>
                  {string}
                </span>
              );
            } else {
              //do we have any link?
              list.elements.push(<li key={key}>{string}</li>);
            }
          }
        }
      } else if (TABLE_REGEX.test(key)) {
        //save the table details
        let row = null,
          column = null;
        const thMatches = TABLE_HEAD_REGEX.exec(key);
        const tdMatches = TABLE_CELL_REGEX.exec(key);
        if (thMatches !== null && thMatches.length === 2) {
          //head has row=0
          row = 0;
          column = Number(thMatches[1]);
        } else if (tdMatches !== null && tdMatches.length === 3) {
          //row and column
          row = Number(tdMatches[1]);
          column = Number(tdMatches[2]);
        }
        if (!table.key) {
          table.key = key + "--table";
        }
        if (row !== null) {
          //add to table elements
          if (!table.elements[row]) {
            table.elements[row] = [];
          }
          const element = formatPpMessage(
            key,
            getElementValues(ppMessageExists, formatPpMessage, key)
          );
          table.elements[row][column] =
            row === 0 ? (
              <th key={key}>{element}</th>
            ) : (
              <td key={key}>{element}</td>
            );
        }
      }
    }
    //after iterating the whole section, close any pending list or table
    getList(list, sections);
    getTable(list, sections);
  }
  return sections;
}

function renderPpMessages(ppMessageExists, formatPpMessage, ppMessages) {
  //start with main blocks
  const mainKey = "Privacy_Policy_main_";
  const regex = new RegExp(`^${mainKey}`);
  const mainKeys = Object.keys(ppMessages).filter((k) => regex.test(k));
  const mainBlock = renderSections(
    ppMessageExists,
    formatPpMessage,
    mainKeys,
    true
  );
  //parse accordions
  const accordions = [];
  for (let i = 1; i < 21; i++) {
    //check if accordion i exists
    const accordionKey = "Privacy_Policy_accordion_" + i + "_";
    const regex = new RegExp(`^${accordionKey}`);
    const accordionKeys = Object.keys(ppMessages).filter((k) => regex.exec(k));
    if (accordionKeys?.length > 0) {
      //found some accordion i keys
      const sections = renderSections(
        ppMessageExists,
        formatPpMessage,
        accordionKeys
      );
      const titleKey = accordionKey + "title";
      const title = ppMessageExists(titleKey)
        ? formatPpMessage(titleKey)
        : undefined;
      accordions.push(
        <Accordion
          data-testid={"accordion-" + i}
          key={accordionKey}
          headline={title}
        >
          {sections}
        </Accordion>
      );
    }
  }
  return (
    <>
      {mainBlock}
      {accordions.length > 0 ? (
        <div className={style.accordionsContainer}>{accordions}</div>
      ) : null}
    </>
  );
}

export const PrivacyPolicyOverlay = (props) => {
  const [scrolled, setScrolled] = useState(false);
  const { getPrivacyPolicyMessageKeys, formatPpMessage, ppMessageExists } =
    useContext(LocaleContext);

  const ppMessages = getPrivacyPolicyMessageKeys();

  return (
    <OverlayCard
      className={style.container}
      closeBoxClassName={scrolled ? overlayStyle.closeBoxScrolled : ""}
      data-testid="privacy-policy-overlay-container"
      scrollable
    >
      <div
        className={overlayStyle.scrollableContent}
        onScroll={(e) => {
          setScrolled(e.target.scrollTop > 0);
        }}
      >
        {renderMessage(
          ppMessageExists,
          formatPpMessage,
          "Privacy_Policy_title",
          (msg) => (
            <h1 className={style.title}>{msg}</h1>
          )
        )}
        {renderPpMessages(ppMessageExists, formatPpMessage, ppMessages)}
      </div>
    </OverlayCard>
  );
};
