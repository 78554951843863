import SimpleLottieAnimation from "./simple-lottie-animation";
import WhiteSpinnerAnimation from "../lottie/vf_spinner_white_medium_150.json";
import RedSpinnerAnimation from "../lottie/vf_spinner_red_small_75.json";
import style from "../styles/loading-spinner.module.css";

export default function LoadingSpinner(props) {
  const { variant } = props;
  let animation, dataVariant;
  switch (variant?.toLowerCase()) {
    case "red":
      animation = RedSpinnerAnimation;
      dataVariant = "red";
      break;
    case "white":
    default:
      animation = WhiteSpinnerAnimation;
      dataVariant = "white";
  }
  return (
    <SimpleLottieAnimation
      key="mainpage-spinner"
      className={style.spinner}
      animation={animation}
      autoplay={true}
      data-testid="loadingSpinner"
      data-variant={dataVariant}
    />
  );
}
