import React from "react";

import WhiteIcon from "../../images/calendar-icon-white.svg";
import ImageVariants from "../image-variants";

const CalendarIcon = (props) => {
  const variants = {
    white: WhiteIcon,
    default: WhiteIcon,
  };
  return <ImageVariants variants={variants} {...props} />;
};

export default CalendarIcon;
