import Helpers from "../helpers";
import Logger from "../logger/logger";
import createValidator from "./validator";

const invalidCharsRegex = /[^0-9/\-.]/i;

const isValidDate = (date, getLastEligibleDate) => {
  try {
    return date.getTime() <= getLastEligibleDate().getTime();
  } catch (err) {
    Logger.v("Dob validator, Invalid date ", date);
    return false;
  }
};

export default function createDobValidator(
  invalidDateMessage,
  tooRecentMessage,
  getLastEligibleDate
) {
  return createValidator(
    (value, focused, minLength, maxLength, required) => {
      let result;
      if (value instanceof Date) {
        result = isValidDate(value, getLastEligibleDate)
          ? null
          : {
              messageId: tooRecentMessage,
            };
      } else {
        if (value.length > 0) {
          //check valid chars
          let errorMessage = invalidCharsRegex.test(value)
            ? invalidDateMessage
            : null;
          if (!errorMessage) {
            //parse date
            const date = Helpers.parseDate(value);
            const validDate = isValidDate(date, getLastEligibleDate);
            const match = Helpers.getDateValidatorRegex().test(value);
            if (date) {
              if (!validDate) {
                errorMessage = tooRecentMessage;
              }
            } else {
              if (!focused) {
                errorMessage = invalidDateMessage;
              }
            }
            Logger.v(
              `Dob validator: value=${value} parsed=${date} validDate=${validDate} focused=${focused} match=${match} errorMessage=${errorMessage}`
            );
          } else {
            Logger.v(`Dob validator: value=${value} invalid chars`);
          }
          return errorMessage
            ? {
                messageId: errorMessage,
              }
            : null;
        }
      }
      return result;
    },
    (value, minLength, maxLength, required) => {
      let result;
      if (!required && value?.length === 0) return true;
      if (!(value instanceof Date)) {
        if (!value && required) return false;
        try {
          value = new Date(value);
        } catch {
          return false;
        }
      }
      result = value && value.getTime() <= getLastEligibleDate().getTime();
      return result;
    }
  );
}
