import { useEffect } from "react";

export function CallHandler({ call, onFinish }) {
  const res = call.read();
  useEffect(() => {
    if (res) onFinish?.(res);
  }, [res, onFinish]);

  return null;
}
