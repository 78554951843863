import React from "react";
import style from "../styles/promobanner.module.css";

export default function PromoBanner({
  className,
  srcSet,
  srcSetType,
  defaultImage,
  alt,
}) {
  const cn = style.banner + (className ? " " + className : "");
  return srcSet ? (
    <picture className={style.pictureContainer}>
      <source type={srcSetType} srcSet={srcSet} />
      <img src={defaultImage} alt={alt} className={cn} />
    </picture>
  ) : (
    <img src={defaultImage} alt={alt} className={cn} />
  );
}
