import React, { useContext, useState } from "react";
import { ThemeContext } from "../contexts/theme-context";
import Card from "./card";
import XIndicator from "./icons/x-indicator";
import Hero from "./hero";

import style from "../styles/overlay.module.css";
import { OverlayContext } from "../contexts/overlay-context";

const OverlayCard = (props) => {
  const {
    className,
    children,
    childrenClassName,
    closeBoxClassName,
    titleId,
    close,
    title,
    alignTitleToX,
    scrollable,
    ...others
  } = props;
  const { theme } = useContext(ThemeContext);
  const { showOverlay } = useContext(OverlayContext);
  const [scrolled, setScrolled] = useState(false);
  const darkTheme = theme === "dark";
  return (
    <Card
      className={`${style.container} ${className || ""}`}
      data-testid="overlay-container"
      {...others}
    >
      <div
        className={`${scrolled ? style.closeBoxScrolled : style.closeBox} ${
          closeBoxClassName || ""
        }`}
      >
        {alignTitleToX && <span className={style.title}>{title}</span>}
        <div
          className={style.closeButtonContainer}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            showOverlay();
            close?.();
          }}
          data-testid="close-overlay"
        >
          <XIndicator
            className={style.closeButton}
            variant={darkTheme ? "white" : "grey"}
            alt="close"
          />
        </div>
      </div>
      <div
        className={`${style.children} ${
          scrollable ? style.scrollableContainer : ""
        } ${childrenClassName || ""}`}
        onScroll={(e) => {
          if (scrollable) setScrolled(e.target.scrollTop > 0);
        }}
        data-testid="overlay-content"
      >
        {!alignTitleToX && titleId && (
          <Hero className={style.headline} textId={titleId} />
        )}
        {children}
      </div>
    </Card>
  );
};
export default OverlayCard;
