import React, { useContext } from "react";
import style from "../styles/range-selector.module.css";
import formStyle from "../styles/form.module.css";
import iconStyle from "../styles/icon.module.css";
import Chevron from "./chevron";
import { ThemeContext } from "../contexts/theme-context";

// split data into columns, with a fixed amount of rows
// const formatData = (data) => {
//   const rows = 4;
//   const cols = Math.ceil(data.length / rows);
//   const res = new Array(cols);

//   for (let i = 0; i < cols; i++) {
//     res[i] = new Array(rows);
//     for (let j = 0; j < rows; j++) {
//       const idx = i + j * cols;
//       if (idx < data.length) res[i][j] = data[idx];
//     }
//   }
//   return res;
// };

// generates the content from the formatted data
const generateContent = (data, isDisabled, onSelect, selected, isNumeric) => {
  const res = data.map((v) => (
    <div
      className={`
          ${style.dataCell}
          ${isDisabled(v.value || v) ? style.disabled : ""}
          ${selected === (v.value | v) ? style.selected : ""}`}
      key={(v.value ?? v) + "-label"}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        !isDisabled(v.value ?? v) && onSelect(v.value ?? v);
      }}
      data-testid={(v.value ?? v) + "-label"}
    >
      <p className={`${style.dataCellLabel}`}>{v.label ?? v}</p>
    </div>
  ));

  return res;
};

export const RangeSelector = (props) => {
  const {
    label,
    onPrevious,
    data,
    onNext,
    onSelect,
    selected,
    disableData,
    isNumeric,
    end,
    className,
  } = props;
  const { theme } = useContext(ThemeContext);
  return (
    <div className={`${style.container} ${formStyle.container}`}>
      <div className={`${style.row}`}>
        <Chevron
          data-testid="previous"
          direction="left"
          className={`${iconStyle.icon} ${style.chevron}`}
          onClick={(e) => {
            e.stopPropagation();
            onPrevious();
          }}
          variant={theme === "light" ? "grey" : "white"}
        />
        <p data-testid="label" className={style.label}>
          {label}
        </p>
        <Chevron
          data-testid="next"
          direction="right"
          disabled={end}
          className={`${iconStyle.icon} ${style.chevron}`}
          onClick={(e) => {
            e.stopPropagation();
            onNext();
          }}
          variant={theme === "light" ? "grey" : "white"}
        />
      </div>
      <div
        className={`${style.content} ${className ?? ""}`}
        data-testid="content"
      >
        {generateContent(data, disableData, onSelect, selected, isNumeric)}
      </div>
    </div>
  );
};
