import React, { useContext, useRef } from "react";
import { useEffect } from "react";
import { ThemeContext } from "../contexts/theme-context";
import style from "../styles/checkbox.module.css";
import EventsHelper from "./tracking/events-helper";

export const Checkbox = React.forwardRef((props, ref) => {
  const { label, id, checked, "data-testid": dataTestId, size } = props;
  const { theme } = useContext(ThemeContext);
  const element = useRef();

  useEffect(() => {
    if (ref) ref.current = element.current;
  }, [ref, element]);
  return (
    <div className={style.container} data-testid={dataTestId || "checkbox"}>
      <input
        // value={checked ? "on" : "off"}
        checked={checked}
        className={`${style.input} ${style[theme]} ${
          size === "small" ? style.small : ""
        }`}
        id={id}
        type="checkbox"
        onChange={(e) => {
          EventsHelper.clickEvent(dataTestId || id, {
            checked: e.target.value,
          });
          props.onChange?.(e);
        }}
      />
      <label className={style.label} ref={element} htmlFor={id}>
        {label || props.children}
      </label>
    </div>
  );
});
