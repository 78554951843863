import ConfigHelper from "../../config/config-helper";
import Logger from "../logger/logger";
import EventsHelper from "../tracking/events-helper";
import CallHelper from "./call-helper";
import fetchHelper from "./fetch-helper";

export default function downloadPDF(url, options, callback) {
  return new Promise(async (resolve, reject) => {
    try {
      async function getPDF(resolve, reject) {
        try {
          const signal = options?.signal;
          const call = options?.config
            ? CallHelper.createCall(options?.config)
            : {
                url,
                method: "get",
                headers: { Accept: "application/pdf" },
              };
          const res = await fetchHelper(url, {
            method: call.method,
            headers: call.headers,
            signal,
          });
          if (res.ok && res.status !== 204) {
            if (options?.type === "json") {
              const json = await res.json();
              resolve(json);
            } else {
              const blob = await res.blob();
              resolve(blob);
            }
            return;
          } else {
            const error = new Error("HTTP Error " + res?.status);
            error.status = res.status;
            // keep showing the spinner
            setTimeout(() => reject(error), options?.waitForMillis ?? 0);
          }
        } catch (error) {
          if (ConfigHelper.isDevelopmentMode()) {
            Logger.w("Error during PDF loading: ", error);
            EventsHelper.debugEvent(`Error during PDF loading: ${error}`);
          }
          reject(error);
        }
      }
      await getPDF(
        (...args) => {
          resolve(...args);
          callback?.(...args);
        },
        (...args) => {
          reject(...args);
          callback?.(...args);
        }
      );
    } catch (error) {
      if (ConfigHelper.isDevelopmentMode()) {
        Logger.w("Error during call: ", error);
        EventsHelper.debugEvent(`Error while retrieving PDF.js: ${error}`);
      }
      reject(error);
    }
  });
}
