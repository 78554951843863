import React, { useRef, useState } from "react";
import InputText from "../input-text";
import FormattedMsg from "../../locale/components/formatted-msg";

const ValidatedInputText = React.forwardRef(function ValidatedInputText(
  props,
  extRef
) {
  const myRef = useRef(null);
  const ref = extRef || myRef;
  const { validator, onBlur, onFocus, ...rest } = props;
  const [focused, setFocused] = useState(false);

  const error = validator?.getError(
    props.value,
    focused,
    rest.minLength,
    rest.maxLength,
    rest.required
  );

  return (
    <InputText
      {...rest}
      errorMessage={
        error?.message || error?.messageId ? (
          <FormattedMsg id={error.messageId} />
        ) : null
      }
      ref={ref}
      onFocus={(e) => {
        onFocus?.(e);
        // setting the status normally interferes with click events
        setTimeout(() => setFocused(true));
      }}
      onBlur={(e) => {
        onBlur?.(e);
        // setting the status normally interferes with click events
        setTimeout(() => setFocused(false));
      }}
    />
  );
});

export default ValidatedInputText;
