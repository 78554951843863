export default class GoogleService {
  constructor(placesContext) {
    this.apiKey = placesContext?.apiKey;
  }

  getPlacePredictions(request, callback, networkContext) {
    if (!this.autocompleteService) {
      if (window.google?.maps?.places?.AutocompleteService === undefined) {
        throw new Error(
          "Places API were not setup correctly, use PlacesLoader"
        );
      }
      this.autocompleteService =
        new window.google.maps.places.AutocompleteService();
    }
    return this.autocompleteService.getPlacePredictions(request, callback);
  }

  getDetails(options, callback, container, networkContext) {
    if (!this.placesService) {
      if (window.google?.maps?.places?.PlacesService === undefined) {
        throw new Error(
          "Places API were not setup correctly, use PlacesLoader"
        );
      }
      this.placesService = new window.google.maps.places.PlacesService(
        container
      );
    }
    return this.placesService.getDetails(options, callback, container);
  }
}
