import ConfigHelper from "../../config/config-helper";
import Logger from "../logger/logger";
import fetchHelper from "../network/fetch-helper";

const ContextId = "LocationIQService";
const placeTag = "PLACE_PREDICTIONS";

export default class LocationIQService {
  constructor(placesContext) {
    this.apiKey = placesContext?.apiKey;
  }

  getPlacePredictions(request, callback, networkContext) {
    //create autocomplete request
    const params = [
      "https://api.locationiq.com/v1/autocomplete?key=" + this.apiKey,
    ];
    for (const key of Object.keys(request)) {
      const value = request[key];
      if (value) {
        const encoded =
          value instanceof Array
            ? value.map((a) => encodeURIComponent(a)).join(",")
            : encodeURIComponent(value);
        params.push(`${encodeURIComponent(key)}=${encoded}`);
      }
    }
    const requestUrl = params.filter((a) => !!a).join("&");

    const result = networkContext.addNetworkCall(
      ContextId,
      placeTag + "-" + encodeURI(requestUrl),
      () => {
        return new Promise(async (resolve, reject) => {
          try {
            const res = await fetchHelper(requestUrl);
            if (res.status === 200) {
              //Succesful
              const json = await res.json();
              resolve(json);
              //manually call callback as netlib doesn't have this automatically
              setTimeout(() => {
                callback(json);
              });
            } else {
              const error = new Error(
                "HTTP Error getting autocomplete result" + res?.status
              );
              if (ConfigHelper.isLocationLogsEnabled()) {
                Logger.d(error.message);
              }
              reject(error);
              //manually call callback as netlib doesn't have this automatically
              setTimeout(() => {
                callback(error);
              });
            }
          } catch (error) {
            if (ConfigHelper.isLocationLogsEnabled()) {
              Logger.d("Error getting autocomplete result: ", error);
            }
            reject(error);
            //manually call callback as netlib doesn't have this automatically
            setTimeout(() => {
              callback(error);
            });
          }
        });
      },
      true,
      true
    );
    if (
      result.isStarted() &&
      !result.isPending() &&
      (result.hasError() || result.hasSuccess())
    ) {
      setTimeout(() => {
        callback(result.read());
      }, 0);
    }
  }

  getDetails(options, callback, networkContext) {
    if (ConfigHelper.isLocationLogsEnabled()) {
      Logger.d("Location iq service getDetails", options, callback);
    }
    setTimeout(() => {
      callback(options.originalPlace);
    });
  }

  hasPendingCalls(networkContext) {
    return networkContext?.isLoading(ContextId);
  }
}
