import React, { useContext } from "react";

import { ReactComponent as OpenedEyeWhite } from "../../images/opened-eye-white.svg";
import { ReactComponent as OpenedEyeBlack } from "../../images/opened-eye-black.svg";

import { ReactComponent as ClosedEyeWhite } from "../../images/closed-eye-white.svg";
import { ReactComponent as ClosedEyeBlack } from "../../images/closed-eye-black.svg";

import ImageVariants from "../image-variants";
import { ThemeContext } from "../../contexts/theme-context";

const EyeIcon = (props) => {
  const { opened, ...other } = props;
  const { theme } = useContext(ThemeContext);
  const variants = {
    dark: opened ? OpenedEyeWhite : ClosedEyeWhite,
    light: opened ? OpenedEyeBlack : ClosedEyeBlack,
    default: OpenedEyeBlack,
  };
  return <ImageVariants variants={variants} variant={theme} {...other} />;
};

export default EyeIcon;
