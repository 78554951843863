import Card from "../../../components/card";
import NavBar from "../../../components/navbar";

import pageStyle from "../../../styles/page.module.css";
import style from "../../../styles/test-fetch.module.css";
import Footer from "../../../components/footer";
import { testData, testError } from "../../../components/network/test";
import useNetwork from "../../../components/network/use-network";
import { Suspense, useReducer } from "react";
import { useContext } from "react";
import ConfigContext from "../../../contexts/config-context";
import Button from "../../../components/button";
import InPageLoadingSpinner from "../../../components/in-page-loading-spinner";

/* istanbul ignore file */
function InnerData({ data, label, "data-testid": dataTestId }) {
  const image = data.read();
  return <Image image={image} label={label} data-testid={dataTestId} />;
}

function Image({ image, label, alt }) {
  return image instanceof Error ? (
    <h3 className={style.error}>
      Error{label ? ` ${label}` : ""}: {image.toString()}
    </h3>
  ) : image ? (
    <img
      className={style.image}
      src={URL.createObjectURL(image)}
      alt={alt || label}
    />
  ) : null;
}

function Content({
  forceUpdate,
  checkNetworkCall,
  startNetworkCall,
  isLoading,
  config,
}) {
  //start the first call with the outer loading handling
  const data1 = startNetworkCall(
    "testData1",
    () => testData(config, "1"),
    false,
    true,
    () => {
      forceUpdate();
    }
  );
  //create a check for a call that was never started, will never start
  const dataNeverRead = checkNetworkCall("testNever");
  const image = data1.read();
  //create subsequent data object AFTER data1.read is ok, just to have 2 levels of loading
  const data2 = startNetworkCall(
    "testData2",
    () => testData(config, "2"),
    false,
    true,
    () => {
      forceUpdate();
    }
  );
  const dataError = startNetworkCall(
    "testDataError",
    () => testError(config, "generic"),
    false,
    true,
    () => {
      forceUpdate();
    }
  );
  const data3 = checkNetworkCall("testData3", false, false);
  return (
    <>
      <h2>Test fetch page and loading</h2>
      <Card>
        <h2>Config</h2>
        <div>Loading 1: {isLoading("testData").toString()}</div>
        <div>Loading 2: {isLoading("testData2").toString()}</div>
        <div>Loading all: {isLoading().toString()}</div>
        <Image image={image} label="data1" data-testid="data1" />
        <Suspense fallback={<h2>Inner loading...</h2>}>
          <InnerData data={data2} label="data2" data-testid="data2" />
        </Suspense>
        <Suspense fallback={<h2>Inner loading...</h2>}>
          <InnerData
            data={dataError}
            label="dataError"
            data-testid="dataError"
          />
        </Suspense>
        {data3.isStarted() ? (
          <Suspense fallback={<h2>Inner loading...</h2>}>
            <InnerData data={data3} label="data3" data-testid="data3" />
          </Suspense>
        ) : (
          <Button
            data-testid="data3Button"
            data-link-id="data3Button"
            onClick={() => {
              //start call
              startNetworkCall(
                "testData3",
                () => testData(config, "3"),
                false,
                false,
                () => {}
              );
              //have to re-render
              forceUpdate();
            }}
          >
            Start test 3
          </Button>
        )}
        <Suspense
          fallback={
            <h2 data-testid="loadingDataNever">
              Inner loading (never fulfilled)...
            </h2>
          }
        >
          <InnerData
            data={dataNeverRead}
            label="dataNeverRead"
            data-testid="dataNever"
          />
        </Suspense>
      </Card>
    </>
  );
}

/**
 * Show a navbar, content div, footer and switch between Content and Suspense spinner
 * @returns fetch test component
 */
export default function FetchTest() {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { checkNetworkCall, startNetworkCall, isLoading } = useNetwork();
  const { config } = useContext(ConfigContext);
  return (
    <div className={pageStyle.page} data-testid="fetchTestPage">
      <NavBar />

      <div className={`${pageStyle.content}`}>
        <Suspense fallback={<InPageLoadingSpinner />}>
          <Content
            forceUpdate={forceUpdate}
            checkNetworkCall={checkNetworkCall}
            startNetworkCall={startNetworkCall}
            isLoading={isLoading}
            config={config}
          />
        </Suspense>
      </div>
      <Footer />
    </div>
  );
}
