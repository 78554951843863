import { Route } from "react-router";
import TestPages from "./test-pages";
import FetchTest from "../pages/common/testPages/test-fetch";
import FullPageLoading from "../pages/full-page-loading";

export default function getTestRoutes(testPagesEnabled) {
  return (
    testPagesEnabled && (
      <>
        <Route path={TestPages.FetchTest} element={<FetchTest />} />
        <Route
          path={TestPages.LoadingTest}
          element={<FullPageLoading testPage={true} />}
        />
      </>
    )
  );
}
