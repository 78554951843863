import React, { useContext } from "react";

import VFLogo from "./icons/vf-logo";
import { ThemeContext } from "../contexts/theme-context";
import Button from "./button";

import style from "../styles/navbar.module.css";
import ConfigHelper from "../config/config-helper";

const ThemeSwitcher = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  function switchTheme() {
    setTheme(theme === "dark" ? "light" : "dark");
  }
  return (
    <div className={`${style.themeSwitcher} ${theme}`}>
      <Button
        className={style.switcherButton}
        variant="outline"
        onClick={switchTheme}
        data-link-id="switch-theme"
        data-testid="switch-theme"
      >
        Switch Theme ({theme})
      </Button>
    </div>
  );
};

const NavBar = (props) => {
  const { className } = props;
  const { theme } = useContext(ThemeContext);

  const themeSwitcherEnabled = ConfigHelper.isThemeSwitcherButtonEnabled();

  return (
    <div
      className={`${style.navbar} ${className || ""}`}
      data-testid="navbar"
      data-theme={theme}
    >
      <div className={style.behindContainer}>
        <div className={`${style.container}`}>
          <div className={style.items}>
            <VFLogo
              className={style.logo}
              alt="Vodafone OneNumber"
              variant={theme === "dark" ? "inverted" : "red"}
            />
            {props.children}
            {themeSwitcherEnabled && <ThemeSwitcher />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NavBar;
