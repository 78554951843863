import OverlayCard from "./overlay-card";
import style from "../styles/pdf-overlay.module.css";
import Button from "./button";
import { Suspense, useContext, useState } from "react";
import { OverlayContext } from "../contexts/overlay-context";
import useNetwork from "./network/use-network";
import { CallHandler } from "./call-handler";
import InPageLoadingSpinner from "./in-page-loading-spinner";
import sendPDF from "./network/sendPDF";
import WarningIcon from "./icons/warning-icon";
import { TickIcon } from "./icons/tick-icon";
import { ThemeContext } from "../contexts/theme-context";

const pdfTag = "sendPDFTag";
export default function SendPdfOverlay(props) {
  const {
    callOptions,
    onClose: close,
    spinnerMessageId,
    successText,
    errorText,
    mainButtonText,
    backButtonText,
    tryAgainText,
    ...rest
  } = props;

  const [tick, setTick] = useState(Date.now());
  const { showOverlay } = useContext(OverlayContext);
  const { startNetworkCall, checkNetworkCall } = useNetwork();
  const { theme } = useContext(ThemeContext);
  const sendPDFCall = checkNetworkCall(pdfTag, false, false);

  const error =
    sendPDFCall?.finishedAt + 10 >= tick ? sendPDFCall?.hasError() : false;
  const data =
    sendPDFCall?.finishedAt + 10 >= tick ? sendPDFCall?.hasSuccess() : false;

  const startSendPDF = () => {
    return startNetworkCall(
      pdfTag,
      () => sendPDF(callOptions.config),
      false,
      false
    );
  };

  if (!error && !data && !sendPDFCall?.isPending()) {
    startSendPDF();
    setTick(Date.now());
  }
  return (
    <OverlayCard
      {...rest}
      className={`${style.overlayCard} ${style.center}`}
      childrenClassName={style.children}
      close={close}
    >
      <div className={`${style.pdfPlugin}`} data-testid="pdf-plugin-container">
        {sendPDFCall?.isPending() ? (
          <Suspense
            fallback={
              <InPageLoadingSpinner spinnerMessageId={spinnerMessageId} />
            }
          >
            <CallHandler
              call={sendPDFCall}
              onFinish={() => setTick(Date.now())}
            />
          </Suspense>
        ) : (
          <div className={style.sendPDF}>
            {data ? (
              <>
                <TickIcon
                  className={style.icon}
                  variant={theme === "dark" ? "white" : "red"}
                  circled
                  data-testid="tick-icon"
                />
                <p className={style.message} data-testid="success-message">
                  {successText}
                </p>

                <div className={style.buttons}>
                  <Button
                    variant="primary"
                    data-link-id="back"
                    data-testid="back-button"
                    onClick={() => {
                      showOverlay();
                      close?.();
                    }}
                  >
                    {backButtonText}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <WarningIcon
                  className={style.icon}
                  variant={theme === "dark" ? "white" : "red"}
                  data-testid="warning-icon"
                />
                <p className={style.message} data-testid="error-message">
                  {errorText}
                </p>
                <div className={style.buttons}>
                  <Button
                    variant="outline"
                    data-link-id="back"
                    data-testid="back-button"
                    onClick={() => {
                      showOverlay();
                      close?.();
                    }}
                  >
                    {backButtonText}
                  </Button>
                  <Button
                    data-testid="try-again-button"
                    onClick={() => {
                      startSendPDF();
                      setTick(Date.now());
                    }}
                  >
                    {tryAgainText}
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </OverlayCard>
  );
}
