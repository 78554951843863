import React, { useState } from "react";
import { Link } from "react-router-dom";
import FormattedMsg from "../locale/components/formatted-msg";
import style from "../styles/OTP.module.css";
import formStyle from "../styles/form.module.css";
import Button from "./button";
import Card from "./card";
import WarningIcon from "./icons/warning-icon";
import { OTPInput } from "./OTPInput";

const inputsValue = (inputArray) => {
  return inputArray
    .filter((el) => {
      try {
        if (el !== null && el !== undefined) {
          return true;
        }
      } catch (err) {}
      return false;
    })
    .join("");
};

const errorRegex = /^[0-9]*$/;

export const OTP = (props) => {
  const {
    titleId,
    descriptionId,
    changeContactId,
    hintId,
    sendId,
    retryId,
    contact,
    error: { error, attemptsLeft },
    onSend,
    onNewOTP,
    coolDown,
    onChangeContact,
    changeContactLink,
  } = props;

  const inputsLength = 6;
  const [inputs, setInputs] = useState(new Array(inputsLength));

  const setInput = (index, value) => {
    const copy = [...inputs];
    copy[index] = value;
    setInputs(copy);
  };
  const clearInputs = () => {
    setInputs(new Array(inputsLength));
  };

  const showError = error;
  const inputValue = inputsValue(inputs);
  const isValid = errorRegex.test(inputValue);
  const submitButtonEnabled = isValid && inputValue.length === inputsLength;

  return (
    <Card className={style.container}>
      <h1 className={formStyle.title}>
        <FormattedMsg id={titleId} />
      </h1>
      <div
        className={style.alertBannerContainer}
        style={{ display: showError ? "block" : "none" }}
        data-testid="alert-banner-container"
      >
        <div className={style.alertBannerBar} data-testid="alert-banner-bar">
          <WarningIcon
            variant="white"
            filled={true}
            data-testid="alert-banner-icon"
          />
        </div>
        <p
          className={style.alertBannerDescription}
          data-testid="alert-banner-description"
        >
          <FormattedMsg
            id="Incorrect_OTP_error_message"
            values={{ N: attemptsLeft }}
          />
        </p>
      </div>
      <p className={style.description}>
        <FormattedMsg id={descriptionId} />
      </p>
      <div className={style.contactContainer}>
        <span className={style.contact} data-testid="contact">
          {contact + " "}
        </span>
        <Link
          data-testid="change-contact-button"
          onClick={onChangeContact}
          to={changeContactLink}
          className={style.link}
        >
          <FormattedMsg id={changeContactId} />
        </Link>
      </div>
      <OTPInput
        values={inputs}
        titleId={hintId}
        hintId="Incorrect_character_OTP_input_entry_error_message"
        error={!isValid}
        singleOnChange={(i, e) => {
          setInput(i, e);
        }}
      />
      <div className={style.buttonsContainer}>
        <Button
          data-testid="send-otp-button"
          variant={submitButtonEnabled ? "primary" : "disabled"}
          data-link-id="send-otp-button"
          onClick={() => {
            onSend(inputValue);
            clearInputs();
          }}
          className={style.sendButton}
        >
          <FormattedMsg id={sendId} />
        </Button>
        <Button
          variant="link"
          data-testid="send-new-otp"
          data-link-id="send-new-otp"
          className={style.link}
          disabled={coolDown?.secondsLeft > 0}
          onClick={() => {
            // reset input field
            onNewOTP();
            clearInputs();
          }}
        >
          <FormattedMsg
            id={coolDown?.secondsLeft > 0 ? "Resend_OTP_timer_copy" : retryId}
            values={{
              N: attemptsLeft,
              nn: (
                <span className={style.secondsLeft}>
                  {coolDown.secondsLeft}
                </span>
              ),
            }}
          />
        </Button>
      </div>
    </Card>
  );
};
