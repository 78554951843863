import ConfigHelper from "../../config/config-helper";
import Logger from "../logger/logger";
import EventsHelper from "../tracking/events-helper";
import CallHelper from "./call-helper";
import fetchHelper from "./fetch-helper";

export default function sendPDF(config) {
  return new Promise(async (resolve, reject) => {
    try {
      const call = CallHelper.createCall(config, config?.method ?? "post");

      const res = await fetchHelper(config.url, {
        method: call.method,
        headers: { ...call.headers, "Content-Type": "application/json" },
      });
      if (res.ok) {
        resolve(res.status);
        return;
      } else {
        const error = new Error("HTTP Error " + res?.status);
        error.status = res.status;
        reject(error);
      }
    } catch (error) {
      if (ConfigHelper.isDevelopmentMode()) {
        Logger.w("Error during call sendPDF: ", error);
        EventsHelper.debugEvent(`Error during call sendPDF: ${error}`);
      }
      reject(error);
    }
  });
}
