import React, { useState } from "react";

import style from "../styles/toggle-switch.module.css";

const generateStates = (options, state) => {
  let res = [];
  const { selected, select, onStateChange } = state;

  for (const [index, { state, label }] of options.entries()) {
    res.push(
      <div
        className={`${style["toggle-switch-button"]} 
            ${state === selected ? style["selected"] : ""} 
            ${index === 0 ? style["toggle-switch-left-button"] : ""}
            ${
              index === options.length - 1
                ? style["toggle-switch-right-button"]
                : ""
            }
        `}
        key={index}
        data-testid={"toggle-switch-option-" + index}
        onClick={() => {
          select(index);
          onStateChange({ index, state, label });
        }}
      >
        <p className={style["toggle-switch-button-text"]}>{label || state}</p>
      </div>
    );
  }

  return res;
};
const ToggleSwitch = (props) => {
  // options is an array of objects which must contain the field `state` and the optional `label`
  // (in case is preferred to show a different label on the switch)
  const { options, onStateChange, selected } = props;
  const [, select] = useState(selected ?? options[0]?.label);
  return (
    <div
      className={style["toggle-switch-container"]}
      data-testid={props["data-testid"] || "toggle-switch"}
    >
      {generateStates(options, {
        selected: selected ?? options[0]?.label,
        select,
        onStateChange,
      })}
    </div>
  );
};

export default ToggleSwitch;
