import Logger from "../logger/logger";

const LOADED = {};
const LOADINGCALLBACKS = {};

const ScriptHelper = {
  isLoading(url) {
    //check if any script loading with that url has already been started
    if (document.querySelector(`head>script[src="${url}"]`)) {
      return true;
    }
  },
  isLoaded(url) {
    return LOADED[url] !== undefined;
  },
  loadScript(url, callback, async, crossOrigin) {
    if (this.isLoading(url)) {
      //already started
    } else if (this.isLoaded()) {
      //already loaded, call callback after the event cycle
      setTimeout(() => {
        if (typeof callback === "function") {
          callback(LOADED[url]);
        }
      });
    } else {
      //should start loading
      //create the script loading tag and appends it to the head of the document
      const script = document.createElement("script");
      script.src = url;
      if (crossOrigin) {
        script.crossOrigin = crossOrigin;
      }
      if (async) {
        script.async = true;
      }
      if (typeof callback === "function") {
        let callbacks = LOADINGCALLBACKS[url] || [];
        callbacks.push(callback);
        LOADINGCALLBACKS[url] = callbacks;
      }
      script.onload = (...args) => {
        Logger.d(`Script ${url} loaded ${Date.now()}`);
        LOADED[url] = args;
        LOADINGCALLBACKS[url]?.forEach((element) => {
          element(...args);
        });
        LOADINGCALLBACKS[url] = null;
      };
      script.onerror = (...args) => {
        Logger.d(`Script ${url} loading error ${Date.now()}`, ...args);
        LOADED[url] = args;
        LOADINGCALLBACKS[url]?.forEach((element) => {
          element(...args);
        });
        LOADINGCALLBACKS[url] = null;
      };
      Logger.d(`Script ${url} loading started... ${Date.now()}`);
      document.head.appendChild(script);
    }
  },
};

export default ScriptHelper;
