import ConfigHelper from "../../config/config-helper";
import Logger from "../logger/logger";
import createValidator from "./validator";

const genericIbanValidator = /^[A-Z]{1,2}[A-Z0-9]*$/i;

export default function createIbanValidator(
  country,
  invalidMessage,
  notRecognizedMessage,
  notExistsMessage,
  invalidList
) {
  const isValid = (value) => {
    return !invalidList.includes(trimValue(value));
  };

  const trimValue = (value) => value.replace(/\s/g, "");
  const validator = createValidator(
    (value, focused, minLength, maxLength, required) => {
      let result;
      value = value.replace(/\s/g, "");
      const regex = country.ibanRegex;
      const completeRegex = country.ibanCompleteRegex;
      if (value.length > 0) {
        if (completeRegex.test(value)) {
          if (ConfigHelper.isValidatorsLogsEnabled()) {
            Logger.v("Complete IBAN", value);
          }
          if (!isValid(value)) {
            if (ConfigHelper.isValidatorsLogsEnabled()) {
              Logger.v("NOT EXISTS??? IBAN", value);
            }
            //IBAN ok bu tdoes not exists
            result = {
              messageId: notExistsMessage,
            };
          }
        } else if (regex.test(value)) {
          if (ConfigHelper.isValidatorsLogsEnabled()) {
            Logger.v(
              "INCOMPLETE IBAN",
              value,
              genericIbanValidator,
              genericIbanValidator.test(value),
              regex,
              regex.test(value)
            );
          }
          if (!focused) result = { messageId: invalidMessage };
        } else {
          if (genericIbanValidator.test(value)) {
            if (ConfigHelper.isValidatorsLogsEnabled()) {
              Logger.v(
                "NOT RECOGNIZED IBAN",
                value,
                genericIbanValidator,
                genericIbanValidator.test(value),
                regex,
                regex.test(value)
              );
            }
            //valid characters but invbalid country iban
            result = {
              messageId: notRecognizedMessage,
            };
          } else {
            if (ConfigHelper.isValidatorsLogsEnabled()) {
              Logger.v(
                "NOT VALID IBAN",
                value,
                genericIbanValidator,
                genericIbanValidator.test(value),
                regex,
                regex.test(value)
              );
            }
            //something is wrong with value
            result = {
              messageId: invalidMessage,
            };
          }
        }
      }
      return result;
    },
    (value, minLength, maxLength, required) => {
      let result;
      value = trimValue(value);
      const completeRegex = country.ibanCompleteRegex;
      result = completeRegex.test(value) && isValid(value);
      if (ConfigHelper.isValidatorsLogsEnabled()) {
        Logger.v(
          "submittable IBAN result ",
          result,
          value,
          completeRegex.test(value),
          isValid(value)
        );
      }
      return result;
    }
  );
  validator.exists = (value) => {
    return isValid(value);
  };
  validator.getMinLength = () => {
    return country.ibanMinLength;
  };
  validator.getMaxLength = () => {
    return country.ibanMaxLength;
  };

  validator.isLengthValid = (value) => {
    value = trimValue(value);
    return (
      country.ibanMinLength <= value.length &&
      country.ibanMaxLength >= value.length
    );
  };

  validator.trimValue = trimValue;
  validator.hasInvalidChars = (value) => {
    const regex = country.ibanRegex;
    return !regex.test(trimValue(value));
  };
  return validator;
}
