import { React, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Helpers from "../../../components/helpers";
import Logger from "../../../components/logger/logger";
import useNetwork from "../../../components/network/use-network";
import ConfigHelper from "../../../config/config-helper";

const GENERATE_OTP_FORMKEY = "formKeyGenerateOTP";
const VERIFY_OTP_FORMKEY = "formKeyVerifyOTP";
const SESSION_STORAGE_ITEM = "newSubscriptionData";
const TEMP_KEY = "temp";
const QUOTATIONS_FORMKEY = "formKeyQuotations";

const getSessionStorageData = () => {
  return Helpers.getFromSessionStorage(SESSION_STORAGE_ITEM) ?? {};
};

const NewSubscription = ({ pages }) => {
  const location = useLocation();
  const splits = location.pathname.split("/");
  const page = splits?.[splits.length - 1];
  const formData = getSessionStorageData();
  const { removeNetworkCall } = useNetwork();

  const [, setLastSet] = useState(0);
  const state = useLocation().state;
  if (state?.clean) {
    sessionStorage.removeItem(SESSION_STORAGE_ITEM);
    return (
      <Navigate
        to={location.pathname + (location.search || "")}
        replace={true}
      />
    );
  }

  const setSessionStorageData = (formData, temp = true) => {
    if (ConfigHelper.isSessionStorageLogsEnabledEnabled()) {
      Logger.v("Saving session storage data...", JSON.stringify(formData));
    }
    const result = Helpers.saveToSessionStorage(SESSION_STORAGE_ITEM, formData);
    setLastSet(Date.now());
    return result;
  };

  formData[page] = formData[page] || {};
  formData[page][TEMP_KEY] = formData[page][TEMP_KEY] || {};

  const context = {
    pages,
    page,
    pageLocation: location.pathname,
    formData,
    setData(key, value, temp = true) {
      // modify only if the value is different
      if (temp) {
        // reset the temp object if the value
        // is the same as the one saved
        if (
          value === undefined ||
          value?.length === 0 ||
          (value &&
            typeof value === "object" &&
            Object.keys(value).length === 0)
        )
          value = undefined;
        if (value !== formData[page][key])
          formData[page][TEMP_KEY][key] = value;
        else formData[page][TEMP_KEY][key] = undefined;
      } else formData[page][key] = value;

      setSessionStorageData(formData);
    },
    setPageData(save = true, erase = true) {
      // update only the modified ones
      if (save && formData[page][TEMP_KEY]) {
        Object.keys(formData[page][TEMP_KEY]).forEach(
          (key) => (formData[page][key] = formData[page][TEMP_KEY][key])
        );
      }
      if (erase) formData[page][TEMP_KEY] = undefined;
      setSessionStorageData(formData);
    },
    setGenerateOTPResult(result) {
      //save generate otp result to be used in different pages
      const data = formData[GENERATE_OTP_FORMKEY];
      if (
        !data ||
        (data.started > (data.lastResultTimestamp || 0) &&
          data.started > (data.lastErrorTimestamp || 0))
      ) {
        const now = Date.now();
        if (
          result instanceof Error ||
          (result?.status && result.status !== 202)
        ) {
          //API error
          formData[GENERATE_OTP_FORMKEY].lastError = result;
          formData[GENERATE_OTP_FORMKEY].lastErrorTimestamp = now;
          // apply long cooldown
          if (result.body?.message?.secondsUntilUnlock) {
            //reset
            formData[GENERATE_OTP_FORMKEY].timesCalled = 0;
            //apply long cooldown
            formData[GENERATE_OTP_FORMKEY].cooldown =
              now + result.body.message.secondsUntilUnlock * 1000;
          }
        } else {
          formData[GENERATE_OTP_FORMKEY].lastResultTimestamp = now;
          formData[GENERATE_OTP_FORMKEY].lastResult = result;
          //Check anti-hammering
          formData[GENERATE_OTP_FORMKEY].timesCalled =
            (formData[GENERATE_OTP_FORMKEY].timesCalled || 0) + 1;

          //apply short cooldown
          formData[GENERATE_OTP_FORMKEY].cooldown =
            now + ConfigHelper.getShortOTPCooldownSeconds() * 1000;

          //zap away any verify result
          formData[VERIFY_OTP_FORMKEY] = {
            attemptsLeft: result.attemptsLeft,
            // remove error banner
            showBanner: false,
          };
          //remove network call
          //TODO: use constants
          removeNetworkCall("verifyOTP", false);
        }
        setSessionStorageData(formData, false);
        return true;
      }
    },
    onStartGenerateOTPcall(email) {
      const started = Date.now();
      const lastEmail = formData[GENERATE_OTP_FORMKEY]?.email;
      const lastResult = formData[GENERATE_OTP_FORMKEY]?.lastResult;
      const lastResultTimestamp =
        formData[GENERATE_OTP_FORMKEY]?.lastResultTimestamp;
      const cooldown = formData[GENERATE_OTP_FORMKEY]?.cooldown;
      const timesCalled = formData[GENERATE_OTP_FORMKEY]?.timesCalled;
      //reset saved data
      formData[GENERATE_OTP_FORMKEY] = {
        email: email,
        started: started,
      };
      //check if we have the same email
      if (email === lastEmail) {
        //Keep cooldown logic and latest results
        formData[GENERATE_OTP_FORMKEY].lastResult = lastResult;
        formData[GENERATE_OTP_FORMKEY].lastResultTimestamp =
          lastResultTimestamp;
        formData[GENERATE_OTP_FORMKEY].cooldown = cooldown;
        formData[GENERATE_OTP_FORMKEY].timesCalled = timesCalled;
      }
      //If the email is different, do not copy anything as the cooldown has to restart
      setSessionStorageData(formData);
      return started;
    },
    getGenerateOTPData() {
      return formData[GENERATE_OTP_FORMKEY];
    },
    setVerifyOTPResult(result) {
      //save verify otp result to be used in different pages
      const data = formData[VERIFY_OTP_FORMKEY];
      if (
        !data ||
        (data.started > (data.lastResultTimestamp || 0) &&
          data.started > (data.lastErrorTimestamp || 0))
      ) {
        const now = Date.now();
        if (result instanceof Error) {
          //API error
          formData[VERIFY_OTP_FORMKEY].lastError = result;
          formData[VERIFY_OTP_FORMKEY].lastErrorTimestamp = now;
          switch (result.status) {
            case 409:
              const attemptsLeft = result?.body?.message?.attemptsLeft;
              if (!isNaN(attemptsLeft) && attemptsLeft > 0) {
                formData[VERIFY_OTP_FORMKEY].attemptsLeft = attemptsLeft;
              } else if (formData[VERIFY_OTP_FORMKEY].attemptsLeft) {
                formData[VERIFY_OTP_FORMKEY].attemptsLeft--;
              }
              // set the error banner visible
              formData[VERIFY_OTP_FORMKEY].showBanner = true;
              break;
            case 423:
              const secondsUntilUnlock =
                result?.body?.message?.secondsUntilUnlock;
              if (!isNaN(secondsUntilUnlock) && secondsUntilUnlock > 0) {
                formData[VERIFY_OTP_FORMKEY].cooldown =
                  Date.now() + secondsUntilUnlock * 1000;
              }
              // } else {
              //   formData[VERIFY_OTP_FORMKEY].secondsUntilUnlock =
              //     ConfigHelper.getLongOTPCooldownSeconds();
              // }
              break;
            default:
          }
        } else {
          formData[VERIFY_OTP_FORMKEY].lastResultTimestamp = now;
          formData[VERIFY_OTP_FORMKEY].lastResult = result;
          // remove the error banner (not really needed, though)
          formData[VERIFY_OTP_FORMKEY].showBanner = false;
          formData["contactDetails"].emailVerified =
            formData[GENERATE_OTP_FORMKEY]?.email;
          formData["contactDetails"].submittable = true;
        }
        //new result
        setSessionStorageData(formData, false);
        return true;
      }
    },
    onStartVerifyOTPcall(otp) {
      //reset saved data
      formData[VERIFY_OTP_FORMKEY] = {
        otp: otp,
        started: Date.now(),
        lastResultTimestamp: formData[VERIFY_OTP_FORMKEY]?.lastResultTimestamp,
        lastResult: formData[VERIFY_OTP_FORMKEY]?.lastResult,
        attemptsLeft: formData[VERIFY_OTP_FORMKEY]?.attemptsLeft,
        showBanner: formData[VERIFY_OTP_FORMKEY]?.showBanner,
      };
      setSessionStorageData(formData);
    },
    getVerifyOTPData() {
      return formData[VERIFY_OTP_FORMKEY];
    },
    getEmailData() {
      return formData[pages.NewSubscription.ContactDetails.relative]?.email;
    },
    getQuotationsData() {
      return formData[QUOTATIONS_FORMKEY];
    },
    setQuotationsResult(result) {
      //save quotations result to be used in different pages
      const data = formData[QUOTATIONS_FORMKEY];
      if (!data) formData[QUOTATIONS_FORMKEY] = {};
      const now = Date.now();
      //new result
      if (result instanceof Error) {
        //API error
        formData[QUOTATIONS_FORMKEY].error = result;
        formData[QUOTATIONS_FORMKEY].errorTimestamp = now;
      } else {
        formData[QUOTATIONS_FORMKEY].resultTimestamp = now;
        formData[QUOTATIONS_FORMKEY].plans = result;
      }
      setSessionStorageData(formData);
      return true;
    },
  };

  Logger.v("Showing newSubscription page: " + page);
  return <Outlet context={context} />;
};

export default NewSubscription;
