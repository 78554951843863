import React from "react";
import { SubscriptionCard } from "../../../components/subscription-card";
import Card from "../../../components/card";
import FormattedMsg from "../../../locale/components/formatted-msg";

import pageStyle from "../../../styles/page.module.css";
import formStyle from "../../../styles/form.module.css";

import { useNavigate, useOutletContext } from "react-router-dom";

export const ShowSubscription = (props) => {
  const { children, plan, noCard, className, containerClassName, setPageData } =
    props;
  const navigate = useNavigate();
  const { pages, pageLocation } = useOutletContext();

  return (
    <div
      className={`${pageStyle.horzCardsContainer} ${containerClassName ?? ""}`}
    >
      <SubscriptionCard
        plan={plan}
        buttonText={<FormattedMsg id="Change_subscription" />}
        onSelect={() => {
          setPageData(true, false);
          navigate(pages.NewSubscription.SelectPlan.full, {
            state: { redirectTo: pageLocation },
          });
        }}
        variant="tertiary"
        changeSubscription={true}
        showPib={true}
        className={`${className ?? ""}`}
      />
      {noCard ? (
        <div className={pageStyle.vertCardsContainer}>{children}</div>
      ) : (
        <Card
          className={formStyle.cardContainer}
          data-testid="newSubscriptionContainer"
        >
          {children}
        </Card>
      )}
    </div>
  );
};
