import React from "react";
import FormattedMsg from "../locale/components/formatted-msg";

import style from "../styles/card.module.css";

const wrapWithLabel = (labels, showLabel, children) => {
  if (showLabel && labels)
    return (
      <div className={style.container} data-testid="card-top-label">
        {labels.top?.textId && (
          <p className={`${style.label} ${style.topLabel}`}>
            <FormattedMsg id={labels.top.textId} />
          </p>
        )}
        {labels.center?.textId && (
          <p
            className={`${style.label} ${style.centralLabel}`}
            data-testid="card-central-label"
          >
            <FormattedMsg id={labels.center.textId} />
          </p>
        )}
        {children}
      </div>
    );
  return children;
};
const Card = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { className, children, showLabel, labels, ...others } = props;
  return wrapWithLabel(
    labels,
    showLabel,
    <div className={`${style.card} ${className || ""}`} {...others}>
      {props.children}
    </div>
  );
};
export default Card;
