import fetchHelper from "./fetch-helper";

/**
 * Test method to download arbitrary data using fetchHelper
 * @param  {config} config app config to get the URL
 * @param {path} path subPath to be tested
 * @returns a wrapped network call
 */
export function testData(config, path) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await fetchHelper(
        config.network.endpoints.testData.url + path
      );
      resolve(await result.blob());
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Test method to test different error pages
 * @param  {status} status status to trigger (if null, the generic error page should be triggered)
 * @returns a wrapped network call
 */
export function testError(config, status = "generic") {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await fetchHelper(
        config.network.endpoints.testError[status].url
      );

      if (!result?.ok) {
        const err = new Error();
        err.status = result?.status;
        resolve(err);
      }
    } catch (error) {
      reject(error);
    }
  });
}
