import React, { useState } from "react";
import { OverlayContext } from "../contexts/overlay-context";
import "../styles/themes.css";

const OverlayHandler = (props) => {
  const [overlay, setOverlay] = useState(null);
  const [args, setArgs] = useState(null);
  return (
    <OverlayContext.Provider
      value={{
        overlay: overlay,
        args: args,
        showOverlay: (overlay, ...args) => {
          let newOverlay = overlay || null;
          setOverlay(newOverlay);
          if (overlay && args && args.length > 0) {
            setArgs(...args);
          } else {
            setArgs(null);
          }
        },
      }}
    >
      {props.children}
    </OverlayContext.Provider>
  );
};

export default OverlayHandler;
