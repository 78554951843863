import ConfigHelper from "../../config/config-helper";

export default class Logger {
  static v(...args) {
    if (ConfigHelper.areVerboseLogsEnabled()) {
      console.debug(...args);
    }
  }
  static d(...args) {
    if (ConfigHelper.areDebugLogsEnabled()) {
      console.log(...args);
    }
  }
  static debug(...args) {
    if (ConfigHelper.areDebugLogsEnabled()) {
      console.debug(...args);
    }
  }
  static i(...args) {
    console.log(...args);
  }
  static w(...args) {
    console.warn(...args);
  }
  static e(...args) {
    console.error(...args);
  }
}
