import React, { useContext } from "react";
import FormattedMsg from "../../../locale/components/formatted-msg";
import style from "../../../styles/pages/resultPage.module.css";
import Logger from "../../../components/logger/logger";
import { NewSubscriptionPage } from "../../common/newSubscription/newSubscriptionPage";
import { useLocation, useNavigate } from "react-router-dom";
import BackButtonDisabler from "../../../components/backbutton-disabler";
import Button from "../../../components/button";
import PencilIcon from "../../../components/icons/pencil-icon";
import { ErrorPage } from "./errorPage";
import { ThemeContext } from "../../../contexts/theme-context";
import ConnectivityIcon from "../../../components/icons/connectivity-icon";
import ClockIcon from "../../../components/icons/clock-icon";

export const ResultPage = (props) => {
  const state = useLocation()?.state;
  const status = state?.status ?? props?.status;
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const pages = state?.pages ?? props?.pages;

  const ids = {
    success: {
      titleId: "Subscription_successful_title",
      content: (
        <div className={style.pageContent}>
          <div className={style.threeParagraphs}>
            <div className={style.paragraphContainer}>
              <ClockIcon
                className={style.paragraphIcon}
                variant={theme === "dark" ? "white" : "red"}
              />
              <p className={style.paragraphText}>
                <FormattedMsg id="Subscription_successful_copy_1" />
              </p>
            </div>
            <div className={style.paragraphContainer}>
              <ConnectivityIcon
                className={style.paragraphIcon}
                variant={theme === "dark" ? "white" : "red"}
              />
              <p className={style.paragraphText}>
                <FormattedMsg id="Subscription_successful_copy_2" />
              </p>
            </div>
            <div className={style.paragraphContainer}>
              <PencilIcon
                className={style.paragraphIcon}
                variant={theme === "dark" ? "white" : "red"}
              />
              <p className={style.paragraphText}>
                <FormattedMsg
                  id="Subscription_successful_copy_3"
                  values={{
                    Vodafone_link: (
                      <Button
                        className={style.link}
                        href="https://www.vodafone.de"
                        variant="link"
                        external
                      >
                        <FormattedMsg id="Vodafone_link" />
                      </Button>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
          <p className={style.footNote}>
            <FormattedMsg id="Subscription_successful_note" />
          </p>
        </div>
      ),
    },
    pending: {
      titleId: "Pending_subscription_order_title",
      descriptionId: "Pending_subscription_order_copy",
    },
    error: {
      error: true,
    },
    downloadSuccessful: {
      titleId: "Download_successful_title",
      descriptionId: "Download_successful_copy",
    },
    subscriptionFailed: {
      titleId: "subscription_activation_failed_error_page_title",
      descriptionId: "subscription_activation_failed_Nth_time_error_page_copy",
      mainButtonId: "contact_telesales_button",
      mainButtonLink: {
        external: true,
        href: "https://www.vodafone.de/hilfe/produktberatung-und-bestellung.html?mtlscops=true",
      },
      hidePrimaryButton: false,
      hideSecondaryButton: true,
      error: true,
      heroImage: "eSIMError",
    },
    retrySubscription: {
      titleId: "subscription_activation_failed_error_page_title",
      descriptionId: "subscription_activation_failed_1st_time_error_page_copy",
      mainButtonId: "Restart_subscription_button",
      hidePrimaryButton: false,
      primaryAction: () => {
        navigate(pages.NewSubscription.SelectPlan.full, {
          state: { clean: true },
        });
      },
      hideSecondaryButton: true,
      error: true,
      heroImage: "eSIMError",
    },
    eSIMStorageFullError: {
      titleId: "eSIM_storage_full_error_title",
      descriptionId: "eSIM_storage_full_error_copy",
      mainButtonId: "Retry_eSIM_download_button",
      error: true,
    },
    eSIMAlreadyInstalledError: {
      titleId: "profile_installed_error_page_title",
      descriptionId: "profile_installed_error_page_copy",
      mainButtonId: "customer_care_button",
      hidePrimaryButton: false,
      hideSecondaryButton: true,
      mainButtonLink: {
        external: true,
        href: "https://www.vodafone.de/hilfe/vodafone-hotline.html?b_id=681&c_id=sea_cic_307:olp_key_bko__per_mul_dive_service&extProvId=330&extPu=vodafone-gaw-g&extCr=582168080157&extPm=130674924142&extTg=kwd-196452402&extLi=130674924142&keyword=kwd-196452402&extAP=&extMT=e&ds_rl=1238684&gad_source=1&gclid=Cj0KCQiA35urBhDCARIsAOU7QwmOUQ9zIqKJYWfhPiLJasKyk5YXaj2BuGdyKLNm4BxgdUTSMm_Iu7MaAmKREALw_wcB&gclsrc=aw.ds",
      },
      heroImage: "eSIMError",
      error: true,
    },
    downloadedAvailableError: {
      titleId: "downloaded_available_status_page_title",
      descriptionId: "downloaded_available_status_page_copy",
      hidePrimaryButton: false,
      hideSecondaryButton: true,
      mainButtonId: "customer_care_button",
      mainButtonLink: {
        external: true,
        href: "https://www.vodafone.de/hilfe/vodafone-hotline.html?b_id=681&c_id=sea_cic_307:olp_key_bko__per_mul_dive_service&extProvId=330&extPu=vodafone-gaw-g&extCr=582168080157&extPm=130674924142&extTg=kwd-196452402&extLi=130674924142&keyword=kwd-196452402&extAP=&extMT=e&ds_rl=1238684&gad_source=1&gclid=Cj0KCQiA35urBhDCARIsAOU7QwmOUQ9zIqKJYWfhPiLJasKyk5YXaj2BuGdyKLNm4BxgdUTSMm_Iu7MaAmKREALw_wcB&gclsrc=aw.ds",
      },
      heroImage: "eSIMError",
      error: true,
    },
    downloadFailed: {
      titleId: "eSIM_download_failed_recoverable_error_title",
      descriptionId: "eSIM_download_failed_recoverable_error_copy",
      mainButtonId: "Retry_eSIM_download_button",
      error: true,
    },
  }[status] ?? { error: true };

  if (ids.error)
    return (
      <BackButtonDisabler>
        <ErrorPage {...ids} status={status === "error" ? null : status} />
      </BackButtonDisabler>
    );

  Logger.d("Result page", props, ids);
  return (
    <NewSubscriptionPage className={style.page}>
      <BackButtonDisabler>
        <div className={`${style.heroImage} ${style[status + "HeroImage"]}`} />
        <div
          className={`${ids.descriptionId ? style.container : ""} ${
            style.pageContainer
          }`}
          data-testid={"result-page-container-" + status}
        >
          <h1 className={style.title} data-testid="result-page-title">
            <FormattedMsg id={ids.titleId} />
          </h1>
          {ids.descriptionId ? (
            <p className={style.info} data-testid="result-page-copy">
              <FormattedMsg
                id={ids.descriptionId}
                values={{
                  link: (
                    <Button
                      className={style.link}
                      href="https://www.vodafone.de"
                      variant="link"
                      external
                    >
                      <FormattedMsg id="Vodafone_link" />
                    </Button>
                  ),
                }}
              />
            </p>
          ) : (
            ids.content
          )}
          {ids.mainButtonId && (
            <Button
              data-link-id="main-button"
              data-testid="main-button"
              variant="primary"
              className={style.mainButton}
            >
              <FormattedMsg id={ids.mainButtonId} />
            </Button>
          )}
        </div>
      </BackButtonDisabler>
    </NewSubscriptionPage>
  );
};
