import { useState } from "react";
import Card from "../../../components/card";
import { SubscriptionCard } from "../../../components/subscription-card";
import ToggleSwitch from "../../../components/toggle-switch";
import { NewSubscriptionPage } from "../newSubscription/newSubscriptionPage";

export function TestSubscriptionCardPage() {
  const defaultPlans = [
    {
      pricePlanId: "PRODUCT_PRICING_TEXT_2",
      pricePlanCategory: "product",
      pricing: "10,99 EUR",
      recurrency: "per month",
      durationInMonths: 1,
      durationLabel: "Flex contract",
      durationTag: "Monthly Contract Tag",
      commercialName: "Smart Tech L",
      offerDescription: "First month for free*",
      hasPromotion: true,
      promotionDetails:
        "*Der erste Monat der für den Tarif vereinbarten Laufzeit ist kostenlos, danach gelten die bei Vertragsabschluss vereinbarten Laufzeiten und Preise. Dieses Angebot kann pro Gerät einmal alle 24 Monate eingelöst werden.",
      serviceInformation: "20 GB",
      keySellingPoints: [
        "High-speed internet in 4G/LTE & 5G networks",
        "Renewed every month. You can cancel any time",
      ],
      additionalTexts: [
        {
          key: "Data volume included",
          value: "20GB with 4G/LTE Max, in Germany and within EU Roaming",
          tags: ["included_services"],
        },
        {
          key: "High speed data volume",
          value:
            "20GB with maximum bandwith of 500 mbits/s in download and 100 mbit/s in upload speed, when data allowance  is reached it's 32 kbit/s in download and upload speed",
          tags: ["included_services"],
        },
        {
          key: "Monthly cost",
          value: "10,99 €",
          tags: ["contract_info"],
        },
        {
          key: "Discount",
          value: "1st month for free",
          tags: ["contract_info"],
        },
        {
          key: "promotionDetails",
          value:
            "Der erste Monat der für den Tarif vereinbarten Laufzeit ist kostenlos, danach gelten die bei Vertragsabschluss vereinbarten Laufzeiten und Preise. Dieses Angebot kann pro Gerät einmal alle 24 Monate eingelöst werden.",
          tags: ["promotionDetails", "Discount"],
        },
        {
          key: "Duration",
          value: "Cancellable at any time",
          tags: ["contract_info"],
        },
        {
          key: "Plan management",
          value: "Enabled in the My Vodafone app",
          tags: ["other_info"],
        },
      ],
      termsAndConditionsSummary: "<p>Essentials Plan T&Cs</p>",
      moreInfoUrl:
        "https://www.vodafone.de/media/downloads/pdf/VF-SmartTech-L-Flex-Mobil-Maerz-2021.pdf",
      contract: false,
    },
    {
      pricePlanId: "PRODUCT_PRICING_TEXT_3",
      pricePlanCategory: "product",
      pricing: "5,99 EUR",
      recurrency: "per month",
      durationInMonths: 1,
      durationLabel: "24-month contract",
      durationTag: "24-Month contract tag",
      commercialName: "Smart Tech M",
      offerDescription: "First month for free*",
      hasPromotion: true,
      promotionDetails:
        "*Der erste Monat der für den Tarif vereinbarten Laufzeit ist kostenlos, danach gelten die bei Vertragsabschluss vereinbarten Laufzeiten und Preise. Dieses Angebot kann pro Gerät einmal alle 24 Monate eingelöst werden.",
      serviceInformation: "3 GB",
      keySellingPoints: [
        "High-speed internet in 4G/LTE & 5G networks",
        "Renewed every month. You can cancel any time",
      ],
      additionalTexts: [
        {
          key: "Data volume included",
          value: "3GB with 4G/LTE Max, in Germany and within EU Roaming",
          tags: ["included_services"],
        },
        {
          key: "High speed data volume",
          value:
            "3GB with maximum bandwith of 500 mbits/s in download and 100 mbit/s in upload speed, when data allowance  is reached it's 32 kbit/s in download and upload speed",
          tags: ["included_services"],
        },
        {
          key: "Monthly cost",
          value: "5,99 €",
          tags: ["contract_info"],
        },
        {
          key: "Discount",
          value: "1st month for free",
          tags: ["contract_info"],
        },
        {
          key: "promotionDetails",
          value:
            "Der erste Monat der für den Tarif vereinbarten Laufzeit ist kostenlos, danach gelten die bei Vertragsabschluss vereinbarten Laufzeiten und Preise. Dieses Angebot kann pro Gerät einmal alle 24 Monate eingelöst werden.",
          tags: ["promotionDetails", "Discount"],
        },
        {
          key: "Duration",
          value: "24 month plan, afterwards cancellable at any time",
          tags: ["contract_info"],
        },
        {
          key: "Plan management",
          value: "Enabled in the My Vodafone app",
          tags: ["other_info"],
        },
      ],
      termsAndConditionsSummary: "<p>Essentials Plan T&Cs</p>",
      moreInfoUrl:
        "https://www.vodafone.de/media/downloads/pdf/VF-SmartTech-M-Mobil-Maerz-2021.pdf",
      contract: true,
    },
  ];

  const [plans, setPlan] = useState(structuredClone(defaultPlans));
  const [checked, setChecked] = useState(
    structuredClone(defaultPlans).map((p) => {
      const res = {};
      Object.keys(p).forEach((k) => {
        if (Array.isArray(p[k])) {
          res[k] = p[k].map((v) => !!v);
        } else res[k] = !!p[k];
      });
      return res;
    })
  );

  const keys = plans.map((plan) => Object.keys(plan));
  let options = plans.map((plan) => ({
    label: plan.durationLabel,
    state: plan.durationTag,
    tag: plan.durationTag,
  }));

  const [selected, select] = useState(options[0].state);
  const handleToggleSwitch = (newState) => {
    select(newState.state);
  };

  return (
    <NewSubscriptionPage applyMeasures={true}>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!options.includes(null) && (
            <ToggleSwitch
              options={options}
              selected={selected}
              onStateChange={handleToggleSwitch}
              data-testid="switch-plans"
            />
          )}
          <SubscriptionCard
            plan={selected === options[0].state ? plans[0] : plans[1]}
            showPib={true}
          />
        </div>
        {keys.map((p, i) => (
          <Card
            key={i + "-card"}
            style={{
              height: "fit-content",
              opacity: selected === options[i].state ? "100%" : "50%",
            }}
          >
            <form style={{ display: "flex", "--card-width": "100%" }}>
              <div key={i + "-div"}>
                <p key={i + "-title"}>Card {i}</p>
                <ul key={i}>
                  {p.map((k) => {
                    const plan = plans[i];
                    const res = (
                      <li key={i + k}>
                        <input
                          id={k}
                          type="checkbox"
                          onChange={(e) => {
                            setPlan((prev) => {
                              const res = structuredClone(prev);
                              res[i][k] = e.target.checked
                                ? defaultPlans[i][k]
                                : null;
                              return res;
                            });
                            setChecked((prev) => {
                              const res = structuredClone(prev);
                              if (
                                Array.isArray(defaultPlans[i][k]) &&
                                e.target.checked
                              ) {
                                res[i][k] = Array(
                                  defaultPlans[i][k].length
                                ).fill(true);
                              } else res[i][k] = e.target.checked;
                              return res;
                            });
                          }}
                          checked={checked[i][k]}
                          key={k + "-checkbox"}
                        />
                        <label htmlFor={k} key={k + "-label"}>
                          {"\t" + k}
                        </label>
                      </li>
                    );
                    if (Array.isArray(plan[k])) {
                      return [
                        res,
                        <ol key={k + "-ol"}>
                          {plan[k].map((v, j) => {
                            if (typeof v === "object") {
                              v = JSON.stringify(v);
                            }

                            return (
                              <li key={j + v}>
                                <input
                                  id={v}
                                  type="checkbox"
                                  onChange={(e) => {
                                    setPlan((prev) => {
                                      const res = structuredClone(prev);
                                      res[i][k][j] = e.target.checked
                                        ? defaultPlans[i][k][j]
                                        : undefined;
                                      return res;
                                    });
                                    setChecked((prev) => {
                                      const res = structuredClone(prev);
                                      res[i][k][j] = e.target.checked;
                                      return res;
                                    });
                                  }}
                                  checked={checked[i][k][j]}
                                  key={v + "-checkbox"}
                                />
                                <label htmlFor={v} key={v + "-label"}>
                                  {"\t" + v}
                                </label>
                              </li>
                            );
                          })}
                        </ol>,
                      ];
                    } else return res;
                  })}
                </ul>
              </div>
            </form>
          </Card>
        ))}
      </div>
    </NewSubscriptionPage>
  );
}
