import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ErrorPage } from "../pages/common/newSubscription/errorPage";
import Logger from "../components/logger/logger";
import LocaleContext from "../contexts/locale-context";
import Helpers from "../components/helpers";
import ConfigHelper from "../config/config-helper";
import FlowContext from "../contexts/flow-context";

function getEntryPoint(location, ...entryPointsArgs) {
  if (location && entryPointsArgs) {
    for (const entryPoints of entryPointsArgs) {
      if (entryPoints) {
        const entryPointKey = Object.keys(entryPoints).find(
          (key) => entryPoints?.[key]?.full === location.pathname
        );
        //return always the first result found
        const entryPoint = entryPoints[entryPointKey];
        if (entryPoint) {
          return {
            entryPoint,
          };
        }
      }
    }
  }
}

export default function UserHandler({ children }) {
  const { setLocale: setLocaleOriginal, isLocaleSupported } =
    useContext(LocaleContext);
  const [error, setError] = useState(null);
  const location = useLocation();
  const flowContext = useContext(FlowContext);
  const flow = flowContext?.flow;
  const entryPoints = flowContext?.getFlowPages?.()?.EntryPoints;
  useEffect(() => {
    const setLocale = (...args) => {
      const result = setLocaleOriginal(...args);
      setError(false);
      return result;
    };
    //Check if a location is a valid entry point
    const epResult = getEntryPoint(location, entryPoints);
    if (epResult) {
      const entryPoint = epResult.entryPoint;
      // CHANGED: we are getting the token only when the entryPoint.hasToken BUT
      // we're not giving any error because the user could still go to OneNumber
      // even without a token
      const token =
        (entryPoint.hasToken && Helpers.getTokenFromLocation(location)) || null;
      //take the country and lang info from the location or the default country from the entryPoint
      const locationCountry =
        Helpers.getCountryFromLocation(location) ||
        (entryPoint.defaultCountry
          ? Helpers.getCountry(entryPoint.defaultCountry)
          : undefined);
      const locationLang = Helpers.getLangFromLocation(location);
      //remove country lang and the token from the url and save it
      const redirectPars = Helpers.getLocationParams(location);
      if (ConfigHelper.isDevelopmentMode()) {
        Logger.d(
          "EntryPoint location checker",
          locationCountry,
          locationLang,
          token?.substring(0, 5) || "NO TOKEN",
          redirectPars
        );
      }
      if (locationCountry) {
        //Save previous cookie info
        const sessionCookiesPreferences = Helpers.getCookiesPreferences();
        //Clear any session data
        sessionStorage.clear();
        //check if we should restore the cookies preferences from the temporary
        if (entryPoint.restoreCookiePreferences) {
          if (sessionCookiesPreferences) {
            Helpers.saveCookiesPreferences(sessionCookiesPreferences);
          }
        }
        //we have a supported country in location info, we have to save it
        Helpers.saveUserSessionInfo(
          locationCountry,
          token,
          isLocaleSupported(locationLang) ? locationLang : null,
          redirectPars
        );
        setLocale(
          isLocaleSupported(locationLang)
            ? locationLang
            : isLocaleSupported(locationCountry.lang)
            ? locationCountry.lang
            : "en"
        );
        if (error === null || error) {
          setError(false);
        }
      } else {
        //invalid country in URL or no country at all!
        if (!error) {
          setError(true);
        }
      }
    } else {
      //read the country and lang information from the sessionStorage
      const savedCountry = Helpers.getSavedCountry();
      const savedLang = Helpers.getSavedLang();
      //Use saved country, take the saved lang
      if (savedLang && isLocaleSupported(savedLang)) {
        //try to use the lang
        if (setLocale(savedLang)) {
          //already saved
        } else {
          //ok, not doing anything
        }
      } else {
        if (savedCountry) {
          setLocale(savedCountry.intl);
        } else if (!error) {
          setError(true);
        }
      }
    }
  }, [
    location,
    setLocaleOriginal,
    isLocaleSupported,
    error,
    setError,
    flow,
    entryPoints,
  ]);
  return error ? (
    <ErrorPage status={404} />
  ) : error === null ? (
    <div style={{ display: "flex", flexDirection: "column", flexGrow: "1" }} />
  ) : (
    children
  );
}
