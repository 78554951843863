import React, { useState, useEffect, useRef } from "react";
import Chevron from "../components/chevron";
import pageStyle from "../styles/page.module.css";
import style from "../styles/carousel.module.css";

const DotIndicator = ({ length, idx, onSelect }) => {
  const res = [];

  for (let i = 0; i < length; i++) {
    res.push(
      <div
        onClick={() => onSelect(i)}
        key={"dot-" + i}
        data-testid={"dot-" + i}
        className={`${style.indicatorDot} ${
          idx === i && style.indicatorDotSelected
        }`}
      />
    );
  }

  return (
    <div
      className={style.indicatorContainer}
      data-testid="dot-indicator-container"
    >
      {res}
    </div>
  );
};

const getSteps = (length, cardsPerStep) => {
  return Math.ceil(length / (cardsPerStep || 1));
};

const wrapChildren = (
  testid,
  children,
  cardsPerStep,
  alwaysWrap,
  wrapperFn,
  wrapperClassname
) => {
  if (children) {
    if (children.length > 1 && (cardsPerStep > 1 || alwaysWrap)) {
      const wrapped = [];
      let j;

      const steps = getSteps(children.length, cardsPerStep);
      const unevenness = children.length % steps;
      for (let i = 0; i < steps; i++) {
        const base = i * cardsPerStep;
        const elementsToBeWrapped = [];
        for (j = 0; j < cardsPerStep; j++) {
          const idx = base + j;
          if (idx < children.length) {
            elementsToBeWrapped.push(children[idx]);
          }
        }
        const isLastUneven = i === steps - 1 && unevenness > 0;
        wrapped.push(
          wrapperFn ? (
            wrapperFn(elementsToBeWrapped, cardsPerStep, isLastUneven)
          ) : (
            <div
              className={`${pageStyle.horzCardsContainer} ${
                style.contentSlide
              } ${isLastUneven ? style.lastUneven : ""} ${
                wrapperClassname || ""
              }`}
              key={`${testid}-${children.length}-${cardsPerStep}-${i}`}
            >
              {elementsToBeWrapped}
            </div>
          )
        );
      }
      return wrapped;
    } else return children;
  }
};

const Carousel = (props) => {
  const [step, setStep] = useState(0);
  const [start, setStart] = useState(0);
  const {
    className,
    onChange,
    cardsPerStep,
    endless,
    wrapperFn,
    wrapperClassname,
    alwaysWrap,
  } = props;
  const [numOfCards, setNumOfCards] = useState(cardsPerStep);
  const length = getSteps(props.children.length, cardsPerStep);
  const contentRef = useRef(null);

  // watches for element change
  useEffect(() => {
    if (onChange) {
      onChange(step === length - 1);
    }
  }, [step, length, onChange]);

  useEffect(() => {
    //if number of cards changes, we have to adapt the steps
    if (cardsPerStep !== numOfCards) {
      const newStep = Math.floor((step * numOfCards) / cardsPerStep);
      setNumOfCards(cardsPerStep);
      const toleranceCounterbalance = 0.015;
      const width = contentRef.current.children[0].offsetWidth;
      const newOffset = (width + width * toleranceCounterbalance) * newStep;
      setStep(newStep);
      setTimeout(() => {
        setStart(newOffset);
        contentRef.current.scrollLeft = newOffset;
      }, 100);
    }
  }, [cardsPerStep, numOfCards, step, length]);

  const next = () => updateStep(step + 1 === length ? length - 1 : step + 1);
  const previous = () => updateStep(step === 0 ? 0 : step - 1);

  // scroll handling
  const [startTimer, setStartTimer] = useState(null);
  const unevenness = props.children.length % length;

  const scrollHandler = (e) => {
    if (start !== 0 && !start) {
      setStart(e.currentTarget.scrollLeft);
    }

    if (startTimer) clearTimeout(startTimer);
    setStartTimer(
      setTimeout(() => {
        const end = e.target.scrollLeft;
        const tolerance = 0.01;
        const delta = end - (start ?? end); // `end - end` is required when resizing
        const slideWidth = unevenness
          ? e.target.children[0].offsetWidth / cardsPerStep
          : e.target.children[0].offsetWidth;
        const offset = Math.round(delta / slideWidth - tolerance);

        setStep((prev) =>
          prev + offset < 0
            ? 0
            : prev + offset > length - 1
            ? length - 1
            : prev + offset
        );
        setStart(end);
      }, 50)
    );
  };

  const updateStep = (i) => {
    const slideWidth = contentRef.current.children[0].offsetWidth;
    const toleranceCounterbalance = 0.015;
    const balancedSlideWidth =
      slideWidth + slideWidth * toleranceCounterbalance;

    if (i !== step) {
      setStep(i);
      setStart(null);
      contentRef.current.scrollLeft += balancedSlideWidth * (i - step);
    }
  };

  const testid = props["data-testid"] || "carousel";

  return (
    <div
      className={`${style.carouselContainer} ${endless ? style.endless : ""} ${
        className || ""
      }`}
      style={style}
      data-testid={testid}
    >
      <div className={style.controls}>
        {!endless && (
          <Chevron
            style={{ visibility: step > 0 ? "visible" : "hidden" }}
            className={`${style.chevron} ${style.chevronLeft}`}
            direction="left"
            background={true}
            onClick={(e) => {
              e.stopPropagation();
              previous();
            }}
            data-testid="prev-button"
          />
        )}
        <div
          className={style.carouselContent}
          ref={contentRef}
          onScroll={scrollHandler}
          data-testid="carousel-content"
        >
          {wrapChildren(
            testid,
            props.children,
            cardsPerStep,
            alwaysWrap,
            wrapperFn,
            wrapperClassname
          )}
        </div>
        {!endless && (
          <Chevron
            style={{ visibility: step < length - 1 ? "visible" : "hidden" }}
            className={`${style.chevron} ${style.chevronRight}`}
            direction="right"
            background={true}
            onClick={(e) => {
              e.stopPropagation();
              next();
            }}
            data-testid="next-button"
          />
        )}
      </div>
      {length > 1 && (
        <DotIndicator length={length} idx={step} onSelect={updateStep} />
      )}
    </div>
  );
};

export default Carousel;
