const developmentMode = process.env.NODE_ENV === "development";
const unitTestMode = process.env.NODE_ENV === "test";

const localConfig = {
  developmentMode: developmentMode,
  debugLogs: developmentMode,
  verboseLogs: developmentMode,
  themeSwitcherButton: developmentMode,
  testPages: developmentMode,
  instanaEnabled: !unitTestMode && !developmentMode,
  callbacksSimulatingEnabled: developmentMode,
  validatorsLogsEnabled: false,
  sessionStorageLogsEnabled: false,
  locationVerificationEnabled: true,
  autoCompleteTimeout: 1000,
  locationLogsEnabled: false,
  disableEdgeWebviewDetection: false,
  disableSafariAutofillCheck: false,
  enableOnDeviceTestPages: false,
};

export default class ConfigHelper {
  static getLocalConfig(unsafe = false) {
    if (unsafe) {
      //unsafe, returns the objet itself
      //should be used by config handler to set some variable
      return localConfig;
    } else {
      //safe, returns a copy
      return { ...localConfig };
    }
  }

  static isDevelopmentMode() {
    return localConfig.developmentMode;
  }

  static isThemeSwitcherButtonEnabled() {
    return localConfig.themeSwitcherButton;
  }

  static shouldShowMissingTranslations() {
    return false;
  }

  static isTestPagesEnabled() {
    return localConfig.testPages;
  }

  static areDebugLogsEnabled() {
    return localConfig.debugLogs;
  }

  static areVerboseLogsEnabled() {
    return localConfig.verboseLogs;
  }

  static shouldShowTranslationsDebug() {
    return false;
  }

  static getGenerateOTPMaxCallsBeforeLongCooldown() {
    return 5;
  }

  static getVerifyOTPPredefinedAttemptsLeft() {
    return 5;
  }

  static getContractSummaryAttempts() {
    return 5;
  }

  static getContractSummaryAttemptDelaySeconds() {
    return this.isDevelopmentMode() ? 5 : 24;
  }

  static getShortOTPCooldownSeconds() {
    if (this.isDevelopmentMode()) {
      return 5;
    } else {
      return 60;
    }
  }

  static getLongOTPCooldownSeconds() {
    if (this.isDevelopmentMode()) {
      return 10;
    } else {
      return 300;
    }
  }

  static isInstanaEnabled() {
    return localConfig.instanaEnabled;
  }

  static isCallbackSimulatingEnabled() {
    return localConfig.callbacksSimulatingEnabled;
  }

  static isValidatorsLogsEnabled() {
    return localConfig.validatorsLogsEnabled;
  }

  static isSessionStorageLogsEnabledEnabled() {
    return localConfig.sessionStorageLogsEnabled;
  }

  static isLocationVerificationEnabled() {
    return localConfig.locationVerificationEnabled;
  }

  static isLocationLogsEnabled() {
    return localConfig.locationLogsEnabled;
  }

  static getAutoCompleteTimeout() {
    return localConfig.autoCompleteTimeout;
  }

  static isEdgeWebviewDetectionDisabled() {
    return localConfig.disableEdgeWebviewDetection;
  }

  static isSafariAutofillCheckDisabled() {
    return localConfig.disableSafariAutofillCheck;
  }

  static isOnDeviceTestPageEnabled() {
    return localConfig.enableOnDeviceTestPages;
  }
}
