import fetchHelper from "./fetch-helper";

export default function fetchConfigData() {
  return new Promise(async (resolve, reject) => {
    try {
      const configUrl =
        process.env.REACT_APP_CONFIG_URL || "/config/config.json";
      const result = await fetchHelper(configUrl, {
        cache: "no-cache",
      });
      if (result.ok) {
        try {
          const text = await result.text();
          try {
            //if it's a valid JSON, returns it
            resolve(JSON.parse(text));
          } catch (err) {
            //JSON is not correct, if we recieved an HTML, probably the config is not deployed
            if (text?.startsWith("<!DOCTYPE")) {
              //It's a doctype, so the config has not been deployed
              throw new Error("No config file found!");
            } else {
              //any other error
              throw err;
            }
          }
        } catch (err) {
          //if we're here, we got an error on result.text(), just throw it
          throw err;
        }
      } else throw new Error("Result is not ok: " + result?.status);
    } catch (error) {
      reject(error);
    }
  });
}
