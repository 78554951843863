import React, { useState, useEffect } from "react";
import { ThemeContext } from "../contexts/theme-context";
import "../styles/themes.css";

const THEMES = {
  dark: "dark",
  default: "light",
};

const switchTheme = (theme) => {
  const classList = document.querySelector(":root").classList;
  const themeValues = Object.values(THEMES).filter((value) => !!value);
  for (let cl of classList) {
    if (themeValues.includes(cl)) {
      classList.remove(cl);
    }
  }
  if (theme) {
    classList.add(theme);
  }
};

export default function ThemeHandler(props) {
  const defaultValue = props.theme
    ? props.theme === THEMES.dark
      ? THEMES.dark
      : THEMES.default
    : window.matchMedia("(prefers-color-scheme: dark)")?.matches
    ? THEMES.dark
    : THEMES.default;
  const [theme, setTheme] = useState(defaultValue);

  useEffect(() => {
    switchTheme(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
