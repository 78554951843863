import style from "../styles/on-off-switch.module.css";

export const OnOffSwitch = ({ value, onChange, "data-testid": dataTestId }) => {
  return (
    <div
      className={value ? style.containerOn : style.containerOff}
      onClick={(e) => {
        e.stopPropagation();
        onChange(!value);
      }}
      data-testid={dataTestId}
    >
      <div className={style.indicator} />
    </div>
  );
};
