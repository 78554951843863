import React, { useContext, useRef } from "react";
import { OverlayContext } from "../contexts/overlay-context";
import style from "../styles/overlay.module.css";
import { AlertOverlay } from "./alert-overlay";
import BackButtonDisabler from "./backbutton-disabler";
import { CookiePolicyOverlay } from "./cookies-overlay";
import DatePickerModal from "./datepicker-modal";
import { InfoOverlay } from "./info-overlay";
import LoadingSpinner from "./loading-spinner";
import PdfOverlay from "./pdf-overlay";
import { PrivacyPolicyOverlay } from "./privacy-policy-overlay";
import { SaveOverlay } from "./save-overlay";
import SendPdfOverlay from "./send-pdf-overlay";
import { DetailsOverlay } from "./subscription-card";

const Overlays = (props) => {
  const { overlay, args, showOverlay } = useContext(OverlayContext);
  const background = useRef(null);
  let component = null;

  if (typeof overlay === "string")
    switch (overlay) {
      case "loading":
        component = (
          <LoadingSpinner className={style.container} {...props} {...args} />
        );
        break;
      case "pdf-overlay":
        component = (
          <PdfOverlay className={style.container} {...props} {...args} />
        );
        break;
      case "send-pdf-overlay":
        component = (
          <SendPdfOverlay className={style.container} {...props} {...args} />
        );
        break;
      case "plan-details":
        component = (
          <DetailsOverlay className={style.container} {...props} {...args} />
        );
        break;
      case "datepicker-modal":
        component = (
          <DatePickerModal className={style.container} {...props} {...args} />
        );
        break;
      case "info-overlay":
        component = (
          <InfoOverlay className={style.container} {...props} {...args} />
        );
        break;
      case "cookies-overlay":
        component = (
          <CookiePolicyOverlay
            className={style.container}
            {...props}
            {...args}
          />
        );
        break;
      case "privacy-policy":
        component = (
          <PrivacyPolicyOverlay
            {...props}
            {...args}
            className={style.container}
          />
        );
        break;
      case "yes-no-alert":
        component = (
          <AlertOverlay {...props} {...args} className={style.container} />
        );
        break;
      case "unsaved-changes":
        component = (
          <SaveOverlay {...props} {...args} className={style.container} />
        );
        break;
      // case "terms-and-conditions":
      //   component = (
      //     <TermsAndConditions
      //       {...props}
      //       className={style.container}
      //       close={onClose}
      //       onClick={onClickOutside}
      //     />
      //   );
      //   break;
      // case "cookie-policy":
      //   component = (
      //     <CookiePolicy
      //       {...props}
      //       className={style.container}
      //       close={onClose}
      //       onClick={onClickOutside}
      //     />
      //   );
      //   break;
      default:
    }
  else component = overlay;
  const rootComponent = document.body;
  if (component) {
    if (!rootComponent.classList.contains("noscroll")) {
      rootComponent.classList.add("noscroll");
    }
  } else if (rootComponent.classList.contains("noscroll")) {
    rootComponent.classList.remove("noscroll");
  }
  return component ? (
    <BackButtonDisabler
      onBack={() => {
        // showOverlay();
        //return true because we don't need to keep stopping back button
        return true;
      }}
    >
      <div
        ref={background}
        className={`${style.overlay} ws10`}
        onClick={(e) => {
          if (e.target === background.current) {
            e.preventDefault();
            showOverlay();
            component?.props?.onClose?.(e);
          }
        }}
      >
        {component}
      </div>
    </BackButtonDisabler>
  ) : null;
};

export default Overlays;
