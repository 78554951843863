import React from "react";
import style from "../styles/icon.module.css";
/**
 * ImageVariants JSX component
 * Takes some props:
 * - variants: an object containing some name => imagename association. A 'default' name is mandatory
 * - variant: the variant that we want to render (if not found, the component will use the default in variants)
 * Every other prop is passed down to the img tag
 */
const ImageVariants = (props) => {
  const {
    variants,
    variant,
    alt,
    onClick,
    onPointerDown,
    className,
    "data-testid": dataTestId,
    ...other
  } = props;
  if (!variants?.default) {
    throw new Error("ImageVariants: a default variant has to be specified!");
  } else {
    const image = Object.keys(variants).includes(variant)
      ? variants[variant]
      : variants.default;
    const ReactComponent = image;
    return (
      <div
        className={style.iconClickbox}
        onClick={onClick}
        onPointerDown={onPointerDown}
      >
        {
          //could be an svg or however a react component, try to render it as it is
          typeof image === "object" ? (
            <ReactComponent
              alt={alt}
              className={`${style.icon} ${className}`}
              {...other}
              data-testid={dataTestId ?? "icon-container"}
              onClick={onClick}
            />
          ) : (
            <img
              data-testid={dataTestId ?? "icon-container"}
              className={`${style.icon} ${className}`}
              src={image}
              alt={alt}
              onClick={onClick}
              {...other}
            />
          )
        }
      </div>
    );
  }
};

export default ImageVariants;
