import Helpers from "../helpers";

const CallHelper = {
  createCall: (callConfig, httpMethod = "get", jsonBody, rawBody, rawType) => {
    const country = Helpers.getSavedCountry();
    if (!country) {
      throw new Error("Creating call but no country was specified");
    }
    //ALWAYS use the country intl as lang, as we don't support multi-language per country
    const lang = country.intl;
    const headers = {
      Accept: "application/json",
      "Accept-Language": lang,
      //Country code removed as it should be taken from the token
      // "vf-country-code": country.name.toUpperCase(),
      "vf-trace-transaction-id": Helpers.generateTransactionId(),
    };
    let body;
    if (jsonBody) {
      headers["Content-type"] = "application/json";
      body = jsonBody;
    } else if (rawBody) {
      body = rawBody;
      if (rawType) {
        headers["Content-type"] = rawType;
      }
    }
    //add any additional header from the call configuration
    if (callConfig.headers) {
      for (const header of callConfig.headers) {
        const index = header.indexOf(":");
        if (index < 0) {
          //no :, copy header as it is
          headers[header] = undefined;
        } else {
          headers[header.substring(0, index)] = header.substring(index + 1);
        }
      }
    }
    //add token if it's specified in the call configuration
    if (callConfig.token) {
      headers["Authorization"] = `Bearer ${Helpers.getSavedToken()}`;
    }
    return {
      url: callConfig.url,
      method: httpMethod,
      body: body,
      headers: headers,
    };
  },
};

export default CallHelper;
