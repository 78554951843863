import React from "react";
import Button from "../../../components/button";
import Card from "../../../components/card";
import FormattedMsg from "../../../locale/components/formatted-msg";
import style from "../../../styles/pages/errorPage.module.css";
import MobilePlans from "../../../oda/mobile-plans";
import Logger from "../../../components/logger/logger";
import { NewSubscriptionPage } from "../../common/newSubscription/newSubscriptionPage";
import { useNavigate } from "react-router-dom";

export const ErrorPage = (props) => {
  const {
    titleId,
    descriptionId,
    mainButtonId,
    mainButtonLink,
    secondaryActionId,
    hidePrimaryButton,
    primaryAction,
    children,
    hideSecondaryButton,
    descriptionValues,
    status,
    heroImage,
    withCard,
  } = props;

  const navigate = useNavigate();

  // use default strings, if no override is provided
  const defaultIds = {
    400: {
      titleId: "Bad_request_page_title",
      descriptionId: "Bad_request_page_copy",
      hidePrimaryButton: true,
      secondaryActionId: "Quit_setup_button",
    },
    401: {
      titleId: "Unauthorized_user_page_title",
      descriptionId: "Unauthorized_user_page_copy",
      hidePrimaryButton: true,
      secondaryActionId: "Quit_setup_button",
    },
    404: {
      titleId: "Page_not_found_page_title",
      descriptionId: "Page_not_found_page_copy",
      mainButtonId: "Sending_OTP_failed_server_error_page_button",
      hidePrimaryButton: false,
      primaryAction: () => navigate(-1),
      hideSecondaryButton: true,
    },
    500: {
      titleId: "Sending_OTP_failed_server_error_page_title",
      descriptionId: "Sending_OTP_failed_server_error_page_copy",
      mainButtonId: "Sending_OTP_failed_server_error_page_button",
      hidePrimaryButton: false,
      primaryAction: () => navigate(-1),
      hideSecondaryButton: false,
      secondaryActionId: "Quit_setup_button",
    },
    502: {
      titleId: "Sending_OTP_failed_server_error_page_title",
      descriptionId: "Sending_OTP_failed_server_error_page_copy",
      mainButtonId: "Sending_OTP_failed_server_error_page_button",
      hidePrimaryButton: false,
      primaryAction: () => navigate(-1),
      hideSecondaryButton: false,
      secondaryActionId: "Quit_setup_button",
    },
    default: {
      titleId: "Catch_all_failed_page_title",
      descriptionId: "Catch_all_failed_page_copy",
      hidePrimaryButton: true,
      secondaryActionId: "Quit_setup_button",
      hideSecondaryButton: false,
    },
  };

  // initialize the current ids with the overrides
  let ids = {
    titleId,
    descriptionId,
    mainButtonId,
    mainButtonLink,
    secondaryActionId,
    hidePrimaryButton,
    primaryAction,
    hideSecondaryButton,
  };

  // set the defaults where necessary
  const statusIds = defaultIds[status] ?? defaultIds.default;
  Object.keys(ids).forEach(
    (id) => (ids[id] = ids[id] === undefined ? statusIds[id] : ids[id])
  );

  const errorId = status ? `error` + status : "UnknownError";
  const showPrimaryButton = !ids.hidePrimaryButton && ids.mainButtonId;
  const showSecondaryButton = !ids.hideSecondaryButton && ids.secondaryActionId;

  Logger.d("Error page", props, ids);
  return (
    <NewSubscriptionPage
      className={style.page}
      contentClassName={style.content}
    >
      <div
        className={`${style.heroImage} ${
          heroImage
            ? style[heroImage]
            : status === 404
            ? style.error404
            : status === 400 || status >= 500
            ? style.serverError
            : ""
        }`}
        data-testid="error-page-hero"
        data-test-errorid={errorId}
      />
      <Card className={`${style.container} ${withCard ? "" : style.noCard}`}>
        <h1 className={style.title} data-testid="error-page-title">
          <FormattedMsg id={ids.titleId} />
        </h1>
        <p className={style.info} data-testid="error-page-description">
          <FormattedMsg id={ids.descriptionId} values={descriptionValues} />
        </p>
        {children}
        {(showPrimaryButton || showSecondaryButton) && (
          <div className={style.buttonsContainer}>
            {showPrimaryButton && (
              <Button
                variant="primary"
                data-link-id="send-new-otp"
                data-testid="primary-action-button"
                onClick={ids.primaryAction}
                href={ids.mainButtonLink?.href}
                external={ids.mainButtonLink?.external}
              >
                <FormattedMsg id={ids.mainButtonId} />
              </Button>
            )}
            {showSecondaryButton && (
              <Button
                variant="secondary"
                data-link-id="quit-setup"
                data-testid="quit-button"
                onClick={() => {
                  try {
                    if (!MobilePlans.hasCallbacks()) {
                      //if no callbacks are available, use fake ones
                      MobilePlans.toggleSimulation();
                    }
                    MobilePlans.cancelPurchase();
                  } catch (error) {
                    Logger.e("Cannot call cancel purchase", error);
                  }
                }}
              >
                <FormattedMsg id={ids.secondaryActionId} />
              </Button>
            )}
          </div>
        )}
      </Card>
    </NewSubscriptionPage>
  );
};
