import OverlayCard from "./overlay-card";
import style from "../styles/alert-overlay.module.css";
import FormattedMsg from "../locale/components/formatted-msg";
import Button from "./button";

export const AlertOverlay = ({
  titleId,
  descriptionId,
  yesButtonId,
  noButtonId,
  onYes,
  onNo,
  onClose,
  className,
  "data-testid": dataTestId,
}) => {
  return (
    <OverlayCard
      title={<FormattedMsg id={titleId} />}
      data-testid={dataTestId ?? "alert-banner-container"}
      alignTitleToX={true}
      className={`${style.container} ${className}`}
      closeBoxClassName={style.closeBox}
      childrenClassName={style.content}
      close={(e) => onNo(e, true)}
    >
      <p className={style.description}>
        <FormattedMsg id={descriptionId} />
      </p>
      <div className={style.buttonsContainer}>
        <Button
          variant="secondary"
          data-testid="no-button"
          onClick={(e) => {
            e.preventDefault();
            onNo(e, false);
          }}
        >
          <FormattedMsg id={noButtonId} />
        </Button>
        <Button
          variant="primary"
          data-testid="yes-button"
          onClick={(e) => {
            e.preventDefault();
            onYes(e);
          }}
        >
          <FormattedMsg id={yesButtonId} />
        </Button>
      </div>
    </OverlayCard>
  );
};
