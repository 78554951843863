import { useContext } from "react";
import GoogleLoader from "./google-loader";
import LocationIqLoader from "./locationiq-loader";
import PlacesContext from "./places-context";
import ConfigContext from "../../contexts/config-context";
import NetworkContext from "../../contexts/network-context";

export default function PlacesLoader({ provider, children }) {
  const { config } = useContext(ConfigContext);
  const networkContext = useContext(NetworkContext);
  let component;
  switch (provider?.toLowerCase?.()) {
    case "google":
      component = (
        <GoogleLoader apiKey={config.location.google.apiKey}>
          {children}
        </GoogleLoader>
      );
      break;
    case "locationiq":
      component = (
        <LocationIqLoader apiKey={config.location.locationIQ.apiKey}>
          {children}
        </LocationIqLoader>
      );
      break;
    default:
      component = (
        <div>
          <h2>Error loading location API</h2>
        </div>
      );
  }
  return (
    <PlacesContext.Provider value={{ provider, networkContext }}>
      {component}
    </PlacesContext.Provider>
  );
}
