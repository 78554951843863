import { useContext, useEffect, useState } from "react";
import Helpers from "../../../components/helpers";
import Logger from "../../../components/logger/logger";
import networkProfileData from "../../../components/network/network-profile";
import useNetwork from "../../../components/network/use-network";
import ConfigContext from "../../../contexts/config-context";
import MobilePlans from "../../../oda/mobile-plans";
import { CallHandler } from "../../../components/call-handler";
import { ErrorPage } from "./errorPage";
import { NewSubscriptionPage } from "../../common/newSubscription/newSubscriptionPage";
import { ResultPage } from "./result";
import InPageLoadingSpinner from "../../../components/in-page-loading-spinner";
import EventsHelper from "../../../components/tracking/events-helper";

const nwProfileTag = "networkProfileTag";

const getStatus = (status) => {
  if (!status) return "pending";
  switch (status?.toLowerCase()) {
    // Network Profile statuses
    case "downloaded":
      return "downloadedAvailableError";
    case "installed":
      return "eSIMAlreadyInstalledError";
    // Mobile Plans statuses
    case "success":
      return "downloadSuccessful";
    case "insufficientspaceoncard":
      return "eSIMStorageFullError";
    default:
      return "error";
  }
};

const LoadingPage = ({ call, callback, spinnerMessageId, mobilePlans }) => {
  useEffect(() => {
    EventsHelper.debugEvent(
      "Showing loading spinner with message " + spinnerMessageId
    );
  }, [spinnerMessageId]);
  return (
    <NewSubscriptionPage spinnerMessageId={spinnerMessageId}>
      {mobilePlans ? (
        <InPageLoadingSpinner spinnerMessageId={spinnerMessageId} />
      ) : (
        <CallHandler call={call} onFinish={callback} />
      )}
    </NewSubscriptionPage>
  );
};

const lastCalled = {};

export const DownloadProfile = (props) => {
  const { config } = useContext(ConfigContext);
  const { startNetworkCall, checkNetworkCall } = useNetwork();
  const [, setTick] = useState(Date.now());

  const { profile, error } = Helpers.getNetworkProfile();
  const { result } = Helpers.getDownloadSIMStatus();
  const activationResult = result?.activationResult;

  useEffect(() => {
    const MPEventListener = (event) => {
      EventsHelper.debugEvent(
        "Received internal MPEvent: " + JSON.stringify(event)
      );
      Helpers.saveSIMDownloadStatus(event.detail);
      setTick(Date.now());
    };
    window.addEventListener("MPEvent", MPEventListener, false);
    return () => {
      window.removeEventListener("MPEvent", MPEventListener, false);
    };
  }, [setTick]);

  Logger.d(profile, error);

  const getNetworkProfileCall =
    !profile && !error ? checkNetworkCall(nwProfileTag, false, false) : null;

  const callback = (result) => {
    Logger.d(result, result instanceof Error);
    const res =
      result instanceof Error ? { error: result } : { profile: result };
    Helpers.saveNetworkProfile(res);
    setTick(Date.now());
  };

  const getNetworkProfile = () => {
    setTick(Date.now());
    return startNetworkCall(
      nwProfileTag,
      () => networkProfileData(config),
      false,
      false
    );
  };

  const downloadSIM = (activationCode) => {
    if (!activationCode) {
      Logger.d("Tried to download eSIM without an active profile");
      return null;
    }

    /** Prevent double calling */
    if (!lastCalled.mp) {
      if (!MobilePlans.hasCallbacks()) {
        EventsHelper.debugEvent(
          "Cannot find MobilePlans callbacks, turn on simulation"
        );
        MobilePlans.toggleSimulation(true);
      }

      EventsHelper.debugEvent("Trying to install the newtwork profile...");
      MobilePlans.notifyPurchase(activationCode);
      lastCalled.mp = Date.now();
    }
  };

  if (!profile && !error && !getNetworkProfileCall?.isPending()) {
    lastCalled.mp = null;
    getNetworkProfile();
  }

  if (getNetworkProfileCall?.isPending()) {
    return (
      <LoadingPage
        call={getNetworkProfileCall}
        spinnerMessageId="Checking_for_subscription_loading"
        callback={callback}
      />
    );
  }

  if (activationResult) {
    if (profile && !lastCalled.activationResultEvent) {
      lastCalled.activationResultEvent = Date.now();
      EventsHelper.debugEvent(
        `Activation completed with status ${activationResult}`
      );
    }
    return <ResultPage status={getStatus(activationResult)} />;
  }

  if (profile || error?.status === 404 || error?.status === 409) {
    if (error) return <ResultPage status="pending" />;
    if (profile && !lastCalled.profileEvent) {
      lastCalled.profileEvent = Date.now();
      EventsHelper.debugEvent(
        "Received a network profile with status " + profile.status
      );
    }
    if (profile.status?.toLowerCase() === "released" && !activationResult) {
      downloadSIM(profile.activationCode);
      return (
        <LoadingPage
          spinnerMessageId="Downloading_eSIM_loading"
          mobilePlans={true}
        />
      );
    } else return <ResultPage status={getStatus(profile.status)} />;
  }

  if (error) {
    return <ErrorPage status={error.status} />;
  }
};
