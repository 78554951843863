import { useContext } from "react";
import PlacesContext from "./places-context";

export default function LocationIqLoader({ children, apiKey }) {
  //dummy class, just check the PlacesContext
  const placesContext = useContext(PlacesContext);
  if (placesContext?.provider?.toLowerCase?.() !== "locationiq") {
    throw new Error("Cannot instantiate loader as no PlacesHelper");
  } else {
    //save api key into the context to be used later from the helper
    placesContext.apiKey = apiKey;
  }
  return children;
}
