import createDefaultTextValidator from "./default-text-validator";

const cityRegex = /^[\p{L}().,'\-\d\s/]+$/u;
const numberValidator = /^[\d/\-\s.A-Z]+$/i;
const streetValidator = /^[\p{L}.'\-\d\s/]+$/u;
const secondLineRegex = cityRegex;
const postcodeRegex = /^\d+$/;

const createAddressValidator = (
  textErrorMessage,
  invalidErrorMessage,
  validRegex,
  isValidated,
  setValidated,
  locationVerificationEnabled,
  isLoading,
  setLoading
) => {
  const textValidator = createDefaultTextValidator(
    textErrorMessage,
    textErrorMessage,
    validRegex
  );
  const validator = {
    getError: (value, focused, minLength, maxLength, required) => {
      let result;
      if (locationVerificationEnabled) {
        if (value.length > 0) {
          const validated = isValidated(value);
          if (!validated) {
            const textError = textValidator.getError(
              value,
              focused,
              minLength,
              maxLength,
              required
            );
            //if any texterror occurred, show it
            if (textError) {
              return textError;
            } else if (validated === false && !focused && !isLoading?.()) {
              // has been validated and it's invalid OR not yet validated and not in focus
              result = {
                messageId: invalidErrorMessage,
              };
            }
          }
        }
      } else {
        result = textValidator.getError(
          value,
          focused,
          minLength,
          maxLength,
          required
        );
      }
      return result;
    },
    isSubmittable: (value, minLength = 0, maxLength = Infinity, required) => {
      let result;
      if (
        locationVerificationEnabled &&
        value?.length >= minLength &&
        value?.length <= maxLength &&
        required
      ) {
        result = isValidated();
      } else {
        result = textValidator.isSubmittable(
          value,
          minLength,
          maxLength,
          required
        );
      }
      return result;
    },
    isValidated: (value) => {
      return isValidated(value);
    },
    setValidated: (value) => {
      return setValidated(value);
    },
    validateInvalidChars: (value) => {
      return value.length === 0 || validRegex.test(value);
    },
    isLoading: () => {
      return isLoading?.();
    },
    setLoading: (value) => {
      return setLoading?.(value);
    },
  };
  return validator;
};

export default function createAddressValidators(
  setValidated,
  isValidated,
  locationVerificationEnabled,
  setLoading,
  isLoading,
  prefix
) {
  return {
    postcode: createAddressValidator(
      "Postcode_invalid_character_error_message",
      "Postcode_not_recognized_error_message",
      postcodeRegex,
      (value) => isValidated((prefix ? prefix : "") + "postcode", value),
      (value) => setValidated((prefix ? prefix : "") + "postcode", value),
      locationVerificationEnabled,
      (value) => isLoading((prefix ? prefix : "") + "postcode", value),
      (value) => setLoading((prefix ? prefix : "") + "postcode", value)
    ),
    city: createAddressValidator(
      "Invalid_city_error_message",
      "City_not_recognized_error_message",
      cityRegex,
      (value) => isValidated((prefix ? prefix : "") + "city", value),
      (value) => setValidated((prefix ? prefix : "") + "city", value),
      locationVerificationEnabled,
      (value) => isLoading((prefix ? prefix : "") + "city", value),
      (value) => setLoading((prefix ? prefix : "") + "city", value)
    ),
    street: createAddressValidator(
      "Street_name_invalid_character_error_message",
      "Street_name_not_recognized_error_message",
      streetValidator,
      (value) => isValidated((prefix ? prefix : "") + "street", value),
      (value) => setValidated((prefix ? prefix : "") + "street", value),
      locationVerificationEnabled,
      (value) => isLoading((prefix ? prefix : "") + "street", value),
      (value) => setLoading((prefix ? prefix : "") + "street", value)
    ),
    houseNumber: createAddressValidator(
      "Invalid_House_number_error_message",
      "House_number_not_recognized_error_message",
      numberValidator,
      (value) => isValidated((prefix ? prefix : "") + "houseNumber", value),
      (value) => setValidated((prefix ? prefix : "") + "houseNumber", value),
      false,
      (value) => isLoading((prefix ? prefix : "") + "houseNumber", value),
      (value) => setLoading((prefix ? prefix : "") + "houseNumber", value)
    ),
    secondLine: createDefaultTextValidator(
      "2nd_line_of_address_invalid_character_error_message",
      "2nd_line_of_address_invalid_character_error_message",
      secondLineRegex,
      true
    ),
  };
}
