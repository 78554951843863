import React, { useContext } from "react";
import ImageVariants from "../image-variants";
import InfoIconBlack from "../../images/info-icon-black.svg";
import InfoIconWhite from "../../images/info-icon-white.svg";
import { ThemeContext } from "../../contexts/theme-context";

export const InfoIcon = (props) => {
  const { theme } = useContext(ThemeContext);
  const variants = {
    light: InfoIconBlack,
    dark: InfoIconWhite,
    default: InfoIconBlack,
  };
  return <ImageVariants variants={variants} variant={theme} {...props} />;
};
