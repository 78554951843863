/**
 * Country class
 * @param {*} name
 * @param {*} alternativeName
 * @param {*} flag
 * @param {*} prefix
 * @param {*} lang
 * @param {*} intl
 * @param {*} timezone
 * @returns
 */
export const Country = (
  name,
  alternativeName,
  flag,
  prefix,
  lang,
  intl,
  timezone,
  ibanRegex,
  ibanCompleteRegex,
  ibanMinLength,
  ibanMaxLength
) => ({
  name,
  alternativeName,
  flag,
  prefix,
  lang,
  intl,
  timezone,
  ibanRegex,
  ibanCompleteRegex,
  ibanMinLength,
  ibanMaxLength,
});

export const Countries = {
  de: Country(
    "de",
    undefined,
    undefined,
    "49",
    "de",
    "de-DE",
    "Europe/Berlin",
    /^(DE\d{0,20}|D)$/i,
    /^DE\d{20}$/i,
    22,
    22
  ),
};
