import React, { lazy, Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoadingHandler from "./providers/loading-handler";
import OverlayHandler from "./providers/overlay-handler";
import Overlays from "./components/overlays";

import ThemeHandler from "./providers/theme-handler";
import FullPageLoading from "./pages/full-page-loading";
import { ErrorPage } from "./pages/siegfried/newSubscription/errorPage";
import ConfigHandler from "./providers/config-handler";
import NetworkHandler from "./providers/network-handler";
import ConfigHelper from "./config/config-helper";
import Logger from "./components/logger/logger";
import UserHandler from "./providers/user-handler";
import { CookiesHandler } from "./providers/cookies-handler";
import { CookiesBanner } from "./components/cookies-banner";
import UnsupportedWebviewPage from "./pages/unsupported-webview-page.jsx";
import getSiegfriedRoutes from "./routes/siegfried-routes.js";
import getJasmineRoutes from "./routes/jasmine-routes.js";
import getTestRoutes from "./routes/test-routes.js";
import FlowHandler from "./providers/flow-handler.jsx";
import { PageUpdater } from "./components/page-updater.jsx";
const LocaleHandler = lazy(() => import("./providers/locale-handler"));

const slashRegex = /\/\/+/g;

function UrlCleaner({ children }) {
  const location = useLocation();
  if (location.pathname.match(slashRegex)) {
    const newPath =
      location.pathname.replace(slashRegex, "/") +
      (location.search || "") +
      (location.hash || "");
    Logger.d(
      `Found some invalid path, replacing ${location.toString()} with ${newPath}`
    );
    return <Navigate to={newPath} replace={true} />;
  } else {
    return { ...children };
  }
}

export default function App() {
  useEffect(() => {
    const eventListener = (event) => {
      Logger.d("UNHANDLED REJECTION", event);
    };
    window.addEventListener("unhandledrejection", eventListener);
    return () => {
      window.removeEventListener("unhandledrejection", eventListener);
    };
  }, []);
  const testPagesEnabled = ConfigHelper.isTestPagesEnabled();
  return (
    <ThemeHandler>
      <FlowHandler>
        <Suspense fallback={<FullPageLoading />}>
          <NetworkHandler>
            <LocaleHandler>
              <ConfigHandler>
                <LoadingHandler>
                  <PageUpdater />
                  <UserHandler>
                    <OverlayHandler>
                      <UnsupportedWebviewPage>
                        <CookiesHandler>
                          <Routes>
                            {getSiegfriedRoutes(testPagesEnabled)}
                            {getJasmineRoutes(testPagesEnabled)}
                            {getTestRoutes(testPagesEnabled)}
                            <Route
                              path="*"
                              element={
                                <UrlCleaner>
                                  <ErrorPage status={404} />
                                </UrlCleaner>
                              }
                            />
                          </Routes>
                          <CookiesBanner />
                          <Overlays />
                        </CookiesHandler>
                      </UnsupportedWebviewPage>
                    </OverlayHandler>
                  </UserHandler>
                </LoadingHandler>
              </ConfigHandler>
            </LocaleHandler>
          </NetworkHandler>
        </Suspense>
      </FlowHandler>
    </ThemeHandler>
  );
}
