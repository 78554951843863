import OverlayCard from "./overlay-card";
import style from "../styles/cookies-overlay.module.css";
import overlayStyle from "../styles/overlay.module.css";
import policyStyle from "../styles/privacy-policy.module.css";
import { useState } from "react";
import FormattedMsg from "../locale/components/formatted-msg";
import { Accordion } from "./accordion";
import { useContext } from "react";
import { CookiesContext } from "../contexts/cookies-context";
import { OnOffSwitch } from "./on-off-switch";
import Button from "./button";
import { OverlayContext } from "../contexts/overlay-context";

const isEnabledOrDefault = (cookie) => {
  if (cookie?.enabled === null) {
    return cookie?.default;
  } else {
    return cookie?.enabled;
  }
};

export const CookiePolicyOverlay = (props) => {
  const { opened } = props;
  const [scrolled, setScrolled] = useState(false);
  const { setCookie, cookies, save, hasCookies } = useContext(CookiesContext);
  const { showOverlay } = useContext(OverlayContext);
  const [, setTick] = useState(Date.now());

  return (
    <OverlayCard
      data-testid="cookies-overlay-container"
      scrollable
      closeBoxClassName={scrolled ? overlayStyle.closeBoxScrolled : ""}
      className={style.container}
    >
      <div
        className={`${overlayStyle.scrollableContent} ${style.content}`}
        onScroll={(e) => setScrolled(e.target.scrollTop > 0)}
      >
        <h1 className={policyStyle.title}>
          <FormattedMsg id="Cookie_management_overlay_title" />
        </h1>
        <p className={policyStyle.paragraph}>
          <FormattedMsg id="Cookie_management_overlay_copy" />
        </p>
        <h1 className={policyStyle.separatedTitle}>
          <FormattedMsg id="Manage_consent_preferences_title" />
        </h1>
        <div className={policyStyle.accordionsContainer}>
          {hasCookies("strictly-necessary") && (
            <Accordion
              opened={opened === "strictly-necessary"}
              headlineId="Necessary_cookies_list_item"
              headIndicator={
                <span className={policyStyle.textIndicator}>
                  <FormattedMsg id="Always_active_indicator" />
                </span>
              }
              data-testid="strictly-necessary-accordion"
            >
              <div className={policyStyle.xlGaps}>
                <p className={policyStyle.paragraph}>
                  <FormattedMsg id="Necessary_cookies_description" />
                </p>
                <h3 className={policyStyle.subsectionTitle}>
                  <FormattedMsg id="Used_cookies_title" />
                </h3>
                <p className={policyStyle.paragraph}>
                  {cookies["strictly-necessary"].cookieNames.join(", ")}
                </p>
              </div>
            </Accordion>
          )}
          {hasCookies("performance") && (
            <Accordion
              opened={opened === "performance"}
              headlineId="Performance_cookies_list_item"
              headIndicator={
                <OnOffSwitch
                  data-testid="performance-cookies-switch"
                  value={isEnabledOrDefault(cookies.performance)}
                  onChange={(value) => {
                    setCookie("performance", value);
                    setTick(Date.now());
                  }}
                />
              }
              data-testid="performance-accordion"
            >
              <div className={policyStyle.xlGaps}>
                <p className={policyStyle.paragraph}>
                  <FormattedMsg id="Performance_cookies_description" />
                </p>
                <h3 className={policyStyle.subsectionTitle}>
                  <FormattedMsg id="Used_cookies_title" />
                </h3>
                <p className={policyStyle.paragraph}>
                  {cookies.performance.cookieNames.join(", ")}
                </p>
              </div>
            </Accordion>
          )}
          {hasCookies("functional") && (
            <Accordion
              data-testid="functional-accordion"
              headlineId="Functional_cookies_list_item"
              headIndicator={
                <OnOffSwitch
                  data-testid="functional-cookies-switch"
                  value={isEnabledOrDefault(cookies.functional)}
                  onChange={(value) => {
                    setCookie("functional", value);
                    setTick(Date.now());
                  }}
                />
              }
            >
              <div className={policyStyle.xlGaps}>
                <p className={policyStyle.paragraph}>
                  <FormattedMsg id="Functional_cookies_description" />
                </p>
                <h3 className={policyStyle.subsectionTitle}>
                  <FormattedMsg id="Used_cookies_title" />
                </h3>
                <p className={policyStyle.paragraph}>
                  {cookies.functional.cookieNames.join(", ")}
                </p>
              </div>
            </Accordion>
          )}
          {hasCookies("targeting") && (
            <Accordion
              data-testid="targeting-accordion"
              headlineId="Targeting_cookies_list_item"
              headIndicator={
                <OnOffSwitch
                  data-testid="targeting-cookies-switch"
                  value={isEnabledOrDefault(cookies.targeting)}
                  onChange={(value) => {
                    setCookie("targeting", value);
                    setTick(Date.now());
                  }}
                />
              }
            >
              <div className={policyStyle.xlGaps}>
                <p className={policyStyle.paragraph}>
                  <FormattedMsg id="Targeting_cookies_description" />
                </p>
                <h3 className={policyStyle.subsectionTitle}>
                  <FormattedMsg id="Used_cookies_title" />
                </h3>
                <p className={policyStyle.paragraph}>
                  {cookies.targeting.cookieNames.join(", ")}
                </p>
              </div>
            </Accordion>
          )}
        </div>
      </div>

      <div className={`${overlayStyle.buttons} ${style.buttonsContainer}`}>
        <Button
          data-testid="accept-all-button"
          variant="secondary"
          onClick={() => {
            setCookie("all", true, true);
            showOverlay();
          }}
        >
          <FormattedMsg id="Accept_cookies_button" />
        </Button>
        <Button
          data-testid="reject-all-button"
          variant="secondary"
          onClick={() => {
            setCookie("all", false, true);
            showOverlay();
          }}
        >
          <FormattedMsg id="Reject_cookies_button" />
        </Button>
        <Button
          data-testid="save-button"
          variant="primary"
          onClick={() => {
            save();
            showOverlay();
          }}
        >
          <FormattedMsg id="Save_settings_button" />
        </Button>
      </div>
    </OverlayCard>
  );
};
