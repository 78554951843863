import { useNavigate } from "react-router-dom";
import Button from "../../../components/button";
import Card from "../../../components/card";

export default function TestPages({ pages }) {
  const navigate = useNavigate();

  return (
    <main>
      <Card style={{ margin: "auto" }}>
        <h1>Test Page</h1>
        {Object.keys(pages.TestPages).map((p) => (
          <Button
            onClick={() => navigate(pages.TestPages[p], { state: { pages } })}
            style={{ marginBottom: "8px", width: "210px" }}
            key={p + "-button"}
          >
            {p}
          </Button>
        ))}
      </Card>
    </main>
  );
}
