import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useOutletContext } from "react-router-dom";
import style from "../../../styles/form.module.css";
import pageStyle from "../../../styles/page.module.css";
import FormattedMsg from "../../../locale/components/formatted-msg";
import Divider from "../../../components/divider";
import Button from "../../../components/button";
import { useContext } from "react";
import LocaleContext from "../../../contexts/locale-context";
import { NewSubscriptionPage } from "./newSubscriptionPage";
import { PasswordInput } from "../../../components/password-input";
import createPasswordValidator from "../../../components/input-validators/password-validator";
import createValidator from "../../../components/input-validators/validator";

const passwordRules = [
  {
    label: <FormattedMsg id="Password_condition_1" />,
    check: (v) => v.length >= 6 && v.length <= 10,
  },
  {
    label: <FormattedMsg id="Password_condition_2" />,
    check: (v) => /^[A-Za-z0-9ÄäÖöÜüß]+$/iu.test(v),
  },
  {
    label: <FormattedMsg id="Password_condition_3" />,
    check: (v) => !/[ÄäÖöÜüß]/iu.test(v),
  },
  {
    label: <FormattedMsg id="Password_condition_4" />,
    check: (v) => /^[A-Za-z0-9]+$/iu.test(v),
  },
];

const passwordValidator = createPasswordValidator(passwordRules);

export function CreatePassword() {
  const navigate = useNavigate();
  const { formatMessage } = useContext(LocaleContext);
  const {
    formData: outletFormData,
    setData,
    setPageData,
    pages,
  } = useOutletContext();
  const { selectPlan, createPassword: data } = outletFormData;
  const plan = selectPlan?.plan;

  const repeatValidator = createValidator(
    (value) => {
      return formData.password?.length > 0
        ? value.length === 0 || value === formData.password
          ? null
          : { messageId: "Not_matching_password_error" }
        : null;
    },
    (value) => {
      return formData.password === value;
    }
  );

  const [formData, setFormData] = useState({
    password: data?.password || "",
    repeatPassword: data?.repeatPassword || "",
  });

  const [submittable, setSubmittable] = useState(false);
  useEffect(() => {
    const isSubmittable =
      passwordValidator.isSubmittable(formData.password) &&
      repeatValidator.isSubmittable(formData.repeatPassword);
    setSubmittable(isSubmittable);

    if (isSubmittable !== submittable)
      setData("submittable", isSubmittable, false);
  }, [repeatValidator, formData, setData, submittable]);

  if (!plan) {
    return <Navigate to={pages.NewSubscription.SelectPlan.full} />;
  }

  const handleInput = (field, value) => {
    setFormData((prev) => {
      let res = { ...prev };
      res[field] = value;
      return res;
    });

    setData(field, value);
  };

  return (
    <NewSubscriptionPage
      heroTitle={{ textId: "Contact_details_hero_title" }}
      plan={plan}
    >
      <div className={style.container}>
        <h1 className={style.title}>
          <FormattedMsg id="Create_password_page_title" />
        </h1>
        <p className={pageStyle.description}>
          {formatMessage("Create_password_page_copy")}
        </p>
        <div className={style.row} data-testid="create-password-form">
          <PasswordInput
            data-testid="password-input"
            minLength={6}
            pattern={/^\w$/}
            value={formData.password}
            rules={passwordRules}
            label={formatMessage("Password_input_label") + "*"}
            onChange={(e) => handleInput("password", e.target.value)}
            required={true}
            validator={passwordValidator}
          />
        </div>
        <div className={style.row}>
          <PasswordInput
            data-testid="repeat-password-input"
            value={formData.repeatPassword}
            label={formatMessage("Confirm_password_input_label") + "*"}
            onChange={(e) => handleInput("repeatPassword", e.target.value)}
            validator={repeatValidator}
          />
        </div>
        <div className={style.footer}>
          <div className={style.row}>
            <Divider />
          </div>
          <div className={style.buttonsContainer}>
            <Button
              data-testid="back-button"
              variant="tertiary"
              onClick={() => {
                setPageData();
                navigate(pages.NewSubscription.ContactDetails.full);
              }}
              data-link-id="back_button"
            >
              <FormattedMsg id="Secondary_action" />
            </Button>
            <Button
              data-testid="next-button"
              data-link-id="next_button"
              variant={submittable ? "primary" : "disabled"}
              onClick={() => {
                if (submittable) {
                  setPageData();
                  navigate(pages.NewSubscription.PaymentDetails.full);
                }
              }}
            >
              <FormattedMsg id="Next_CTA" />
            </Button>
          </div>
        </div>
      </div>
    </NewSubscriptionPage>
  );
}
