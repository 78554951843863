import { lazy, useContext, useRef, useState } from "react";
import { DropdownList } from "./dropdown-list";
import ValidatedInputText from "./input-validators/validated-input-text";
import LocaleContext from "../contexts/locale-context";
import Helpers from "./helpers";
import style from "../styles/phone-number-input.module.css";
const CountryFlagIcon = lazy(() => import("./icons/country-flag-icon"));

export const PhoneNumberInput = ({
  value,
  label,
  onChange,
  validator,
  "data-testid": dataTestId,
  ...rest
}) => {
  const { internationalPNPrefixes, localeCountryList } =
    useContext(LocaleContext);
  const number = validator.getNumber(value) || "";
  const code =
    Object.keys(internationalPNPrefixes).find(
      (k) => internationalPNPrefixes[k] === validator?.getPrefix(value)
    ) ?? "DE";
  const [selectedNation, selectNation] = useState(
    value
      ? {
          value: code,
          icon: <CountryFlagIcon code={code} />,
          label: Object.keys(localeCountryList).find(
            (k) => localeCountryList[k] === code
          ),
        }
      : {
          value: "DE",
          icon: <CountryFlagIcon code="DE" />,
          label: Object.keys(localeCountryList).find(
            (k) => localeCountryList[k] === "DE"
          ),
        }
  );
  // use space as a delimiter
  const prefix = internationalPNPrefixes[selectedNation.value];

  const country = Helpers.getSavedCountry()?.name.toUpperCase();
  const countryName = Object.keys(localeCountryList).find(
    (k) => localeCountryList[k] === country
  );
  const options = [];

  for (const nation in localeCountryList) {
    const isoCode = localeCountryList[nation];
    if (internationalPNPrefixes[isoCode])
      options.push({
        value: isoCode,
        label: `${internationalPNPrefixes[isoCode]} ${nation}`,
        icon: <CountryFlagIcon code={isoCode} />,
      });
  }

  options.sort((a, b) =>
    validator.getNumber(a.label)?.localeCompare(validator.getNumber(b.label))
  );
  const opco = {
    label: `${internationalPNPrefixes[country]} ${countryName}`,
    value: country,
    hideInSearch: true,
    icon: <CountryFlagIcon code={country} />,
  };
  options.unshift(opco);

  const inputRef = useRef();

  return (
    <ValidatedInputText
      ref={inputRef}
      type="tel"
      inputMode="numeric"
      forceShowLabel={true}
      hidePlaceholder={true}
      {...rest}
      action={{
        position: "left",
        content: (
          <DropdownList
            className={style.list}
            value={selectedNation}
            defaultValue={opco}
            mode="icon"
            selectedValueToShow={prefix}
            searchFieldLabelId={"Dropdown_menu_search_text"}
            filter={(v, current) => {
              return (
                !current ||
                (validator
                  .getNumber(v.label)
                  ?.toLowerCase?.()
                  .startsWith(current.toLowerCase()) &&
                  !v.hideInSearch)
              );
            }}
            onChange={(v) => {
              selectNation(v);
              onChange?.(
                value.replace(prefix, internationalPNPrefixes[v.value])
              );
              setTimeout(() => inputRef.current.focus(), 20);
            }}
            options={options}
          />
        ),
      }}
      data-testid={dataTestId ?? "phone-number-input"}
      value={number}
      label={label}
      className={style.input}
      onChange={(e) => {
        onChange?.(prefix + " " + e.target.value);
      }}
      validator={validator}
    />
  );
};
