import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import FormattedMsg from "../locale/components/formatted-msg";
import style from "../styles/OTPInput.module.css";

const generateInputs = (params) => {
  const {
    inputFieldsRefs,
    error,
    singleOnChange,
    select: [selected, select],
    ...rest
  } = params;

  const res = [];
  for (let i = 0; i < inputFieldsRefs.length; i++) {
    res[i] = (
      <input
        aria-autocomplete="none"
        type="tel"
        ref={inputFieldsRefs[i]}
        data-testid={"otp-input-" + i}
        onFocus={() => select(true)}
        onBlur={() => select(false)}
        onKeyDown={(e) => {
          // if backspace is pressed in an empty input box,
          // move to the previous
          if (
            inputFieldsRefs[i].current.value === "" &&
            (e.code === "Backspace" || e.key === "Backspace")
          ) {
            inputFieldsRefs[i - 1 >= 0 ? i - 1 : i].current.focus();
            e.preventDefault();
          }
        }}
        minLength="1"
        maxLength="1"
        inputMode="numeric"
        key={"otp-input-" + i}
        {...rest}
        onChange={(e) => {
          const value = e.target.value;
          if (value.length === 1) {
            const next = i + 1;
            if (next < inputFieldsRefs.length) {
              inputFieldsRefs[next].current.focus();
            }
            singleOnChange(i, value);
          } else {
            singleOnChange(i, value[0]);
          }
        }}
        className={`${style.input} ${selected ? style.focused : ""} ${
          error ? style.error : ""
        }`}
      />
    );
  }
  return res;
};

export const OTPInput = (props) => {
  const { values, error, singleOnChange, hintId, titleId, ...other } = props;
  const inputFieldsRefs = Array(values.length).fill(null).map(useRef);
  const [selected, select] = useState(false);

  useEffect(() => {
    //Should keep inputFields updated with the values
    inputFieldsRefs.forEach((inputField, i) => {
      if (inputField.current && inputField.current.value !== values[i]) {
        //Found a difference, update fields value!
        let value = values[i];
        if (value === undefined || null) {
          value = "";
        }
        inputField.current.value = value;
      }
    });
  }, [inputFieldsRefs, values]);

  return (
    <div className={style.container}>
      <h1 className={style.title}>
        <FormattedMsg id={titleId} />
      </h1>
      <div className={style.inputContainer} data-testid="otp-input-container">
        {generateInputs({
          inputFieldsRefs,
          error,
          singleOnChange,
          select: [selected, select],
          other,
        })}
      </div>
      <p
        style={{ visibility: error ? "visible" : "hidden" }}
        className={style.hint}
        data-testid="otp-input-hint"
      >
        <FormattedMsg id={hintId} />
      </p>
    </div>
  );
};
