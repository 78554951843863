import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import LocaleContext from "../../contexts/locale-context";

export default function FormattedMsg(props) {
  const { id, children, ...rest } = props;
  const { getDefaultMessage } = useContext(LocaleContext);
  const defaultMessage = getDefaultMessage(id);
  return (
    <FormattedMessage id={id} defaultMessage={defaultMessage} {...rest}>
      {children || null}
    </FormattedMessage>
  );
}
