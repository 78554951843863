import { useContext, useState } from "react";
import Button from "./button";
import { InfoIcon } from "./icons/info-icon";
import { RangeSelector } from "./range-selector";
import style from "../styles/datepicker.module.css";
import formStyle from "../styles/form.module.css";
import iconStyle from "../styles/icon.module.css";
import OverlayCard from "./overlay-card";
import { OverlayContext } from "../contexts/overlay-context";
import LocaleContext from "../contexts/locale-context";

// generate the ranges
const getRange = (from, length) => {
  const res = Array(length);
  for (let i = 0; i < length; i++) {
    res[i] = from + i;
  }
  return res;
};

const getRangeOf = (value, start, length) => {
  let from = start,
    to = from + length - 1;
  while (!(value >= from && value <= to)) {
    if (value < from) {
      from -= length;
      to -= length;
    } else if (value > to) {
      from += length;
      to += length;
    }
  }

  return from;
};

const DatePickerModal = (props) => {
  const {
    lastEligibleDate,
    // required to update the whole chain of states
    date: origDate,
    setDate: origSetDate,
    headlines,
    buttonsLabels,
    infoLabel,
  } = props;
  const [date, setCopyDate] = useState(origDate);
  const setDate = (date) => {
    setCopyDate(date);
    origSetDate(date);
  };

  const { formatMessage } = useContext(LocaleContext);
  // indicates what input is expected (year | month | day)
  const [state, setState] = useState(date.day ? "day" : "year");
  const yearsRange = 24;
  const today = new Date();
  // start from the previous range
  // if the year is already selected, display the range in which it's included
  const [startYearRange, setStartYearRange] = useState(
    date.year
      ? getRangeOf(date.year, today.getFullYear() - yearsRange + 1, yearsRange)
      : today.getFullYear() - yearsRange * 2 + 1
  );

  const { showOverlay } = useContext(OverlayContext);

  // months data
  const months = [
    { label: formatMessage("January"), value: 0 },
    { label: formatMessage("February"), value: 1 },
    { label: formatMessage("March"), value: 2 },
    { label: formatMessage("April"), value: 3 },
    { label: formatMessage("May"), value: 4 },
    { label: formatMessage("June"), value: 5 },
    { label: formatMessage("July"), value: 6 },
    { label: formatMessage("August"), value: 7 },
    { label: formatMessage("September"), value: 8 },
    { label: formatMessage("October"), value: 9 },
    { label: formatMessage("November"), value: 10 },
    { label: formatMessage("December"), value: 11 },
  ];

  const isLeap = (year) =>
    (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  const daysInMonth = [
    31,
    isLeap(date.year) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];

  // checkers to disable certain dates
  const disableYear = (v) => v > lastEligibleDate.getFullYear();
  const disableMonth = (v, year = date.year) =>
    disableYear(year) ||
    (year === lastEligibleDate.getFullYear() &&
      v > lastEligibleDate.getMonth());
  const disableDay = (v, month = date.month, year = date.year) =>
    disableMonth(month, year) ||
    (year === lastEligibleDate.getFullYear() &&
      month === lastEligibleDate.getMonth() &&
      v > lastEligibleDate.getDate());

  // selectors and data for year, month and day
  const selectors = {
    year: {
      label: `${startYearRange} - ${startYearRange + yearsRange - 1}`,
      onNext: () => {
        // avoid going after the current year
        if (startYearRange + yearsRange < today.getFullYear()) {
          setStartYearRange((prev) => prev + yearsRange);
        }
      },
      onPrevious: () => {
        setStartYearRange((prev) => prev - yearsRange);
      },
      disableData: disableYear,
      onSelect: (year) => {
        const month =
          date.month && disableMonth(date.month, year) ? null : date.month;
        setDate((prev) => {
          return { ...prev, month, year };
        });
      },
      selected: date.year,
      end: startYearRange + yearsRange - 1 === today.getFullYear(),
      data: getRange(startYearRange, yearsRange),
      className: style.yearsMeasures,
    },
    month: {
      label: date.year,
      onNext: () => {
        // avoid going after the last eligible year
        if (date.year + 1 <= lastEligibleDate.getFullYear()) {
          // avoid keeping selected the month if it's not eligible
          const month = disableMonth(date.month, date.year + 1)
            ? null
            : date.month;
          setDate((prev) => ({ ...prev, year: prev.year + 1, month }));
        }
      },
      onPrevious: () => {
        setDate((prev) => ({ ...prev, year: prev.year - 1 }));
      },
      disableData: disableMonth,
      onSelect: (month) => {
        const day = date.day && disableDay(date.day, month) ? null : date.day;
        setDate((prev) => ({ ...prev, month, day }));
      },
      selected: date.month,
      end: disableYear(date.year + 1),
      data: months,
      className: style.monthsMeasures,
    },
    day: {
      label: `${months[date.month]?.label} - ${date.year}`,
      onNext: () => {
        // avoid going after the last eligible month
        if (!disableMonth(date.month + 1)) {
          // change the year, if necessary
          let { month, year } = date;
          if (month === 11) {
            month = -1;
            year++;
          }
          // avoid keeping selected the month if it's not eligible
          const day = disableDay(date.day, month + 1, year) ? null : date.day;
          setDate({ year, month: month + 1, day });
        }
      },
      onPrevious: () => {
        let month = date.month - 1,
          { year } = date;
        if (month === -1) {
          month = 11;
          year--;
        }
        setDate((prev) => ({ ...prev, year, month }));
      },
      disableData: disableDay,
      onSelect: (day) => setDate((prev) => ({ ...prev, day })),
      selected: date.day,
      end: disableMonth(date.month + 1),
      data: getRange(1, daysInMonth[date.month]),
      isNumeric: true,
      className: style.daysMeasures,
    },
  };
  return (
    <OverlayCard
      className={`${style.modal} ${style.close}`}
      title={<p className={style.title}>{headlines[state]}</p>}
      alignTitleToX={true}
      data-testid="datepicker-modal"
    >
      <div className={style.content}>
        <RangeSelector {...selectors[state]} />
        <div className={`${style.buttons}`}>
          {state !== "year" && (
            <Button
              data-testid="back-button"
              data-link-id="back_button"
              variant="tertiary"
              onClick={(e) => {
                if (state === "month") setState("year");
                else if (state === "day") setState("month");
                e.stopPropagation();
              }}
            >
              {buttonsLabels.back[state]}
            </Button>
          )}
          <Button
            data-testid="select-button"
            data-link-id="Select_button"
            className={style.mainButton}
            variant={date[state] !== null ? "primary" : "disabled"}
            onClick={(e) => {
              if (state === "day") {
                props.onChange(new Date(date.year, date.month, date.day));
                props.onClose();
                props.onFinish();
                showOverlay();
              } else if (state === "month") setState("day");
              else if (state === "year") setState("month");
              e.stopPropagation();
            }}
          >
            {buttonsLabels.next[state]}
          </Button>
        </div>
        <div className={`${style.info} ${formStyle["info-row"]}`}>
          <InfoIcon className={`${formStyle.infoIcon} ${iconStyle.icon}`} />
          <div className={`${formStyle.info}`}>
            <p>{infoLabel}</p>
          </div>
        </div>
      </div>
    </OverlayCard>
  );
};

export default DatePickerModal;
