import { useContext, useRef } from "react";
import PlacesHelper from "./places-helper";
import Logger from "../logger/logger";
import PlacesContext from "./places-context";
import Divider from "../divider";

import listStyle from "../../styles/dropdown-list.module.css";
import style from "../../styles/address.module.css";
import FormattedMsg from "../../locale/components/formatted-msg";

export default function Prediction({
  className,
  values,
  search,
  country,
  onClick,
  "data-testid": dataTestId,
}) {
  const ref = useRef(null);
  const placesContext = useContext(PlacesContext);
  return Array.isArray(values) ? (
    <div
      className={listStyle.list + (className ? ` ${className}` : "")}
      ref={ref}
      data-testid={dataTestId}
    >
      <div
        className={`${listStyle.listHeader} ${listStyle.listElement} ${style.predictionsTitle}`}
      >
        <FormattedMsg
          id="search_results_info_copy"
          values={{ search: search }}
        />
      </div>
      {values.map((value) => {
        const description = PlacesHelper.getDescription(placesContext, value);
        const key = JSON.stringify(value);
        return [
          <div
            className={listStyle.listElement}
            key={key}
            data-testid="prediction-item"
            onPointerDown={(e) => {
              //disable the default to have the click before the blur
              e?.preventDefault?.();
            }}
            onClick={(event) => {
              if (typeof onClick === "function") {
                Logger.d("Clicked on place", value);
                PlacesHelper.getPlaceDetails(
                  placesContext,
                  ref.current,
                  value,
                  country,
                  (result) => {
                    Logger.d("Retrieved details for place", result);
                    onClick(result);
                  }
                );
              }
              event?.preventDefault?.();
              event?.stopPropagation?.();
            }}
          >
            {description}
          </div>,
          <Divider key={key + "-divider"} className={listStyle.divider} />,
        ];
      })}
    </div>
  ) : null;
}
