// Suspense integrations like Relay implement
// a contract like this to integrate with React.
// Real implementations can be significantly more complex.
// Don't copy-paste this into your project!

import ConfigHelper from "../../config/config-helper";
import Logger from "../logger/logger";

const ERROR = "error";
const PENDING = "pending";
const SUCCESS = "success";

/**
 *
 * @param {function} fun should be a function that has to be run and return a promise
 * @returns
 */
export default function wrapPromise(promise, start) {
  let status = null;
  let result;
  let suspender;
  let finishedAt = null;
  function wrap(promise, start) {
    suspender = new Promise((resolve, reject) => {
      if (start) {
        status = PENDING;
        try {
          promise
            .then(
              (r) => {
                finishedAt = Date.now();
                status = SUCCESS;
                result = r;
                try {
                  resolve(result);
                } catch (error) {
                  status = ERROR;
                  result = error;
                  resolve(result);
                }
              },
              (e) => {
                finishedAt = Date.now();
                status = ERROR;
                result = e;
                resolve(result);
              }
            )
            .catch((error) => {
              finishedAt = Date.now();
              status = ERROR;
              result = error;
              resolve(result);
            });
        } catch (error) {
          finishedAt = Date.now();
          status = ERROR;
          result = error;
          resolve(result);
        }
      } else {
        status = null;
      }
    });
  }
  wrap(promise, start);
  function isPending() {
    return status === PENDING;
  }
  function hasError() {
    return status === ERROR;
  }
  function hasSuccess() {
    return status === SUCCESS;
  }
  function isStarted() {
    return status !== null;
  }
  return {
    read() {
      // when the call returns a result, save the timestamp
      Logger.d("Finished at: ", finishedAt);
      this.finishedAt = finishedAt;
      if (isPending()) {
        //throw the suspender to trigger the Suspence
        throw suspender;
      } else if (hasError()) {
        //Returns result and do not throw because we want to handle the error
        return result;
      } else if (hasSuccess()) {
        //returns succesful result
        return result;
      } else if (!isStarted()) {
        return null;
      } else throw new Error("Read, invalid status: " + status);
    },
    isPending() {
      return isPending();
    },
    hasError() {
      return hasError();
    },
    hasSuccess() {
      return hasSuccess();
    },
    isStarted() {
      return isStarted();
    },
    getStatus() {
      switch (status) {
        case PENDING:
          return PENDING;
        case ERROR:
          return ERROR;
        case SUCCESS:
          return SUCCESS;
        case null:
          return null;
        default:
          throw new Error("getStatus, invalid status: " + status);
      }
    },
    rewrap(promise, start) {
      if (ConfigHelper.isDevelopmentMode()) {
        Logger.v("Rewrapping promise");
      }
      return wrap(promise, start);
    },
  };
}
