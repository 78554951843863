import OverlayCard from "./overlay-card";
import style from "../styles/info-overlay.module.css";

export const InfoOverlay = (props) => {
  const { title, text } = props;
  return (
    <OverlayCard
      className={style.overlayCard}
      data-testid="info-overlay-container"
    >
      <div className={style.content}>
        <h1 className={style.title} data-testid="info-overlay-title">
          {title}
        </h1>
        <p className={style.info} data-testid="info-overlay-text">
          {text}
        </p>
      </div>
    </OverlayCard>
  );
};
