import React, { useState } from "react";
import FormattedMsg from "../locale/components/formatted-msg";
import style from "../styles/accordion.module.css";
import Card from "./card";
import Chevron from "./chevron";
import Divider from "./divider";

export const Accordion = ({
  "data-testid": dataTestId,
  headlineId,
  headline,
  children,
  headIndicator,
  opened,
  ...rest
}) => {
  const [showContent, setShowContent] = useState(opened || false);
  const title =
    headline || (headlineId ? <FormattedMsg id={headlineId} /> : null);

  return (
    <Card
      className={`${style.container}`}
      data-testid={dataTestId ?? "accordion-container"}
    >
      <div
        className={style.headContainer}
        onClick={() => setShowContent((prev) => !prev)}
        data-testid="accordion-head"
      >
        {title ? <h2 className={style.head}>{title}</h2> : null}
        <div className={style.headIndicatorsContainer}>
          {headIndicator && (
            <div className={style.headIndicator}>{headIndicator}</div>
          )}
          <Chevron direction={showContent ? "up" : "down"} />
        </div>
      </div>
      {showContent && (
        <div className={style.content} data-testid="accordion-content">
          <Divider className={style.divider} />
          {children}
        </div>
      )}
    </Card>
  );
};
