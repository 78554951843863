import React from "react";

import RedIcon from "../../images/pencil-red.svg";
import GreyIcon from "../../images/pencil-grey.svg";
import WhiteIcon from "../../images/pencil-white.svg";
import ImageVariants from "../image-variants";

const PencilIcon = (props) => {
  const variants = {
    white: WhiteIcon,
    grey: GreyIcon,
    red: RedIcon,
    default: RedIcon,
  };
  return <ImageVariants variants={variants} {...props} />;
};

export default PencilIcon;
