import React from "react";
import ImageVariants from "../image-variants";
import { ReactComponent as RedCheckMark } from "../../images/red-check-mark.svg";
import { ReactComponent as WhiteCheckMark } from "../../images/white-check-mark.svg";
import { ReactComponent as GreenCheckMark } from "../../images/green-check-mark.svg";
import { ReactComponent as BlueCheckMark } from "../../images/blue-check-mark.svg";
import { ReactComponent as CircledGreenCheckMark } from "../../images/green-check-mark.svg";
import { ReactComponent as CircledBlueCheckMark } from "../../images/blue-check-mark.svg";
import { ReactComponent as CircledRedCheckMark } from "../../images/circled-red-check-mark.svg";
import { ReactComponent as CircledWhiteCheckMark } from "../../images/circled-white-check-mark.svg";
import style from "../../styles/icon.module.css";

export const TickIcon = ({ circled, ...props }) => {
  const { active, background, ...others } = props;

  const addBackground = (image) =>
    background ? (
      <div
        className={`${style.background} ${active ? style.active : ""} ${
          props.className || ""
        }`}
      >
        {image}
      </div>
    ) : (
      image
    );

  const variants = circled
    ? {
        red: CircledRedCheckMark,
        white: CircledWhiteCheckMark,
        green: CircledGreenCheckMark,
        blue: CircledBlueCheckMark,
        default: RedCheckMark,
      }
    : {
        red: RedCheckMark,
        white: WhiteCheckMark,
        green: GreenCheckMark,
        blue: BlueCheckMark,
        default: RedCheckMark,
      };
  return addBackground(
    <ImageVariants
      className={`${style.icon} ${(background && props.className) ?? ""}`}
      variants={variants}
      {...others}
    />
  );
};
