import Helpers from "../helpers";
import Logger from "../logger/logger";
import GoogleService from "./google-service";
import LocationIQService from "./locationiq-service";

const GOOGLE = "google";
const LOCIQ = "locationiq";

export default class PlacesHelper {
  static getProvider(placesContext) {
    if (!placesContext) {
      throw new Error("PlacesContext must be passed");
    } else {
      const provider = placesContext.provider?.toLowerCase?.();
      if (provider !== GOOGLE && provider !== LOCIQ) {
        throw new Error("Invalid placesContext, no provider");
      } else {
        return provider;
      }
    }
  }

  static getApiService(placesContext) {
    const provider = this.getProvider(placesContext);
    let service = placesContext.apiService?.[provider];
    if (!service) {
      switch (provider) {
        case GOOGLE:
          service = new GoogleService(placesContext);
          break;
        case LOCIQ:
          service = new LocationIQService(placesContext);
          break;
        default:
      }
      if (!placesContext.apiService) {
        placesContext.apiService = {};
      }
      placesContext.apiService[provider] = service;
    }
    return service;
  }

  static getAutocompletePlacesPredictions(
    placesContext,
    country,
    city,
    postcode,
    street,
    houseNumber,
    callback
  ) {
    if (city || postcode || street) {
      const service = this.getApiService(placesContext);
      const provider = this.getProvider(placesContext);
      const myCountry = Helpers.getCountry(country);
      if (!myCountry) {
        throw new Error(
          "Invalid country when preparing autocomplete places prediction"
        );
      }
      let cb = callback;
      let options;
      const input = [houseNumber, street, city, postcode].filter((a) => !!a);
      switch (provider) {
        case GOOGLE:
          options = {
            input: input.map((a) => encodeURIComponent(a)).join(", "),
            componentRestrictions: {
              country: myCountry.name,
            },
            language: myCountry.lang,
            types: street ? ["address"] : ["(regions)"],
          };
          break;
        case LOCIQ:
          options = {
            q: input,
            countrycodes: myCountry.name,
            "accept-language": myCountry.lang,
            tag: houseNumber ? "building" : street ? "highway" : "place",
            dedupe: 1,
            limit: 20,
          };
          if (houseNumber && street) {
            //filter out results in other roads (if no errors)
            cb = (results) => {
              const res =
                results instanceof Array
                  ? results.filter((res) => {
                      return res.address.road === street;
                    })
                  : results;
              callback(res);
            };
          }
          break;
        default:
      }
      Logger.d("Calling getPlacePredictions...", options);
      service.getPlacePredictions(
        options,
        (...args) => {
          Logger.d("Autocomplete places received", ...args);
          cb(...args);
        },
        placesContext.networkContext
      );
    }
  }

  static getAddressPredictions(
    placesContext,
    country,
    city,
    postcode,
    street,
    houseNumber,
    callback
  ) {
    return this.getAutocompletePlacesPredictions(
      placesContext,
      country,
      city,
      postcode,
      street,
      houseNumber,
      callback
    );
  }

  static getPlaceDetails(
    placesContext,
    container,
    originalPlace,
    country,
    callback
  ) {
    const service = this.getApiService(placesContext);
    const provider = this.getProvider(placesContext);
    const myCountry = Helpers.getCountry(country);
    if (!myCountry) {
      throw new Error(
        "Invalid country when preparing autocomplete places prediction"
      );
    }
    let options;
    switch (provider) {
      case GOOGLE:
        options = {
          placeId: originalPlace.place_id,
          language: myCountry.lang,
          fields: ["address_components"],
        };
        break;
      case LOCIQ:
        options = {
          originalPlace: originalPlace,
        };
        break;
      default:
    }
    return service.getDetails(
      options,
      callback,
      container,
      placesContext.networkContext
    );
  }

  static getPlaceAddressComponentsValue(place, component, short = false) {
    const found = place?.address_components?.find((p) =>
      p.types.includes(component)
    );
    Logger.d("Place address component search: ", place, component, found);
    return found?.[short ? "short_name" : "long_name"];
  }

  static getPlacePostcode(placesContext, place) {
    const provider = this.getProvider(placesContext);
    switch (provider) {
      case GOOGLE:
        return this.getPlaceAddressComponentsValue(place, "postal_code");
      case LOCIQ:
        // if the value lenght is < 3, the postcode field is not present,
        // but the value is saved in the address name
        return (
          place.address.postcode ||
          (place?.type === "postcode" ? place.address.name : undefined)
        );
      default:
    }
  }

  static getPlaceCity(placesContext, place) {
    const provider = this.getProvider(placesContext);
    switch (provider) {
      case GOOGLE:
        return (
          this.getPlaceAddressComponentsValue(place, "locality") ||
          this.getPlaceAddressComponentsValue(
            place,
            "administrative_area_level_2"
          )
        );
      case LOCIQ:
        return (
          place.address.city ||
          place.address.town ||
          place.address.suburb ||
          (["city", "town", "village", "locality"].includes(place.type)
            ? place.address.name
            : undefined)
        );
      default:
    }
  }

  static getPlaceStreet(placesContext, place) {
    const provider = this.getProvider(placesContext);
    switch (provider) {
      case GOOGLE:
        return this.getPlaceAddressComponentsValue(place, "road");
      case LOCIQ:
        return (
          place.address.road ||
          (place.class === "highway" ? place.address.name : undefined)
        );
      default:
    }
  }

  static getPlaceStreetNumber(placesContext, place) {
    const provider = this.getProvider(placesContext);
    switch (provider) {
      case GOOGLE:
        return this.getPlaceAddressComponentsValue(place, "street_number");
      case LOCIQ:
        return place.address.house_number;
      default:
    }
  }

  static getDescription(placesContext, place) {
    const provider = this.getProvider(placesContext);
    switch (provider) {
      case GOOGLE:
        return place.description;
      case LOCIQ:
        return place.display_name;
      default:
    }
  }

  static hasPendingCalls(placesContext) {
    return !!this.getApiService(placesContext)?.hasPendingCalls?.(
      placesContext?.networkContext
    );
  }
}
