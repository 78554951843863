import { useState, useEffect, useContext } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import useNetwork from "../../../components/network/use-network";
import { OTP } from "../../../components/OTP";
import ConfigContext from "../../../contexts/config-context";
import {
  generateOTPData,
  verifyOTPData,
} from "../../../components/network/email";
import { ErrorPage } from "./errorPage";
import { NewSubscriptionPage } from "./newSubscriptionPage";
import { CallHandler } from "../../../components/call-handler";
import style from "../../../styles/OTP.module.css";
import FormattedMsg from "../../../locale/components/formatted-msg";

const generateOTPTag = "generateOTP";
const verifyOTPTag = "verifyOTP";

export const OTPVerification = () => {
  const { config } = useContext(ConfigContext);
  const { startNetworkCall, checkNetworkCall, removeNetworkCall } =
    useNetwork();
  const [, setTick] = useState();
  const {
    setVerifyOTPResult,
    onStartVerifyOTPcall,
    setGenerateOTPResult,
    getGenerateOTPData,
    onStartGenerateOTPcall,
    getVerifyOTPData,
    getEmailData,
    pages,
  } = useOutletContext();
  const email = getEmailData();
  const generateOTPcall = checkNetworkCall(generateOTPTag, true, false);
  const navigate = useNavigate();

  const generateOTP = () => {
    //force update to show loading
    onStartGenerateOTPcall(email);
    //start Call
    return startNetworkCall(
      generateOTPTag,
      () => generateOTPData(config, email),
      true,
      false
    );
  };

  const generateOTPCallback = (result) => {
    setGenerateOTPResult(result);
  };

  const verifyOTP = (otp) => {
    //force update to show loading
    onStartVerifyOTPcall(otp);
    //start Call
    return startNetworkCall(
      verifyOTPTag,
      () => verifyOTPData(config, otp),
      false,
      false,
      verifyOTPCallback
    );
  };

  const verifyOTPCallback = (result) => {
    if (setVerifyOTPResult(result)) {
      if (!(result instanceof Error)) {
        navigate(pages.NewSubscription.CreatePassword.full, {
          replace: true,
        });
      } else setTick(Date.now());
    }
  };

  const OTPIds = {
    titleId: "E-mail_verification_page_title",
    descriptionId: "E-mail_verification_page_copy",
    hintId: "E-mail_verification_input_hint",
    sendId: "Verify_OTP_button",
    retryId: "Send_new_OTP_button",
    contact: email,
    changeContactId: "Change_email_address_button",
  };

  const generateOTPSavedData = getGenerateOTPData();
  const verifyOTPSavedData = getVerifyOTPData();
  const generateOTPcooldown =
    Math.max(
      generateOTPSavedData?.cooldown || 0,
      verifyOTPSavedData?.cooldown || 0
    ) || 0;

  const now = Date.now();
  const timeToWaitForGenerateOTPCall = generateOTPcooldown
    ? Math.max(Math.round((generateOTPcooldown - now) / 1000), 0)
    : 0;
  useEffect(() => {
    if (timeToWaitForGenerateOTPCall > 0) {
      //set an interval
      const interval = setInterval(() => {
        try {
          setTick(Date.now());
        } catch (error) {}
      }, 500);
      return () => {
        try {
          clearInterval(interval);
        } catch (error) {}
      };
    }
  }, [timeToWaitForGenerateOTPCall]);

  if (generateOTPcall?.hasError()) {
    const error = generateOTPcall?.read();
    if (error.status === 423) {
      const errorChildren =
        timeToWaitForGenerateOTPCall > 0 ? (
          <p className={`${style.link} ${style.linkTooManyOtps} disabled`}>
            <FormattedMsg
              id="Resend_OTP_timer_copy"
              values={{
                nn: (
                  <span className={style.secondsLeft}>
                    {timeToWaitForGenerateOTPCall}
                  </span>
                ),
              }}
            />
          </p>
        ) : null;

      const errorIDs = {
        titleId: "Too_many_OTP_attempts_page_title",
        descriptionId: "Too_many_OTP_attempts_page_copy",
        mainButtonId: "Send_new_OTP",
        secondaryActionId: "Quit_setup_button",
        email,
        hidePrimaryButton: timeToWaitForGenerateOTPCall > 0,
        primaryAction: () => {
          // setSeconds(coolDownSeconds);
          generateOTP(email);
        },
        heroImage: "tooManyAttemptsOTP",
      };
      return <ErrorPage {...errorIDs}>{errorChildren}</ErrorPage>;
    } else
      return (
        <ErrorPage
          status={error.status}
          heroImage="failedOTPGeneration"
          primaryAction={() => {
            generateOTP(email);
          }}
        />
      );
  }

  const attemptsLeft = verifyOTPSavedData?.attemptsLeft;
  const verifyOTPcall = checkNetworkCall(verifyOTPTag, false, false);

  if (verifyOTPcall?.hasError() && !generateOTPcall.isPending()) {
    const error = verifyOTPcall.read();
    if (error.status === 423) {
      //Error 423
      const errorChildren =
        timeToWaitForGenerateOTPCall > 0 ? (
          <p className={`${style.link} ${style.linkTooManyOtps} disabled`}>
            <FormattedMsg
              id="Resend_OTP_timer_copy"
              values={{
                nn: (
                  <span className={style.secondsLeft}>
                    {timeToWaitForGenerateOTPCall}
                  </span>
                ),
              }}
            />
          </p>
        ) : null;

      const errorIDs = {
        titleId: "Too_many_OTP_attempts_page_title",
        descriptionId: "Too_many_OTP_attempts_page_copy",
        mainButtonId: "Send_new_OTP",
        secondaryActionId: "Quit_setup_button",
        email,
        hidePrimaryButton: timeToWaitForGenerateOTPCall > 0,
        primaryAction: () => {
          // setSeconds(coolDownSeconds);
          removeNetworkCall(generateOTPTag, true);
          generateOTP(email);
        },
        heroImage: "tooManyAttemptsOTP",
      };
      return <ErrorPage {...errorIDs}>{errorChildren}</ErrorPage>;
    } else if (error.status !== 409) {
      //Any other error (besides 409)
      return (
        <ErrorPage
          status={error.status}
          primaryAction={() => {
            //reset call
            removeNetworkCall(verifyOTPTag, false);
            //refresh
            setTick(Date.now());
          }}
        />
      );
    }
  }

  return (
    <NewSubscriptionPage heroTitle={{ textId: "Contact_details_hero_title" }}>
      {generateOTPcall?.isPending() ? (
        <CallHandler call={generateOTPcall} onFinish={generateOTPCallback} />
      ) : verifyOTPcall?.isPending() ? (
        <CallHandler call={verifyOTPcall} onFinish={verifyOTPCallback} />
      ) : (
        <OTP
          error={{
            error: verifyOTPSavedData?.showBanner,
            attemptsLeft: attemptsLeft,
          }}
          {...OTPIds}
          onSend={(otp) => {
            verifyOTP(otp);
          }}
          onNewOTP={() => {
            generateOTP(email);
          }}
          onChangeContact={() => {
            navigate(pages.NewSubscription.ContactDetails.full, {
              replace: true,
            });
          }}
          changeContactLink={pages.NewSubscription.ContactDetails.full}
          coolDown={{ secondsLeft: timeToWaitForGenerateOTPCall }}
        />
      )}
    </NewSubscriptionPage>
  );
};
