import { lazy } from "react";
import { Route, Navigate } from "react-router";
import { ErrorPage } from "../pages/jasmine/newSubscription/errorPage";
import ErrorPageTest from "../pages/jasmine/testPages/test-error-page";
import ResultPageTest from "../pages/jasmine/testPages/test-result-page";
import TestPages from "../pages/jasmine/testPages/testPages";
import ContactDetailsContent from "../pages/jasmine/newSubscription/contactDetails";
import { CreatePassword } from "../pages/common/newSubscription/createPassword";
import HomeAddress from "../pages/common/newSubscription/homeAddress";
import NewSubscription from "../pages/common/newSubscription/newSubscription";
import { OTPVerification } from "../pages/common/newSubscription/OTPVerification";
import PaymentDetails from "../pages/jasmine/newSubscription/paymentDetails";
import PersonalInformation from "../pages/common/newSubscription/personalInformation";
import { ResultPage } from "../pages/jasmine/newSubscription/result";
import SelectPlan from "../pages/jasmine/newSubscription/selectPlan";
import JasminePages from "./jasmine-pages";
import getEntryPointRoutes from "./entry-points";
import OnDeviceTestPage from "../pages/jasmine/on-device-tests";
import GetDetailsPage from "../pages/jasmine/get-details";
import SubmitDetailsPage from "../pages/jasmine/submit-details";
import { TestSubscriptionCardPage } from "../pages/jasmine/testPages/test-subscription-card";
const Main = lazy(() => import("../pages/jasmine/main"));

export default function getJasmineRoutes(testPagesEnabled) {
  return (
    <>
      {
        /* Jasmine entry points */
        getEntryPointRoutes(JasminePages.EntryPoints)
      }
      {testPagesEnabled && (
        <>
          <Route
            path={JasminePages.EntryPoints.TestPages.redirectTo}
            element={<TestPages pages={JasminePages} />}
          />
          <Route
            path={JasminePages.TestPages.ErrorPageTest}
            element={<ErrorPageTest />}
          />
          <Route
            path={JasminePages.TestPages.ResultPageTest}
            element={<ResultPageTest />}
          />
          <Route
            path={JasminePages.TestPages.TestSubscriptionCard}
            element={<TestSubscriptionCardPage />}
          />
        </>
      )}
      <Route path={JasminePages.Main} element={<Main pages={JasminePages} />} />
      <Route
        path={JasminePages.PendingActivation}
        element={
          <Navigate
            to={JasminePages.NewSubscription.Result.full}
            state={{ status: "pending" }}
          />
        }
      />
      <Route
        path={JasminePages.NewSubscription.path}
        element={<NewSubscription pages={JasminePages} />}
      >
        <Route
          path={JasminePages.NewSubscription.SelectPlan.relative}
          element={<SelectPlan />}
        />
        <Route
          path={JasminePages.NewSubscription.PersonalInformation.relative}
          element={<PersonalInformation />}
        />
        <Route
          path={JasminePages.NewSubscription.HomeAddress.relative}
          element={<HomeAddress />}
        />
        <Route
          path={JasminePages.NewSubscription.ContactDetails.relative}
          element={<ContactDetailsContent />}
        />
        <Route
          path={JasminePages.NewSubscription.OTPVerification.relative}
          element={<OTPVerification />}
        />
        <Route
          path={JasminePages.NewSubscription.CreatePassword.relative}
          element={<CreatePassword />}
        />
        <Route
          path={JasminePages.NewSubscription.PaymentDetails.relative}
          element={<PaymentDetails />}
        />
        <Route
          path={JasminePages.NewSubscription.Result.relative}
          element={<ResultPage pages={JasminePages} />}
        />
        <Route
          path={JasminePages.NewSubscription.ErrorPage.relative}
          element={<ErrorPage />}
        />
      </Route>
      <Route
        path={JasminePages.GetDeviceDetails}
        element={<GetDetailsPage />}
      />
      <Route
        path={JasminePages.SubmitDeviceDetails}
        element={<SubmitDetailsPage />}
      />
      <Route path={JasminePages.OnDeviceTest} element={<OnDeviceTestPage />} />
    </>
  );
}
