import React, { useContext } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import Button from "../../../components/button";
import quotationsData from "../../../components/network/quotations";
import useNetwork from "../../../components/network/use-network";
import { SmallSubscriptionCard } from "../../../components/subscription-card";
import ToggleSwitch from "../../../components/toggle-switch";
import ConfigContext from "../../../contexts/config-context";
import FormattedMsg from "../../../locale/components/formatted-msg";
import { ErrorPage } from "./errorPage";
import { NewSubscriptionPage } from "../../common/newSubscription/newSubscriptionPage";
import { CallHandler } from "../../../components/call-handler";
import Logger from "../../../components/logger/logger";

import style from "../../../styles/pages/jasmine/selectPlan.module.css";
import NewSubscriptionHero from "../../../components/new-subscription-hero";

const generateCards = (plans, kind, selectedCard, onSelect) => {
  let res = [];
  for (const i in plans) {
    res.push(
      <SmallSubscriptionCard
        plan={plans[i]}
        key={i}
        onClick={() => onSelect(plans[i])}
        data-testid={"subscription-card-" + kind.replace(/\s/g, "-") + i}
        showPib={true}
        selected={selectedCard?.pricePlanId === plans[i].pricePlanId}
      />
    );
  }
  return res;
};

const quotationsTag = "quotations";

const SelectPlan = (props) => {
  const navigate = useNavigate();
  const {
    setData,
    setQuotationsResult,
    getQuotationsData,
    formData: { selectPlan: data },
    pages,
  } = useOutletContext();
  const { startNetworkCall, checkNetworkCall, removeNetworkCall } =
    useNetwork();

  const { config } = useContext(ConfigContext);

  const [, setTick] = useState();
  const [selectedCard, selectCard] = useState(data?.plan);
  const pathState = useLocation()?.state;
  const selectLink = pages.NewSubscription.PersonalInformation.full;

  const onSelect = (plan) => {
    selectCard(plan);
  };
  const getQuotationsCall = checkNetworkCall(quotationsTag, false, false);

  const plans = getQuotationsData()?.plans;
  const error = getQuotationsData()?.error;

  // Extract the labels to use
  Logger.d(plans, error);
  let options = [
    ...new Set(!error ? plans?.map((p) => p.durationTag) : []),
  ].map((tag) => {
    const label = plans.find((p) => p.durationTag === tag).durationLabel;
    return tag ? { label, state: tag, tag } : null;
  });

  const [selected, select] = useState(
    data?.plan?.durationTag ?? options[0]?.durationTag
  );
  const handleToggleSwitch = (newState) => {
    select(newState.state);
    selectCard(null);
  };

  // if no kind of contract is selected,
  // select the kind of the first one
  // or the one of the previously selected
  if (plans && !selected) {
    select(data?.plan?.durationTag ?? plans[0].durationTag);
  }

  const getQuotations = () => {
    //start Call
    setTick(Date.now());
    return startNetworkCall(
      quotationsTag,
      () => quotationsData(config),
      false,
      false
    );
  };

  const callback = (result) => {
    Logger.d(result, result instanceof Error);
    setQuotationsResult(result);
  };

  // call the API if the data hasn't been retrieved yet
  // (and no other call is going on)
  if (!plans && !error && !getQuotationsCall.isPending()) {
    getQuotations();
  }

  if (error) {
    const error = getQuotationsCall.read();
    const errorIDs = {
      primaryAction: () => {
        //reset call
        removeNetworkCall(quotationsTag, false);
        // call the API again
        getQuotations();
        //refresh
        setTick(Date.now());
      },
    };
    return <ErrorPage status={error?.status} {...errorIDs} />;
  }

  return (
    <NewSubscriptionPage applyMeasures={true} className={style.page}>
      {getQuotationsCall?.isPending() ? (
        <CallHandler call={getQuotationsCall} onFinish={callback} />
      ) : (
        <>
          <div className={style.content}>
            <NewSubscriptionHero />
            {!options.includes(null) && (
              <ToggleSwitch
                options={options}
                selected={selected}
                onStateChange={handleToggleSwitch}
                data-testid="switch-plans"
              />
            )}
            <div
              className={
                plans?.some((p) => p.hasPromotion)
                  ? style.verticalCardsContainer
                  : style.cardsContainer
              }
            >
              {generateCards(
                options.includes(null)
                  ? plans
                  : plans?.filter((p) => p.durationTag === selected),
                selected,
                selectedCard,
                onSelect
              )}
            </div>
          </div>
          <div className={style.buttonsContainer}>
            <Button
              variant="outline"
              data-link-id="GoBackButton"
              data-testid="back-button"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FormattedMsg id="Secondary_action" />
            </Button>
            <Button
              variant={selectedCard ? "primary" : "disabled"}
              data-testid="select-plan-button"
              eventMeta={{ pricePlanId: selectedCard?.pricePlanId }}
              onClick={() => {
                // if it's the beginning of the flow, go to the next page,
                // or go back to the previous page otherwise
                const firstTime = !!!data.plan;
                setData("plan", selectedCard, false);
                setData("submittable", true, false);
                // go to the following page if no redirect page was passed
                navigate(
                  firstTime || !pathState?.redirectTo
                    ? selectLink
                    : pathState?.redirectTo
                );
              }}
            >
              <FormattedMsg id="Confirm_selection_button" />
            </Button>
          </div>
        </>
      )}
    </NewSubscriptionPage>
  );
};

export default SelectPlan;
