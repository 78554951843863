import React from "react";
import text from "../styles/text.module.css";
import style from "../styles/footer.module.css";
import FormattedMsg from "../locale/components/formatted-msg";
import { useContext } from "react";
import { OverlayContext } from "../contexts/overlay-context";
import Button from "./button";

const Footer = (props) => {
  const {
    className,
    pageVariant,
    token,
    "data-testid": dataTestId,
    ...others
  } = props;

  const { showOverlay } = useContext(OverlayContext);

  return (
    <div
      className={`${style.container} ${pageVariant ? style[pageVariant] : ""} ${
        className || ""
      }`}
      data-testid={dataTestId || "footer"}
      {...others}
    >
      <div className={style.elements}>
        <Button
          href="https://www.vodafone.de/unternehmen/soziale-verantwortung/datenschutz-privatsphaere.html"
          onClick={() => showOverlay("privacy-policy")}
          className={text.plainLink}
          data-link-id="footer-privacy"
          data-testid="footer-privacy"
          variant="link"
        >
          <FormattedMsg id="Footer_privacy_policy" />
        </Button>
        <p data-testid="footerCopyright">
          <FormattedMsg id="Footer_trademark" />
        </p>
      </div>
    </div>
  );
};
export default Footer;
