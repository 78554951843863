import React from "react";
import Button from "../../components/button";
import Footer from "../../components/footer";
import { useLocation, useNavigate } from "react-router-dom";
import JasminePages from "../../routes/jasmine-pages";
import NavBar from "../../components/navbar";
import FormattedMsg from "../../locale/components/formatted-msg";
import Helpers from "../../components/helpers";
import PromoBanner from "../../components/promobanner";
import promoImageWebp from "../../images/jasmine/Jasmine-welcome-page-banner-German-240213.webp";
import promoImageWebp2x from "../../images/jasmine/Jasmine-welcome-page-banner-German-240213@2x.webp";
import promoImageWebp3x from "../../images/jasmine/Jasmine-welcome-page-banner-German-240213@3x.webp";
import promoImagePng from "../../images/jasmine/Jasmine-welcome-page-banner-German-240213.webp";
import promoImageOfferPng from "../../images/jasmine/Jasmine-welcome-page-banner-German-V7-240423.png";
import style from "../../styles/pages/jasmine/details.module.css";
import { useContext } from "react";
import ConfigContext from "../../contexts/config-context";

export default function GetDetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const test = Helpers.getUrlParameter("test", location);
  const { config } = useContext(ConfigContext);
  return (
    <>
      <NavBar />
      <PromoBanner
        className={style.promoBanner}
        srcSetType={"image/webp"}
        srcSet={
          !config.offers &&
          `${promoImageWebp} 540w, ${promoImageWebp2x} 1080w, ${promoImageWebp3x} 1620w`
        }
        defaultImage={config.offers ? promoImageOfferPng : promoImagePng}
        alt="promo image"
      />
      <div
        className={`${style.container} ${style.pageContainer}`}
        data-testid={"get-details-page-container"}
      >
        <div className={style.pageContent}>
          <p>
            <FormattedMsg id="Landing_page_main_copy" />
          </p>
          <Button
            className={style.mainButton}
            data-testid="next-button"
            onClick={() => {
              navigate(
                JasminePages.SubmitDeviceDetails + (test ? "?test=true" : ""),
                { replace: true }
              );
            }}
          >
            <FormattedMsg id="Next_CTA" />
          </Button>
        </div>
      </div>
      <Footer />
    </>
  );
}
