/**
 * Return a validator
 */

import ConfigHelper from "../../config/config-helper";
import Logger from "../logger/logger";

export default function createValidator(
  validationFunction,
  submittableFunction,
  data = {}
) {
  return {
    // included to permit extensibility
    validationFunction,
    submittableFunction,
    // extra data required for validation
    data,
    getError: (value, focused, minLength, maxLength, required) => {
      const result =
        validationFunction?.(
          value,
          focused,
          minLength,
          maxLength,
          required,
          data
        ) || false;
      if (ConfigHelper.isValidatorsLogsEnabled()) {
        Logger.v(
          `Validator getError called with value="${value}" focused=${focused} minLength=${minLength} maxLength=${maxLength} required=${required}, result=${result}`
        );
      }
      return result;
    },
    isSubmittable: (value, minLength, maxLength, required) => {
      const result =
        submittableFunction?.(value, minLength, maxLength, required, data) ||
        false;
      if (ConfigHelper.isValidatorsLogsEnabled()) {
        Logger.v(
          `Validator isSubmittable called with value="${value}" minLength=${minLength} maxLength=${maxLength} required=${required}, result=${result}`
        );
      }
      return result;
    },
  };
}
