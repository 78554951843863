import React, { forwardRef, useContext, useState } from "react";
import CalendarIcon from "./icons/calendar-icon";
import { OverlayContext } from "../contexts/overlay-context";
import ValidatedInputText from "./input-validators/validated-input-text";

import style from "../styles/datepicker.module.css";
import Helpers from "./helpers";

export const DatePicker = forwardRef((props, ref) => {
  const {
    value,
    upperBound,
    onChange,
    headlines,
    buttonsLabels,
    infoLabel,
    focusedHint,
    separator,
    validator,
    "data-testid": dataTestId,
  } = props;
  const { showOverlay, overlay } = useContext(OverlayContext);
  const [date, setDate] = useState({
    year: value?.getFullYear?.() ?? null,
    month: value?.getMonth?.() ?? null,
    day: value?.getDate?.() ?? null,
  });

  const formatDate = (day, month, year) =>
    `${day || date.day}${separator ?? "/"}${month || date.month + 1}${
      separator ?? "/"
    }${year || date.year}`;

  // if the date is stored, use it as default
  const [input, setInput] = useState(value ? formatDate() : "");

  if (value && !input)
    setInput(
      formatDate(value.getDate(), value.getMonth() + 1, value.getFullYear())
    );

  // if the value is set and the date is not, set it
  if (value && overlay && Object.values(date).some((v) => v === null)) {
    setDate({
      day: value.getDate(),
      month: value.getMonth(),
      year: value.getFullYear(),
    });
  }

  // if the input is invalid, erase the state
  // (except if the modal is opened)
  if (
    !validator?.isSubmittable?.(value, null, null, props.required) &&
    !overlay &&
    (date.day || date.month || date.year)
  ) {
    setDate({ day: null, month: null, year: null });
  }

  const showModal = () =>
    showOverlay("datepicker-modal", {
      className: style.modal,
      lastEligibleDate: upperBound,
      onClose: (save = true) => {
        if (!save) setDate({ day: null, month: null, year: null });
      },
      onChange: (date) => {
        if (date)
          setInput(
            formatDate(date.getDate(), date.getMonth() + 1, date.getFullYear())
          );
        onChange(date);
      },
      onFinish: props.onSelected,
      date,
      setDate,
      headlines,
      buttonsLabels,
      infoLabel,
    });

  return (
    <div data-testid={dataTestId} className={style.datepickerInputContainer}>
      <ValidatedInputText
        ref={ref}
        inputMode="numeric"
        data-testid="datepicker-input-text"
        value={input}
        label={props.label}
        validator={validator}
        focusedHint={focusedHint}
        onChange={(e) => {
          const d = e.target.value;
          const dateObject = Helpers.parseDate(d);
          if (dateObject && dateObject.getTime() <= upperBound.getTime()) {
            setDate({
              year: dateObject.getFullYear(),
              month: dateObject.getMonth(),
              day: dateObject.getDate(),
            });
            props.onChange(dateObject);
          } else props.onChange(null);
          setInput(e.target.value);
        }}
        required={props.required}
        action={{
          content: (
            <div
              className={style.iconContainer}
              onClick={(e) => {
                e.stopPropagation();
                // if the date is invalid, erase it before opening the modal
                if (
                  !validator?.isSubmittable?.(value, null, null, props.required)
                ) {
                  setDate({ year: null, month: null, day: null });
                }
                showModal();
              }}
            >
              <CalendarIcon
                data-testid="calendar-icon"
                className={style.calendarIcon}
              />
            </div>
          ),
        }}
      />
    </div>
  );
});
