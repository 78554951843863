import React, { Suspense } from "react";
import Footer from "../../../components/footer";
import InPageLoadingSpinner from "../../../components/in-page-loading-spinner";
import NavBar from "../../../components/navbar";
import { ShowSubscription } from "./showSubscription";
import NewSubscriptionHero from "../../../components/new-subscription-hero";

import pageStyle from "../../../styles/page.module.css";
import style from "../../../styles/personal-information.module.css";
import { useOutletContext } from "react-router-dom";

export const NewSubscriptionPage = (props) => {
  const {
    heroTitle,
    plan,
    applyMeasures,
    noCard,
    className,
    spinnerMessageId,
    contentClassName,
    containerClassName,
    style: styleProp,
  } = props;
  const context = useOutletContext();
  const measures =
    applyMeasures || plan
      ? `${style["content-margin"]} ${style["card-measures"]} ${style["form-measures"]}`
      : "";

  return (
    <div
      className={`${pageStyle.page} ${measures} ${className ?? ""}`}
      style={styleProp}
    >
      <NavBar />
      {heroTitle && <NewSubscriptionHero />}
      <Suspense
        fallback={<InPageLoadingSpinner spinnerMessageId={spinnerMessageId} />}
      >
        <div className={`${pageStyle.content} ${contentClassName ?? ""}`}>
          {plan ? (
            <ShowSubscription
              noCard={noCard}
              plan={plan}
              className={style.planCard}
              containerClassName={containerClassName}
              setPageData={context.setPageData ?? (() => {})}
            >
              {props.children}
            </ShowSubscription>
          ) : (
            <>{props.children}</>
          )}
        </div>
      </Suspense>
      <Footer />
    </div>
  );
};
