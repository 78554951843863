/**
 * Return a password validator
 */

import createValidator from "./validator";

export default function createPasswordValidator(rules) {
  return createValidator(
    (value, focus) => {
      if (value.length > 0) {
        if (!focus && !rules.every((r) => value && r.check(value))) {
          return {
            messageId: "Password_insufficient_error",
          };
        }
      }
    },
    (value) => {
      return rules.every((r) => value && r.check(value));
    }
  );
}
