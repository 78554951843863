import OverlayCard from "./overlay-card";
import style from "../styles/pdf-overlay.module.css";
import Button from "./button";
import DownloadButton from "./download-button";
import {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { OverlayContext } from "../contexts/overlay-context";
import Logger from "./logger/logger";
import useNetwork from "./network/use-network";
import { CallHandler } from "./call-handler";
import downloadPDF from "./network/downloadPDF";
import InPageLoadingSpinner from "./in-page-loading-spinner";
import EventsHelper from "./tracking/events-helper";

const pdfTag = "downloadPDFTag";
export default function PdfOverlay(props) {
  const {
    iframeClassName,
    name,
    src,
    callOptions,
    onClose: close,
    downloadable,
    mainButtonText,
    backButtonText,
    onBeforePDFLoad,
    spinnerMessageIds,
    spinnerMessageId: spinnerMessageIdProp,
    ...rest
  } = props;

  const [pdfUrl, setPdfUrl] = useState(null);
  const [error, setError] = useState(null);
  const [scrollClass, setScrollClass] = useState(style.closeBox);
  const [, setTick] = useState(Date.now());
  const [spinnerMessageId, setSpinnerMessageId] = useState(
    spinnerMessageIdProp ?? spinnerMessageIds?.id ?? ""
  );
  const retryAfterTimer = useRef({ timer: null, start: true });
  const changeSpinnerMessageIdTimer = useRef(null);
  const { startNetworkCall, checkNetworkCall } = useNetwork();
  const downloadPDFCall = checkNetworkCall(pdfTag, false, false);

  // starts the call & the next retry
  const retryCall = useCallback(
    (attempts = 1) => {
      // if has to be started, otherwise do nothing
      retryAfterTimer.current.start = false;
      setTick(Date.now());
      const controller = new AbortController();
      if (!error && !pdfUrl)
        if (!attempts) {
          // if there are no more attempts, return an error
          setError(new Error("No more retries"));
          return;
        }
        // otherwise, make a new call
        else {
          startNetworkCall(
            pdfTag,
            () => {
              return downloadPDF(
                src,
                {
                  ...callOptions,
                  signal: controller.signal,
                },
                (res) => {
                  // if the call timed out, retry it
                  if (res instanceof Error)
                    if (res.status === 204);
                    else if (res.name === "AbortError") setTick(Date.now());
                    else {
                      setError(res);
                      clearTimeout(retryAfterTimer.current.timer);
                      clearTimeout(changeSpinnerMessageIdTimer.current);
                    }
                  else
                    onBeforePDFLoad?.(res)
                      .then(({ data, downloadLink }) => {
                        if (data) {
                          clearTimeout(retryAfterTimer.current.timer);
                          clearTimeout(changeSpinnerMessageIdTimer.current);
                          if (data.blob)
                            setPdfUrl(URL.createObjectURL(data.blob));
                          else setPdfUrl(downloadLink || data.url);
                        } else {
                          setTick(Date.now());
                        }
                      })
                      .catch((err) => {
                        EventsHelper.debugEvent(
                          `Error before loading PDF: ${err}`
                        );
                        setError(err);
                      });
                }
              );
            },
            false,
            false
          );
        }

      // set the timeout for the next attempt
      // or clear the timer variable
      if (!retryAfterTimer.current.timer && callOptions?.retryAfterSeconds)
        retryAfterTimer.current.timer = attempts
          ? setTimeout(() => {
              // cancel any previous call
              controller.abort(
                `Timeout occured, canceled attempt ${
                  callOptions?.attempts - attempts + 1
                } of ${callOptions?.attempts}`
              );
              Logger.w(
                `Timeout occured, canceled attempt ${
                  callOptions?.attempts - attempts + 1
                } of ${callOptions?.attempts}`
              );
              retryAfterTimer.current.timer = null;
              retryAfterTimer.current.start = false;
              retryCall(attempts - 1);
            }, callOptions?.retryAfterSeconds * 1000)
          : null;
    },
    [callOptions, error, pdfUrl, src, startNetworkCall, onBeforePDFLoad]
  );

  // setup timeouts
  useEffect(() => {
    if (!pdfUrl && !error && !changeSpinnerMessageIdTimer.current)
      setTimeout(
        () => setSpinnerMessageId(spinnerMessageIds?.takingTooLongId),
        spinnerMessageIds?.changeIdAfterSeconds * 1000
      );

    if (retryAfterTimer.current.start) retryCall(callOptions?.attempts ?? 1);
  }, [pdfUrl, error, spinnerMessageIds, retryCall, callOptions]);

  const { showOverlay } = useContext(OverlayContext);

  if (error) {
    showOverlay();
    close?.(true);
    return null;
  }

  return (
    <OverlayCard
      {...rest}
      className={`${style.overlayCard} ${style.center}`}
      childrenClassName={style.children}
      closeBoxClassName={`${scrollClass}`}
      close={close}
    >
      <div
        className={`${style.pdfPlugin} ${iframeClassName || ""}`}
        data-testid="pdf-plugin-container"
        onScroll={(e) =>
          setScrollClass(
            e.target.scrollTop > 0 ? style.closeBoxOver : style.closeBox
          )
        }
      >
        {downloadPDFCall?.isPending() ? (
          <Suspense
            fallback={
              <InPageLoadingSpinner spinnerMessageId={spinnerMessageId} />
            }
          >
            <CallHandler call={downloadPDFCall} />
          </Suspense>
        ) : (
          <object
            className={style.pdfObject}
            data={pdfUrl + "#navbar=0&toolbar=0"}
            type="application/pdf"
            title="object PDF show"
          >
            <iframe
              className={style.pdfObject}
              src={pdfUrl + "#navbar=0&toolbar=0"}
              type="application/pdf"
              title="object PDF show"
            >
              Cannot load PDF
            </iframe>
          </object>
        )}
      </div>
      <div className={style.buttons}>
        <Button
          variant="outline"
          data-link-id="back"
          data-testid="back-button"
          onClick={() => {
            showOverlay();
            close?.();
          }}
        >
          {backButtonText}
        </Button>
        {downloadable && (
          <DownloadButton
            data-link-id="download-pdf"
            data-testid="download-pdf"
            href={pdfUrl}
            name={name}
            variant={pdfUrl ? "primary" : "disabled"}
            download={name ?? "download.pdf"}
          >
            {mainButtonText}
          </DownloadButton>
        )}
      </div>
    </OverlayCard>
  );
}
