import React from "react";

import YellowMark from "../../images/yellow-mark.svg";
import RedIcon from "../../images/red-mark.svg";
import ImageVariants from "../image-variants";

const MarkIcon = (props) => {
  const variants = {
    yellow: YellowMark,
    red: RedIcon,
    default: RedIcon,
  };
  return <ImageVariants variants={variants} {...props} />;
};

export default MarkIcon;
