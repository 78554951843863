import React, { useContext } from "react";
import FormattedMsg from "../../../locale/components/formatted-msg";
import style from "../../../styles/pages/resultPage.module.css";
import Logger from "../../../components/logger/logger";
import { NewSubscriptionPage } from "../../common/newSubscription/newSubscriptionPage";
import { useLocation } from "react-router-dom";
import BackButtonDisabler from "../../../components/backbutton-disabler";
import PencilIcon from "../../../components/icons/pencil-icon";
import { ThemeContext } from "../../../contexts/theme-context";
import ConnectivityIcon from "../../../components/icons/connectivity-icon";
import ClockIcon from "../../../components/icons/clock-icon";
import Button from "../../../components/button";
import { ErrorPage } from "../../common/newSubscription/errorPage";

export const ResultPage = (props) => {
  const status = useLocation()?.state?.status ?? props?.status;
  const { theme } = useContext(ThemeContext);
  const ids = {
    success: {
      titleId: "Subscription_successful_title",
      content: (
        <div className={style.pageContent}>
          <div className={style.threeParagraphs}>
            <div className={style.paragraphContainer}>
              <ClockIcon
                className={style.paragraphIcon}
                variant={theme === "dark" ? "white" : "red"}
              />
              <p className={style.paragraphText}>
                <FormattedMsg id="Subscription_successful_copy_1" />
              </p>
            </div>
            <div className={style.paragraphContainer}>
              <ConnectivityIcon
                className={style.paragraphIcon}
                variant={theme === "dark" ? "white" : "red"}
              />
              <p className={style.paragraphText}>
                <FormattedMsg id="Subscription_successful_copy_2" />
              </p>
            </div>
            <div className={style.paragraphContainer}>
              <PencilIcon
                className={style.paragraphIcon}
                variant={theme === "dark" ? "white" : "red"}
              />
              <p className={style.paragraphText}>
                <FormattedMsg
                  id="Subscription_successful_copy_3"
                  values={{
                    Vodafone_link: (
                      <Button
                        className={style.link}
                        href="https://www.vodafone.de"
                        variant="link"
                        external
                      >
                        <FormattedMsg id="Vodafone_link" />
                      </Button>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      ),
    },
    pending: {
      titleId: "Pending_subscription_order_title",
      descriptionId: "Pending_subscription_order_copy",
    },
  }[status] ?? { error: true };

  Logger.d("Result page", props, ids);

  if (ids.error)
    return (
      <BackButtonDisabler>
        <ErrorPage {...ids} status={status === "error" ? null : status} />
      </BackButtonDisabler>
    );
  return (
    <NewSubscriptionPage className={style.page}>
      <BackButtonDisabler>
        <div className={`${style.heroImage} ${style[status + "HeroImage"]}`} />
        <div
          className={`${ids.descriptionId ? style.container : ""} ${
            style.pageContainer
          }`}
          data-testid={"result-page-container-" + status}
        >
          <h1 className={style.title} data-testid="result-page-title">
            <FormattedMsg id={ids.titleId} />
          </h1>
          {ids.descriptionId ? (
            <p className={style.info} data-testid="result-page-copy">
              <FormattedMsg
                id={ids.descriptionId}
                values={{
                  link: (
                    <Button
                      className={style.link}
                      href="https://www.vodafone.de"
                      variant="link"
                      external
                    >
                      <FormattedMsg id="Vodafone_link" />
                    </Button>
                  ),
                }}
              />
            </p>
          ) : (
            ids.content
          )}
        </div>
      </BackButtonDisabler>
    </NewSubscriptionPage>
  );
};
