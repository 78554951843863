import { useContext, useRef, useState } from "react";
import ValidatedInputText from "../../../components/input-validators/validated-input-text";
import LocaleContext from "../../../contexts/locale-context";
import PlacesContext from "../../../components/places/places-context";
import PlacesHelper from "../../../components/places/places-helper";
import Prediction from "../../../components/places/prediction";
import ConfigHelper from "../../../config/config-helper";
import Helpers from "../../../components/helpers";
import Logger from "../../../components/logger/logger";

import style from "../../../styles/address.module.css";

export default function Address(props) {
  const {
    className,
    testId,
    rowStyle,
    boxStyle,
    smallboxStyle,
    postcode,
    setPostcode,
    city,
    setCity,
    street,
    setStreet,
    houseNumber,
    setHouseNumber,
    secondLine,
    setSecondLine,
    validators,
    minLengths,
    maxLengths,
    requiredFields,
  } = props;
  const { formatMessage } = useContext(LocaleContext);
  const placesContext = useContext(PlacesContext);
  const [postCodeAutoComplete, setPostcodeAutoComplete] = useState();
  const [cityAutoComplete, setCityAutoComplete] = useState();
  const [streetAutoComplete, setStreetAutoComplete] = useState();
  const [houseNumberAutoComplete, sethouseNumberAutoComplete] = useState();
  const [autoCompleteTimer, setAutoCompleteTimer] = useState(undefined);
  const [usedAutoComplete, setUsedAutoComplete] = useState(false);
  const [, setLastRender] = useState(0);

  const valuesRef = {
    postcode: useRef(null),
    city: useRef(null),
    street: useRef(null),
    houseNumber: useRef(null),
    secondLine: useRef(null),
  };
  const boxRefs = {
    postcode: useRef(null),
    city: useRef(null),
    street: useRef(null),
    houseNumber: useRef(null),
    secondLine: useRef(null),
  };

  const values = {
    postcode,
    city,
    street,
    houseNumber,
  };

  const getFocusedElement = () =>
    Object.keys(boxRefs).find((key) => {
      return (
        boxRefs[key].current &&
        document.activeElement?.tagName?.toLowerCase() === "input" &&
        boxRefs[key].current.contains(document.activeElement)
      );
    });

  //get focused element
  const focusedElement = getFocusedElement();
  Object.keys(boxRefs).forEach((key) => {
    if (key !== focusedElement) {
      setAutoCompleteValue(key, null);
    }
  });

  // if an element is focused and autocompletition has just been used
  if (focusedElement && usedAutoComplete) {
    // go to next empty or invalid field
    const order = ["postcode", "city", "street", "houseNumber"];
    setUsedAutoComplete(false);
    const next = order.find(
      (s) => !values[s] || !validators[s]?.isValidated(values[s])
    );

    // Use setTimeout to avoid interfering with focus-related input component state
    setTimeout(
      () =>
        next && boxRefs[next].current?.getElementsByTagName("input")[0].focus()
    );
  }

  function onAutoCompleteResult(source, result) {
    //save selected value
    const values = {
      postcode: PlacesHelper.getPlacePostcode(placesContext, result),
      city: PlacesHelper.getPlaceCity(placesContext, result),
      street: PlacesHelper.getPlaceStreet(placesContext, result),
      houseNumber: PlacesHelper.getPlaceStreetNumber(placesContext, result),
    };
    const validation = {
      postcode: false,
      city: false,
      street: false,
      houseNumber: false,
      secondLine: false,
    };
    const valueStates = {
      postcode,
      city,
      street,
      houseNumber,
    };

    if (values.postcode) {
      // every possible scenario
      // if the street and the postcode have already been validated,
      // keep both as validated when querying for the same postcode
      if (
        street &&
        validators["postcode"]?.isValidated?.(values.postcode) &&
        validators["street"]?.isValidated?.(values.street) &&
        source === "postcode"
      ) {
        validation.street = true;
        validation.houseNumber = true;
      }
      validation.postcode = true;
    }
    if (values.city) {
      // if the street and the city have already been validated,
      // keep both as validated when querying for the same city
      if (
        street &&
        validators["city"]?.isValidated?.(values.city) &&
        validators["street"]?.isValidated?.(values.street) &&
        source === "city"
      ) {
        validation.street = true;
        validation.houseNumber = true;
      }
      validation.city = true;
    }
    if (values.street) {
      validation.street = true;
      validation.houseNumber = true;
    }
    if (ConfigHelper.isLocationLogsEnabled()) {
      Logger.d(
        "Found from autocomplete: ",
        values.postcode,
        values.city,
        values.street,
        values.houseNumber
      );
    }
    Object.keys(validation).forEach((key) => {
      const v = validators[key];
      const valid = validation[key];
      const validated = v.isValidated?.(values[key]);
      if (validated && !valid) {
        //no more valid
        v.setValidated("");
        validation[key] = false;
      } else {
        //has just been validated
        v.setValidated?.(values[key] ?? valueStates[key] ?? "");
        validation[key] = true;
      }
    });
    clearAutoComplete(source);
    setUsedAutoComplete(true);
  }

  function getAcDetails(source) {
    let setterFn, getterFn, acValue;
    switch (source) {
      case "postcode":
        acValue = postCodeAutoComplete;
        setterFn = setPostcodeAutoComplete;
        getterFn = PlacesHelper.getPlacePostcode;
        break;
      case "city":
        acValue = cityAutoComplete;
        setterFn = setCityAutoComplete;
        getterFn = PlacesHelper.getPlaceCity;
        break;
      case "street":
        acValue = streetAutoComplete;
        setterFn = setStreetAutoComplete;
        getterFn = PlacesHelper.getPlaceStreet;
        break;
      case "houseNumber":
        acValue = houseNumberAutoComplete;
        setterFn = sethouseNumberAutoComplete;
        getterFn = PlacesHelper.getPlaceStreetNumber;
        break;
      default:
        //no source, get out
        return;
    }
    //bind getterFn to placehelper to have the same behavior as calling PlaceHelper.<fn>
    getterFn = getterFn.bind(PlacesHelper);
    return {
      acValue,
      setterFn,
      getterFn,
    };
  }

  function setAutoCompleteValue(source, value) {
    const acDetails = getAcDetails(source);
    const focused = getFocusedElement();
    Logger.d("setAutocomplete for " + source, focused, value, acDetails);
    if (focused === source || value === null) {
      //replace old switch, handles autocompelte boxes when still focused
      if (value !== acDetails?.acValue) {
        acDetails?.setterFn?.(value);
      }
    } else {
      //the focused has been switched, so we're not showing autocomplete results.
      //Instead, check if the component can be automatically validated
      tryAutoCompleteValuesToCheckFields(source, value);
    }
  }

  function tryAutoCompleteValuesToCheckFields(
    source,
    autoCompleteValuesToCheck
  ) {
    const acDetails = getAcDetails(source);
    const autoCompleteValues =
      autoCompleteValuesToCheck instanceof Array
        ? autoCompleteValuesToCheck
        : [autoCompleteValuesToCheck];
    //in order to have google compatibility, we have to be asynchronous and
    //use get place detail for each result
    const promises = [];
    const compatibleResults = [];
    const currentValue = valuesRef[source].current?.trim?.()?.toLowerCase?.();
    autoCompleteValues.forEach((val) => {
      promises.push(
        new Promise((resolve, reject) => {
          try {
            PlacesHelper.getPlaceDetails(
              placesContext,
              boxRefs[source],
              val,
              Helpers.getSavedCountry(true),
              (result) => {
                try {
                  if (
                    acDetails
                      ?.getterFn?.(placesContext, result)
                      ?.toLowerCase?.() === currentValue
                  ) {
                    //ok, found! add to compatible results
                    compatibleResults.push(result);
                    resolve(result);
                  } else {
                    resolve(null);
                  }
                } catch (err) {
                  reject(err);
                }
              }
            );
          } catch (err) {
            reject(err);
          }
        })
      );
    });
    Promise.all(promises)
      .then(() => {
        let needRefresh = true;
        const postcodeValue = values.postcode?.trim?.();
        const postcodeValueLc = postcodeValue?.toLowerCase?.();
        const cityValue = values.city?.trim?.();
        const cityValueLc = cityValue?.toLowerCase?.();
        const streetValue = values.street?.trim?.();
        const streetValueLc = streetValue?.toLowerCase?.();
        Logger.d(
          "Checking AC details results",
          compatibleResults,
          acDetails,
          postcodeValue,
          postcode,
          cityValue,
          city,
          streetValue,
          street
        );
        if (compatibleResults.length > 0) {
          //we have at least one postcode result!
          let goodResult;
          compatibleResults.every((res) => {
            const postcode = PlacesHelper.getPlacePostcode(placesContext, res);
            const city = PlacesHelper.getPlaceCity(placesContext, res);
            const street = PlacesHelper.getPlaceStreet(placesContext, res);
            const isPostcodeEqual =
              postcodeValueLc === postcode?.toLowerCase?.();
            const isCityEqual = cityValueLc === city?.toLowerCase?.();
            const isStreetEqual = streetValueLc === street?.toLowerCase?.();
            if (!postcodeValue) {
              //no postcode in the input box
              if (postcode) {
                //we have a postcode in the result
                if (!cityValue) {
                  //no postcode nor city input but some result... check the street
                  if (street && streetValue) {
                    if (city) {
                      goodResult = {
                        postcode,
                        city,
                        street,
                      };
                      return false;
                    } else {
                      //no city but street and postcode??? weird
                      goodResult = {
                        postcode,
                        street,
                      };
                    }
                  } else {
                    //no matching postcode, no matching city nor street... weirdest
                    Logger.d("Autocomplete Result with no match?", res);
                  }
                } else if (isCityEqual) {
                  //and matching city...
                  //We have to update the postcode
                  if (isStreetEqual) {
                    //perfect, update everything
                    //exit loop
                    goodResult = {
                      postcode,
                      city,
                      street,
                    };
                    return false;
                  } else {
                    //street do not match
                    goodResult = {
                      postcode,
                      city,
                    };
                  }
                } else {
                  //not matching postcode, nor city but we have a street??? skip result
                }
              } else {
                //no postcode value in the input box nor in the result
                if (!cityValue) {
                  if (city) {
                    if (!streetValue) {
                      //no postcode, city, street input??? skip
                      Logger.d(
                        "No valid autocomplete input but still, a result",
                        res
                      );
                    } else if (isStreetEqual) {
                      //street result with city
                      if (goodResult.postcode && goodResult.street) {
                        //skip
                        Logger.d("Skip autoresult", res, goodResult);
                      } else if (!goodResult.postcode && !goodResult.street) {
                        //save result
                        goodResult = {
                          city,
                          street,
                        };
                      }
                    } else {
                      //skip, not interested
                    }
                  } else {
                    //no city nor postcode in the result
                    if (isStreetEqual) {
                      //street matches
                      if (!goodResult) {
                        goodResult = {
                          street,
                        };
                      } else {
                        //we have something better
                      }
                    } else {
                      //nothing matches, just skip
                    }
                  }
                } else if (isCityEqual) {
                  //city matches
                  if (!streetValue) {
                    //save result
                    goodResult = {
                      city,
                      street,
                    };
                  } else if (isStreetEqual) {
                    //does not match, skip
                  }
                } else {
                  //no postcode and city present but not matching... skip
                }
              }
            } else if (isPostcodeEqual) {
              if (!cityValue) {
                //postcode only result
                goodResult = {
                  postcode,
                };
                return false;
              } else if (isCityEqual) {
                if (!streetValue) {
                  //No street, best result ever
                  goodResult = {
                    postcode,
                    city,
                  };
                  return false;
                } else if (isStreetEqual) {
                  //everything is in the result, great!!!
                  //exit loop
                  goodResult = {
                    postcode,
                    city,
                    street,
                  };
                  return false;
                } else if (validators.street.isValidated(street)) {
                  //everything is validated, street already valid!!!
                  //exit loop
                  goodResult = {
                    postcode,
                    city,
                    street,
                  };
                  return false;
                } else {
                  //continue with loop, but check the result
                  if (!goodResult.city) {
                    //this is more good, save it
                    goodResult = {
                      postcode,
                      city,
                    };
                  }
                }
              } else if (
                validators.city.isValidated(cityValue) &&
                validators.postcode.isValidated(postcode)
              ) {
                //Our city is invalid BUT the same city with the same postcode has been already validated
                if (validators.street.isValidated(streetValue)) {
                  //ok, city and streets are valid, fine for me
                  //exit loop
                  goodResult = {
                    postcode,
                    city: cityValue,
                    street: streetValue,
                  };
                  return false;
                } else {
                  //postcode and city are valid, save it if better
                  if (!goodResult.city && !goodResult.street) {
                    goodResult = {
                      postcode,
                      city: cityValue,
                    };
                  }
                }
              }
            } else if (validators.postcode.isValidated(postcode)) {
              //postcode already validated, but changed, should de-validate postcode if nothing better happens
            }
            return true;
          });
          Logger.d("Automatic autocomplete result", goodResult);
          //we looped but did not find a perfect test, let's get the best one
          if (goodResult?.postcode) {
            if (postcodeValue !== goodResult.postcode) {
              setPostcode(goodResult.postcode);
              needRefresh = false;
            }
            validators.postcode.setValidated(goodResult.postcode);
          }
          if (goodResult?.city) {
            if (cityValue !== goodResult.city) {
              setCity(goodResult.city);
              needRefresh = false;
            }
            validators.city.setValidated(goodResult.city);
          }
          if (goodResult?.street) {
            if (streetValue !== goodResult.street) {
              setStreet(goodResult.street);
              needRefresh = false;
            }
            validators.street.setValidated(goodResult.street);
          }
        }
        if (needRefresh) {
          //ask for a refresh
          setLastRender(Date.now());
        }
      })
      .catch((err) => {
        Logger.d("AC Finished, got error", err);
      });
  }

  const autoCompleteMinChars = {
    postcode: 2,
    city: 2,
    street: 2,
    houseNumber: 1,
  };

  const country = Helpers.getSavedCountry(true);

  function getAutoComplete(source, value) {
    if (ConfigHelper.isLocationVerificationEnabled()) {
      // reset validation
      //validators[source].setValidated(null);
      try {
        clearTimeout(autoCompleteTimer);
      } catch (error) {}
    }
    if (
      value?.length >= autoCompleteMinChars[source] &&
      validators[source].validateInvalidChars(value)
    ) {
      if (ConfigHelper.isLocationVerificationEnabled()) {
        const now = Date.now();
        validators[source].setLoading(now);
        setAutoCompleteTimer(
          setTimeout(() => {
            const cityValue =
              source !== "postcode"
                ? source === "city"
                  ? value
                  : city
                : undefined;
            const streetValue =
              source !== "postcode" && source !== "city"
                ? source === "street"
                  ? value
                  : street
                : undefined;
            //never use house number in autocomplete, ONB-1013
            const houseNumberValue = undefined;
            const postcodeValue = source === "postcode" ? value : postcode;
            //set autocomplete starting value
            valuesRef[source].current = value;
            PlacesHelper.getAutocompletePlacesPredictions(
              placesContext,
              country,
              cityValue,
              postcodeValue,
              streetValue,
              houseNumberValue,
              (predictions) => {
                if (valuesRef[source]?.current === value) {
                  const values =
                    predictions instanceof Array && predictions.length > 0
                      ? predictions
                      : null;
                  if (!validators[source].isValidated(value)) {
                    setAutoCompleteValue(source, values);
                  }
                  //since we are running very very late, check if we should work on invalidation as well
                  if (values === null) {
                    // validators[source].setValidated(false);
                  }
                  if (predictions instanceof Error) {
                    //ask for a refresh
                    setTimeout(() => {
                      setLastRender(Date.now());
                    }, 0);
                  }
                } else {
                  //source value has changed, no longer valid for autocomplete
                }
                if (validators[source]?.isLoading?.() === now) {
                  validators[source]?.setLoading?.(null);
                }
              }
            );
          }, ConfigHelper.getAutoCompleteTimeout())
        );
      }
    } else {
      Logger.v(
        "NOT AUTOCOMPLETING, ",
        source,
        value,
        validators[source].validateInvalidChars(value)
      );
      setAutoCompleteValue(source, undefined);
    }
  }

  const sbStyle = `${style.addressBox}${
    smallboxStyle ? ` ${smallboxStyle}` : ""
  }`;
  const bStyle = `${style.addressBox}${boxStyle ? ` ${boxStyle}` : ""}`;

  const clearAutoComplete = (source, removeTimeout) => {
    if (autoCompleteTimer !== undefined && removeTimeout) {
      try {
        clearTimeout(autoCompleteTimer);
        validators[source]?.setLoading?.(null);
      } catch (error) {}
      setAutoCompleteTimer(undefined);
    }
    if (source) {
      setAutoCompleteValue(source, null);
    }
  };

  const onBlur = (source) => {
    if (source) {
      const sourceValue = values[source];
      const sourceValueTrimmed = sourceValue?.trim?.();
      const acValues = getAcDetails(source)?.acValue;
      //close autocomplete predictions box
      setAutoCompleteValue(source, null);
      if (
        sourceValueTrimmed &&
        (minLengths[source]
          ? sourceValueTrimmed?.length >= minLengths[source]
          : true)
      ) {
        //valid sourceValue, check if it's not already validated
        if (!validators[source]?.isValidated?.(sourceValue)) {
          //we have to validate, check if we already have the autocomplete values (box opened)
          if (acValues) {
            //we already have an autocomplete list of possible values, check it
            tryAutoCompleteValuesToCheckFields(source, acValues);
          } else {
            //should trigger the autocomplete flow
            getAutoComplete(source, sourceValueTrimmed);
          }
        }
      }
    }
  };

  return (
    <div
      className={`${style.container} ${className || null}`}
      data-testid={testId || null}
    >
      <div className={rowStyle}>
        <div className={sbStyle} ref={boxRefs.postcode}>
          <ValidatedInputText
            type="tel"
            inputMode="numeric"
            data-testid="postcode-input"
            value={postcode}
            label={formatMessage("Postcode_input_label")}
            onChange={(e) => {
              const value = e.target.value;
              setPostcode(value);
              clearAutoComplete("postcode", true);
              getAutoComplete("postcode", value, postcode);
            }}
            minLength={minLengths?.postcode}
            maxLength={maxLengths?.postcode}
            required={requiredFields?.postcode}
            validator={validators.postcode}
            onBlur={() => onBlur("postcode")}
          />
          <Prediction
            className={style.predictions}
            key="postcodeAutoComplete"
            data-testid="postcode-autoComplete"
            values={postCodeAutoComplete}
            search={valuesRef.postcode.current}
            country={country}
            onClick={(value) => {
              onAutoCompleteResult("postcode", value);
            }}
          />
        </div>
        <div className={bStyle} ref={boxRefs.city}>
          <ValidatedInputText
            data-testid="city-input"
            value={city}
            label={formatMessage("City_input_label")}
            onChange={(e) => {
              const value = e.target.value;
              setCity(value);
              clearAutoComplete("city", true);
              getAutoComplete("city", value, city);
            }}
            minLength={minLengths?.city}
            maxLength={maxLengths?.city}
            required={requiredFields?.city}
            validator={validators?.city}
            onBlur={() => onBlur("city")}
          />
          <Prediction
            className={style.predictions}
            key="cityAutoComplete"
            data-testid="city-autoComplete"
            values={cityAutoComplete}
            search={valuesRef.city.current}
            country={country}
            onClick={(value) => {
              onAutoCompleteResult("city", value);
            }}
          />
        </div>
      </div>
      <div className={rowStyle}>
        <div className={bStyle} ref={boxRefs.street}>
          <ValidatedInputText
            data-testid="street-input"
            value={street}
            label={formatMessage("Street_name_input_label")}
            onChange={(e) => {
              const value = e.target.value;
              setStreet(value);
              clearAutoComplete("street", true);
              getAutoComplete("street", value, street);
            }}
            minLength={minLengths?.street}
            maxLength={maxLengths?.street}
            required={requiredFields?.street}
            validator={validators?.street}
            onBlur={() => onBlur("street")}
          />
          <Prediction
            className={style.predictions}
            key="streetAutoComplete"
            data-testid="street-autoComplete"
            values={streetAutoComplete}
            search={valuesRef.street.current}
            country={country}
            onClick={(value) => {
              onAutoCompleteResult("street", value);
            }}
          />
        </div>
        <div className={sbStyle} ref={boxRefs.houseNumber}>
          <ValidatedInputText
            data-testid="houseNumber-input"
            value={houseNumber}
            label={formatMessage("House_number_input_label")}
            onChange={(e) => {
              const value = e.target.value;
              setHouseNumber(value);
            }}
            minLength={minLengths?.houseNumber}
            maxLength={maxLengths?.houseNumber}
            required={requiredFields?.houseNumber}
            validator={validators?.houseNumber}
          />
        </div>
      </div>
      <div className={rowStyle} ref={boxRefs.secondLine}>
        <ValidatedInputText
          className={boxStyle}
          data-testid="secondLine-input"
          value={secondLine}
          label={formatMessage("Second_line_of_address_input_label")}
          onChange={(e) => setSecondLine(e.target.value)}
          minLength={minLengths?.secondLine}
          maxLength={maxLengths?.secondLine}
          required={requiredFields?.secondLine}
          validator={validators?.secondLine}
        />
      </div>
    </div>
  );
}
