import ConfigHelper from "../../config/config-helper";
import Logger from "../logger/logger";
import CallHelper from "./call-helper";
import fetchHelper from "./fetch-helper";

export default function quotationsData(config) {
  return new Promise(async (resolve, reject) => {
    try {
      const call = CallHelper.createCall(config.network.endpoints.quotations);
      const res = await fetchHelper(call.url, {
        method: call.method,
        headers: call.headers,
      });
      if (res.status === 200) {
        //Succesful
        const json = await res.json();
        resolve(json);
      } else {
        const error = new Error("HTTP Error " + res?.status);
        reject(error);
      }
    } catch (error) {
      if (ConfigHelper.isDevelopmentMode()) {
        Logger.w("Error during call: ", error);
      }
      reject(error);
    }
  });
}
