import { v4 as uuidv4 } from "uuid";
import { Countries } from "../config/countries";
import Logger from "./logger/logger";

const SESSION_STORAGE_SESSION_INFO = "sessionInfo";
const SESSION_STORAGE_COUNTRY = "country";
const SESSION_STORAGE_TOKEN = "token";
const SESSION_STORAGE_LANG = "lang";
const SESSION_STORAGE_PARAMS = "params";
const LOCATION_COUNTRY_PARAMETER = "country";
const LOCATION_LANG_PARAMETER = "lang";
const SESSION_STORAGE_NETWORK_PROFILE = "np_";
const LOCATION_TRANSACTION_ID_PARAMETER = "transactionId";
const LOCATION_TOKEN_PARAMETER = "trace";
const SESSION_STORAGE_DOWNLOAD_ESIM_STATUS = "downloadesimstatus";
const DATE_VALIDATOR_REGEX = /^(\d{1,2})\D(\d{1,2})\D(\d{4})$/;
const SESSION_STORAGE_COOKIES_TAG = "cookies";

export default class Helpers {
  /**
   * @function copyToClipboard
   * @param {string} message String to copy into the clipboard
   * @description Copies the string passed as parameter into the clipboard of the browser. Cannot use clipboard APIs because they are not available on windows and they have a lot of security constraints
   */
  static copyToClipboard(message) {
    const textarea = document.createElement("textarea");
    textarea.value = message;
    textarea.setAttribute("readonly", "");
    textarea.style = { position: "absolute", left: "-999999px" };
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }

  static downloadPdfBlob(url, name, setLoadingFunction, logFunction) {
    const setLoading = (...args) => {
      if (setLoadingFunction) {
        setLoadingFunction(...args);
      }
    };
    const appendLogs = (...args) => {
      if (logFunction) {
        logFunction(...args);
      }
    };
    setLoading(true, url);
    try {
      window
        .fetch(url)
        .then((response) => {
          response
            .blob()
            .then((blob) => {
              const objectUrl = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = objectUrl;
              a.setAttribute("download", name);
              a.innerHTML = "downloading...";
              a.style.display = "none";
              const eventListener = (event) => {
                event.stopPropagation();
                a.removeEventListener("click", eventListener);
              };
              a.addEventListener("click", eventListener);
              setTimeout(function () {
                a.click();
                document.body.removeChild(a);
                setTimeout(() => {
                  setLoading(false, url);
                  URL.revokeObjectURL(objectUrl);
                }, 250);
              }, 66);
              document.body.appendChild(a);
            })
            .catch((error) => {
              appendLogs("Download error: " + error);
              setLoading(false, url);
            });
        })
        .catch((error) => {
          appendLogs("Download error: " + error);
          setLoading(false, url);
        });
    } catch (error) {
      appendLogs("Error while creating the download: " + error);
      setLoading(false, url);
    }
  }

  static randomHexString(length) {
    const digits = "0123456789abcdef";
    let n = "";
    for (let i = 0; i < length; i++) {
      const rand = Math.floor(Math.random() * 16);
      n += digits[rand];
    }
    return n;
  }

  static generateTransactionId() {
    return uuidv4();
  }

  static getUrlParameter(name, location) {
    const loc =
      typeof location === "string"
        ? location
        : location?.search || location?.toString() || null;
    if (loc) {
      return new URLSearchParams(loc).get(name);
    }
  }

  static appendUrlParameters(url, ...parameters) {
    const myUrl = typeof url === "string" ? url : "";
    const separator = myUrl.indexOf("?") >= 0 ? "&" : "?";
    //create parameters string
    const parameterString = Array.isArray(parameters)
      ? parameters.join("&")
      : parameters;
    return myUrl + (parameterString ? separator + parameterString : "");
  }

  static copyParameters(srcUrl, dstUrl) {
    const srcWithoutHash = srcUrl ? srcUrl.split("#", 2)[0] : null;
    const srcParsIndex = srcWithoutHash ? srcWithoutHash.indexOf("?") : -1;
    let result = dstUrl;
    if (srcParsIndex >= 0 && srcWithoutHash.length > srcParsIndex) {
      const srcPars = srcWithoutHash.substring(srcParsIndex + 1);
      const separator = dstUrl?.indexOf("?") >= 0 ? "&" : "?";
      //handle any hash in dstUrl
      const dstHashIndex = dstUrl ? dstUrl.indexOf("#") : -1;
      let dstUrlBase = dstUrl || "";
      let dstUrlHash = "";
      if (dstHashIndex >= 0) {
        dstUrlBase = dstUrl.substring(0, dstHashIndex);
        dstUrlHash = dstUrl.substring(dstHashIndex);
      }
      result = `${dstUrlBase}${separator}${srcPars}${dstUrlHash}`;
    }
    return result;
  }

  /**
   * Find a supported country given a name or alternative name
   * @param {String} country
   * @return {Country} country
   */
  static getCountry(country) {
    let result;
    if (country) {
      const lc =
        typeof country === "string" ? country.toLowerCase() : country.name;
      //try to find it directly by name
      result = Countries[lc];
      if (!result) {
        //try to find it via alternative name
        for (const name in Countries) {
          const c = Countries[name];
          if (c.alternativeName?.toLowerCase() === lc) {
            //found by alternative name
            result = c;
            break;
          }
        }
      }
    }
    Logger.v(`Getting country from "${country}"`, result);
    return result;
  }

  /**
   * Find a supported country from a given location or url
   * @param {object|String} location
   * @return {Country} country
   */
  static getCountryFromLocation(location) {
    Logger.d(
      "Getting country for location",
      location,
      location?.search,
      location?.toString?.()
    );
    return this.getCountry(this.getCountryParameter(location));
  }

  static getCountryParameter(location) {
    return this.getUrlParameter(LOCATION_COUNTRY_PARAMETER, location);
  }

  static getLangFromLocation(location) {
    return this.getUrlParameter(LOCATION_LANG_PARAMETER, location);
  }

  static getTransactionIdFromLocation(location) {
    return this.getUrlParameter(LOCATION_TRANSACTION_ID_PARAMETER, location);
  }

  // tries to retrieve the token from the hash and the query paramas
  // if only one is found, uses that
  static getTokenFromLocation(location) {
    const tokens = { hash: null, query: null };
    if (location.pathname) {
      //get the token from the hash part
      tokens.hash = location.hash?.substring(1);
    } else if (typeof location === "string") {
      const idx = location.indexOf("#");
      if (idx >= 0) {
        tokens.hash = location.substring(idx + 1);
      }
    }

    tokens.query = this.getUrlParameter(LOCATION_TOKEN_PARAMETER, location);

    if (tokens.query) {
      Logger.v("Using token from query parameters");
      return tokens.query;
    } else if (tokens.hash) {
      Logger.v("Using token from fragment");
      return tokens.hash;
    } else {
      Logger.v("No token found");
      return null;
    }
  }

  static getLocationParams(location) {
    if (location) {
      const params = new URLSearchParams(location.search || "");
      // params.delete(LOCATION_COUNTRY_PARAMETER);
      // params.delete(LOCATION_LANG_PARAMETER);
      return params.toString();
    } else return "";
  }

  static getSavedLocationParams() {
    return Helpers.getSessionInfo()?.[SESSION_STORAGE_PARAMS] || "";
  }

  static saveUserSessionInfo(country, token, lang, redirectPars) {
    Logger.d("Saving country and token information", country, token);
    //first of all, clear old data
    sessionStorage.removeItem(SESSION_STORAGE_SESSION_INFO);
    const newSession = {};
    const countryToBeSaved =
      typeof country === "string" ? country : country?.name;
    newSession[SESSION_STORAGE_COUNTRY] = countryToBeSaved;
    newSession[SESSION_STORAGE_TOKEN] = token;
    newSession[SESSION_STORAGE_LANG] = lang;
    newSession[SESSION_STORAGE_PARAMS] = redirectPars;
    Helpers.saveToSessionStorage(SESSION_STORAGE_SESSION_INFO, newSession);
  }

  static saveNetworkProfile(profile) {
    Helpers.saveToSessionStorage(SESSION_STORAGE_NETWORK_PROFILE, profile);
  }

  static getNetworkProfile() {
    const savedInfo = Helpers.getFromSessionStorage(
      SESSION_STORAGE_NETWORK_PROFILE
    );
    // if the profile is not saved, return an empty profile
    // so to avoid the caller to check it
    const empty = { profile: null, error: null };
    return savedInfo ?? empty;
  }

  static saveSIMDownloadStatus(result) {
    Helpers.saveToSessionStorage(SESSION_STORAGE_DOWNLOAD_ESIM_STATUS, {
      result,
    });
  }

  static getDownloadSIMStatus() {
    const savedInfo = Helpers.getFromSessionStorage(
      SESSION_STORAGE_DOWNLOAD_ESIM_STATUS
    );
    // if the profile is not saved, return an empty profile
    // so to avoid the caller to check it
    const empty = { result: null };
    return savedInfo ?? empty;
  }

  static getSessionInfo() {
    return Helpers.getFromSessionStorage(SESSION_STORAGE_SESSION_INFO);
  }

  /**
   *
   * @param {boolean} validate
   * @returns {Country} country
   */
  static getSavedCountry(validate = true) {
    const savedCountry = this.getSessionInfo()?.[SESSION_STORAGE_COUNTRY];
    if (validate) {
      return this.getCountry(savedCountry);
    } else {
      return savedCountry;
    }
  }

  static getSavedToken() {
    const savedToken = this.getSessionInfo()?.[SESSION_STORAGE_TOKEN];
    return savedToken;
  }

  static getSavedLang() {
    return this.getSessionInfo()?.[SESSION_STORAGE_LANG];
  }

  static getDateValidatorRegex() {
    return DATE_VALIDATOR_REGEX;
  }

  static parseDate(d) {
    const res = DATE_VALIDATOR_REGEX.exec(d);

    // the value didn't match
    if (res === null) return null;

    // if one of the first two groups is bigger than 12, it's considered the day
    // if they're both smaller or equal than 12, the first is considered the day
    // if they're both bigger than 12, the date is invalid
    if (
      (res[2] > 12 && res[1] > 12) ||
      res[1] <= 0 ||
      res[2] <= 0 ||
      res[3] <= 0
    ) {
      return null;
    }

    // convert also to number
    const year = res[3] * 1;
    const day = (res[2] > 12 ? res[2] : res[1]) * 1;
    const month = (res[2] > 12 ? res[1] : res[2]) * 1;

    // check if values make sense (e.g. catch a date like 32/../.... as invalid)
    const date = new Date(year, month - 1, day);

    if (
      date.getDate() !== day ||
      date.getMonth() !== month - 1 ||
      date.getFullYear() !== year
    ) {
      return null;
    } else return date;
  }

  static getWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  static saveToSessionStorage(key, data) {
    try {
      sessionStorage.setItem(key, encodeURI(JSON.stringify(data)));
    } catch (e) {
      Logger.e(
        `error while saving to sessionStorage (${key}) with value: ${data}`,
        e
      );
    }
  }

  static getFromSessionStorage(key) {
    const data = sessionStorage.getItem(key);
    let res;
    if (data) {
      try {
        res = JSON.parse(decodeURI(data));
      } catch (e) {
        Logger.e(
          `error while getting from sessionStorage (${key}), got value: ${data}`,
          e
        );
      } finally {
        return res;
      }
    } else return null;
  }

  static saveToLocalStorage(key, data) {
    try {
      localStorage.setItem(key, encodeURI(JSON.stringify(data)));
    } catch (e) {
      Logger.e(
        `error while saving to sessionStorage (${key}) with value: ${data}`,
        e
      );
    }
  }

  static getFromLocalStorage(key) {
    const data = localStorage.getItem(key);
    let res;
    if (data) {
      try {
        res = JSON.parse(decodeURI(data));
      } catch (e) {
        Logger.e(
          `error while getting from sessionStorage (${key}), got value: ${data}`,
          e
        );
      } finally {
        return res;
      }
    } else return null;
  }

  static removeFromLocalStorage(key) {
    if (key) localStorage.removeItem(key);
  }

  static saveCookiesPreferences(object) {
    return this.saveToSessionStorage(SESSION_STORAGE_COOKIES_TAG, object);
  }

  static getCookiesPreferences() {
    return this.getFromSessionStorage(SESSION_STORAGE_COOKIES_TAG);
  }
}
