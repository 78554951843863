import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import LocaleContext from "../contexts/locale-context";
import { TickIcon } from "./icons/tick-icon";
import ValidatedInputText from "./input-validators/validated-input-text";
import { ThemeContext } from "../contexts/theme-context";
import MarkIcon from "./icons/mark-icon";

import style from "../styles/iban-input.module.css";

const IBANInput = (props, fwdRef) => {
  const { value, onChange, required, focusedHint, validator } = props;
  const { formatMessage } = useContext(LocaleContext);
  const myRef = useRef(null);
  const ref = fwdRef || myRef;
  const [focused, setFocused] = useState(null);

  // returns the regex to match a country specific iban
  useEffect(() => {
    if (ref.current) {
      const f =
        document.activeElement?.tagName?.toLowerCase?.() === "input"
          ? ref.current?.contains?.(document.activeElement)
          : false;
      if (f !== focused) {
        setFocused(f);
      }
    }
  }, [ref, focused, setFocused]);

  const { theme } = useContext(ThemeContext);
  const hasInvalidChars = validator.hasInvalidChars(value);
  const submittable = validator.isSubmittable(value);
  const validLength = validator.isLengthValid(value);
  const action = validator.trimValue(value) ? (
    submittable ? (
      <>
        <TickIcon
          data-testid="action-icon"
          className={style.ibanStatusIcon}
          variant={theme === "light" ? "green" : "blue"}
        />
        <p className={style.ibanStatusMessage} data-testid="action-message">
          {formatMessage("IBAN_valid_message")}
        </p>
      </>
    ) : hasInvalidChars ? (
      <>
        <MarkIcon
          className={style.ibanStatusIcon}
          variant={theme === "light" ? "red" : "yellow"}
          data-testid="action-icon"
        />
        <p className={style.ibanStatusMessage} data-testid="action-message">
          {formatMessage("IBAN_invalid_message")}
        </p>
      </>
    ) : validLength && !validator.exists(value) ? (
      <>
        <MarkIcon
          data-testid="action-icon"
          className={style.ibanStatusIcon}
          variant={theme === "light" ? "red" : "yellow"}
        />
        <p className={style.ibanStatusMessage} data-testid="action-message">
          {formatMessage("IBAN_does_not_exist_message")}
        </p>
      </>
    ) : null
  ) : null;
  return (
    <ValidatedInputText
      data-testid="iban-input"
      ref={myRef}
      value={value}
      label={formatMessage("IBAN_input_label") + "*"}
      focusedHint={focusedHint}
      onChange={onChange}
      validator={validator}
      // consider the white space when counting the length
      minLength={validator.getMinLength() + value.match(/\s/g)?.length}
      hideIcons={action}
      showErrorsWhenFocused={true}
      xIndicatorVariant={theme === "dark" ? "white" : "grey"}
      action={
        (action && {
          content: (
            <div
              className={style.ibanStatusContainer}
              data-testid="action-container"
            >
              {action}
            </div>
          ),
        }) ||
        null
      }
      required={required}
    />
  );
};

export default forwardRef(IBANInput);
