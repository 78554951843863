import createValidator from "./validator";

export default function createNationalityValidator(
  invalidErrorMessage,
  countryList
) {
  return createValidator(
    (value, focused, minLength, maxLength, required) => {
      let result;
      if (value.length > 0) {
        if (
          required &&
          Object.keys(countryList).find((k) => countryList[k] === value)
        ) {
          //found
        } else if (focused) {
          result = {
            messageId: invalidErrorMessage,
          };
        }
      }
      return result;
    },
    (value, minLength, maxLength, required) => {
      let result;
      result = required
        ? Object.keys(countryList).find((k) => countryList[k] === value)
        : value.length === 0;
      return result;
    }
  );
}
