import React, { useState } from "react";
import LoadingContext from "../contexts/loading-context";

export default function LoadingHandler(props) {
  const [loadingArray, setLoading] = useState([]);
  const setLoadingTag = (loading, tag) => {
    if (tag) {
      if (loading) {
        setLoading([...loadingArray, tag]);
      } else {
        setLoading(loadingArray.filter((value) => value !== tag));
      }
    } else {
      //should not use it, kill everything
      throw new Error("Please always use a tag when calling setLoading");
    }
  };
  return (
    <LoadingContext.Provider
      value={{
        loading: loadingArray.length > 0,
        setLoading: setLoadingTag,
      }}
    >
      {props.children}
    </LoadingContext.Provider>
  );
}
