import React from "react";

import { ReactComponent as WhiteIcon } from "../../images/exit-white.svg";
import { ReactComponent as RedIcon } from "../../images/exit-red.svg";
import { ReactComponent as GreyIcon } from "../../images/exit-grey.svg";
import ImageVariants from "../image-variants";

const XIndicator = (props) => {
  const variants = {
    white: WhiteIcon,
    grey: GreyIcon,
    default: RedIcon,
  };
  return <ImageVariants variants={variants} {...props} />;
};

export default XIndicator;
