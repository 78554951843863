import createDefaultTextValidator from "./default-text-validator";
import createValidator from "./validator";

export function createAccountHolderValidator(fullName) {
  const textValidator = createDefaultTextValidator(
    "Account_holder_name_too_short_characters_error_message",
    "Account_holder_invalid_characters_error_message",
    /^[\p{L}\d]{3,} [\p{L}\s\d ]{3,}$/u
  );

  return createValidator(
    (value, focused, minLength, maxLength, required, { fullName, overlay }) => {
      const defaultRes = textValidator.validationFunction(
        value,
        focused,
        minLength,
        maxLength,
        required
      );

      if (overlay) return;
      if (defaultRes) return defaultRes;
      else if (!focused && fullName !== value)
        return { messageId: "Account_holder_name_not_matching_input_error" };
      else return;
    },
    textValidator.submittableFunction,
    { fullName, overlay: false }
  );
}
