import React, { useState } from "react";
import { useContext } from "react";
import Helpers from "../components/helpers";
import Logger from "../components/logger/logger";
import EventsHelper from "../components/tracking/events-helper";
import { enableTracking } from "../components/tracking/instana";
import ConfigContext from "../contexts/config-context";
import { CookiesContext } from "../contexts/cookies-context";

export const CookiesHandler = (props) => {
  let defaultCookiesSetting = {
    "strictly-necessary": {
      enabled: true,
      cookieNames: [
        "sessionInfo",
        "newSubscriptionData",
        "networkProfileData",
        "cookies",
      ],
      default: true,
    },
    performance: { enabled: null, cookieNames: ["in-session"], default: false },
    functional: { enabled: null, cookieNames: [], default: false },
    targeting: { enabled: null, cookieNames: [], default: false },
  };
  const [stateCookies, setStateCookies] = useState(defaultCookiesSetting);
  const [showCookieBanner, setShowCookieBanner] = useState(true);
  const { config } = useContext(ConfigContext);

  const value = {
    cookieBannerVisible: showCookieBanner,
    cookies: stateCookies,
    setCookie(cookie, enable, save = false) {
      const availableCookies = Object.keys(value.cookies);

      if (cookie === "all")
        setStateCookies((prev) => {
          const res = availableCookies.reduce((cs, c) => {
            cs[c].enabled = enable || false;
            return cs;
          }, prev);
          res["strictly-necessary"].enabled = true;
          if (save) {
            value.save(res);
          }
          return res;
        });
      else if (availableCookies.includes(cookie))
        setStateCookies((prev) => {
          prev[cookie].enabled = enable || false;
          prev["strictly-necessary"].enabled = true;
          if (save) {
            value.save(prev);
          }
          return prev;
        });
    },
    save(cookies = value.cookies) {
      const cookiesToSet = {};
      Object.keys(cookies).forEach((key) => {
        const val = { ...cookies[key] };
        if (val.enabled === null) {
          val.enabled = val.default || false;
        }
        cookiesToSet[key] = val;
        Logger.d(
          `Setting cookie ${key}="${JSON.stringify(cookiesToSet[key])}"`
        );
      });
      Helpers.saveCookiesPreferences(cookiesToSet);
      value.showCookiesBanner(false);

      if (value.cookies.performance.enabled) {
        enableTracking(config);
        if (!Helpers.getSavedToken())
          EventsHelper.debugEvent("No token available");
      } else
        Logger.d(
          value.cookies.performance.cookieNames.join(", ") + " blocked by user"
        );
    },
    showCookiesBanner(enable) {
      setShowCookieBanner(enable);
    },
    isCookiesBannerVisible: () => showCookieBanner,
    hasCookies: (category) => !!value.cookies[category].cookieNames.length,
  };

  const shouldShowCookieBanner = !Helpers.getCookiesPreferences();
  if (showCookieBanner !== shouldShowCookieBanner) {
    setShowCookieBanner(shouldShowCookieBanner);
  }

  return (
    <CookiesContext.Provider value={value}>
      {props.children}
    </CookiesContext.Provider>
  );
};
