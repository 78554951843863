import React from "react";

export const CookiesContext = React.createContext({
  cookieBannerVisible: true,
  cookies: [],
  setCookie: () => {},
  showCookiesBanner: () => {},
  isCookiesBannerVisible: () => {},
  save: () => {},
  hasCookies: () => {},
});
