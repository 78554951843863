import ConfigHelper from "../../config/config-helper";
import Logger from "../logger/logger";
import CallHelper from "./call-helper";
import fetchHelper from "./fetch-helper";

export function generateOTPData(config, email) {
  return new Promise(async (resolve, reject) => {
    try {
      const call = CallHelper.createCall(
        config.network.endpoints.emailVerifier.generateOTP,
        "post",
        JSON.stringify({ email: email?.trim() })
      );
      const res = await fetchHelper(call.url, {
        method: call.method,
        cache: "no-cache",
        headers: call.headers,
        body: call.body,
      });
      if (res.status === 202) {
        //Succesful
        const json = await res.json();
        try {
          const n = Number(json.attemptsLeft);
          if (isNaN(n)) {
            throw new Error();
          }
        } catch (error) {
          //get default attemptsLeft
          const attemptsLeft =
            ConfigHelper.getVerifyOTPPredefinedAttemptsLeft();
          //do not crash for this, simply log the error
          if (ConfigHelper.isDevelopmentMode()) {
            Logger.w(
              "Invalid generate OTP API response, using default attemptsLeft: ",
              JSON.stringify(json),
              attemptsLeft
            );
          }
          json.attemptsLeft = attemptsLeft;
        }
        resolve(json);
      } else {
        const error = new Error("HTTP Error " + res?.status);
        reject(error);
      }
    } catch (error) {
      if (ConfigHelper.isDevelopmentMode()) {
        Logger.w("Error during call: ", error);
      }
      reject(error);
    }
  });
}

export function verifyOTPData(config, otp) {
  return new Promise(async (resolve, reject) => {
    try {
      const call = CallHelper.createCall(
        config.network.endpoints.emailVerifier.verifyOTP,
        "post",
        JSON.stringify({ otp })
      );
      const res = await fetchHelper(call.url, {
        method: call.method,
        cache: "no-cache",
        headers: call.headers,
        body: call.body,
      });
      //Successful, no body
      resolve(res.status);
    } catch (error) {
      if (ConfigHelper.isDevelopmentMode()) {
        Logger.w("Error during verify OTP call: ", error);
      }
      reject(error);
    }
  });
}
