import createDefaultTextValidator from "./default-text-validator";

const numberPartsRegex = /^(\+[0-9-]+) (.+)$/;
const validChars = /^[0-9- ]+$/;
const validFullNumber = /^\+[0-9]+\s{1}[0-9- ]+$/;

export default function createPhoneNumberValidator() {
  const validator = createDefaultTextValidator(
    "Phone_number_input_error_message",
    "Phone_number_input_error_message",
    validChars
  );
  validator.getPrefix = (value) => {
    return value ? value.match(numberPartsRegex)?.[1] : "";
  };
  validator.getNumber = (value) => {
    return value ? value.match(numberPartsRegex)?.[2] : "";
  };
  validator.checkFullNumber = (value) => {
    return validFullNumber.test(value);
  };
  return validator;
}
