import { useState } from "react";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  // useState,
} from "react";
import { CookiesContext } from "../contexts/cookies-context";
import { OverlayContext } from "../contexts/overlay-context";
import FormattedMsg from "../locale/components/formatted-msg";
import style from "../styles/cookies-banner.module.css";
import Button from "./button";
import { OnOffSwitch } from "./on-off-switch";

export const CookiesBanner = () => {
  const { setCookie, isCookiesBannerVisible, cookies, save } =
    useContext(CookiesContext);
  const { showOverlay } = useContext(OverlayContext);
  const cookiesRef = useRef({});
  const [, setTick] = useState(Date.now());
  const calculateTopOffset = useCallback((ref) => {
    if (ref && ref.clientHeight) {
      const pageYOffset = window.scrollY;
      //calculate top offset to be used
      const windowHeight = window.innerHeight;
      const innerTop = windowHeight - ref.clientHeight + pageYOffset;
      const maxTop = document.body.clientHeight - ref.clientHeight;
      const top = maxTop ? Math.min(innerTop, maxTop) : innerTop;
      if (top !== ref.style.top) {
        ref.style.top = `${top || 0}px`;
      }
    }
  }, []);

  useEffect(() => {
    const handleScrollEvent = () => {
      if (isCookiesBannerVisible()) {
        calculateTopOffset(cookiesRef?.current);
      }
    };
    const handleResizeEvent = () => {
      if (isCookiesBannerVisible()) {
        window.scrollTo(0, 0);
        calculateTopOffset(cookiesRef?.current);
      }
    };
    if (isCookiesBannerVisible()) {
      //should show and register scroll event
      window.addEventListener("scroll", handleScrollEvent);
      window.addEventListener("resize", handleResizeEvent);
      return () => {
        window.removeEventListener("scroll", handleScrollEvent);
        window.removeEventListener("resize", handleResizeEvent);
      };
    }
  }, [isCookiesBannerVisible, cookiesRef, calculateTopOffset]);

  useLayoutEffect(() => {
    if (isCookiesBannerVisible() && cookiesRef?.current) {
      calculateTopOffset(cookiesRef.current);
    }
  });

  if (isCookiesBannerVisible()) {
    //check if the ref should be calculated for the first time
    return (
      <div
        className={style.container}
        data-testid="cookies-banner-container"
        id="cookies-banner"
        ref={cookiesRef}
      >
        <div className={style.descriptionContainer}>
          <p className={style.description}>
            <FormattedMsg id="Cookie_bar_copy" />
          </p>
          <div className={style.cookiesSection}>
            <h2 className={style.cookiesSectionTitle}>
              <FormattedMsg id="Manage_consent_preferences_title" />
            </h2>
            <div className={style.cookieIndicatorContainer}>
              <p className={style.cookieName}>
                <FormattedMsg id="Strictly_necessary_cookies_link" />{" "}
                <span
                  className={style.readMore}
                  data-testid="read-more-strictly-necessary"
                  onClick={() => {
                    showOverlay("cookies-overlay", {
                      opened: "strictly-necessary",
                    });
                  }}
                >
                  <FormattedMsg id="Read_more_link" />
                </span>
              </p>
              <p className={style.cookieIndicator}>
                <FormattedMsg id="Always_active_indicator" />
              </p>
            </div>
            <div className={style.cookieIndicatorContainer}>
              <p className={style.cookieName}>
                <FormattedMsg id="Performance_cookies_link" />{" "}
                <span
                  className={style.readMore}
                  data-testid="read-more-performance"
                  onClick={() => {
                    showOverlay("cookies-overlay", {
                      opened: "performance",
                    });
                  }}
                >
                  <FormattedMsg id="Read_more_link" />
                </span>
              </p>
              <OnOffSwitch
                data-testid="performance-cookies-switch"
                value={
                  cookies?.performance.enabled ?? cookies?.performance.default
                }
                onChange={(value) => {
                  setCookie("performance", value);
                  setTick(Date.now());
                }}
              />
            </div>
          </div>
        </div>
        <div className={style.buttonsContainer}>
          <Button
            data-testid="reject-cookies-button"
            variant="secondary"
            className={style.secondaryButton}
            onClick={(e) => {
              setCookie("all", false, true);
              e.stopPropagation();
            }}
          >
            <FormattedMsg id="Reject_cookies_button" />
          </Button>
          <Button
            data-testid="accept-cookies-button"
            variant="primary"
            onClick={(e) => {
              setCookie("all", true, true);
              e.stopPropagation();
            }}
          >
            <FormattedMsg id="Accept_cookies_button" />
          </Button>
          <Button
            data-testid="save-settings-button"
            className={style.secondaryButton}
            onClick={(e) => {
              save();
              e.stopPropagation();
            }}
            variant="primary"
          >
            <FormattedMsg id="Save_settings_button" />
          </Button>
        </div>
      </div>
    );
  }
};
