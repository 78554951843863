import JasminePages from "../routes/jasmine-pages";
import SiegfriedPages from "../routes/siegfried-pages";

const Flows = {
  jas: {
    pages: JasminePages,
    loadTheme: () => {
      import("../styles/theme-jasmine.css").then();
    },
  },
  sf: {
    pages: SiegfriedPages,
    loadTheme: () => {
      import("../styles/theme-siegfried.css").then();
    },
  },
};

export default Flows;
