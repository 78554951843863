import ConfigHelper from "../../config/config-helper";
import Logger from "../logger/logger";
import CallHelper from "./call-helper";
import fetchHelper from "./fetch-helper";

export function subscribe(config, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        // Personal Information
        info: {
          firstName: data.personalInformation.firstName.trim(),
          lastName: data.personalInformation.lastName.trim(),
          birthday: new Date(data.personalInformation.dob).toLocaleDateString(
            "it",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }
          ),
          nationality: data.personalInformation.nationality,
        },
        // Home Address
        addresses: [
          {
            type: "home",
            firstName: data.personalInformation.firstName.trim(),
            lastName: data.personalInformation.lastName.trim(),
            postcode: data.homeAddress.postcode,
            city: data.homeAddress.city,
            street: data.homeAddress.street,
            number: data.homeAddress.houseNumber,
            country: "DE",
            secondLine: data.homeAddress.secondLine ?? "",
          },
          {
            type: "billing",
            firstName: data.personalInformation.firstName.trim(),
            lastName: data.personalInformation.lastName.trim(),
            postcode:
              data.homeAddress.invoice_postcode ?? data.homeAddress.postcode,
            city: data.homeAddress.invoice_city ?? data.homeAddress.city,
            street: data.homeAddress.invoice_street ?? data.homeAddress.street,
            number:
              data.homeAddress.invoice_houseNumber ??
              data.homeAddress.houseNumber,
            country: "DE",
            secondLine:
              data.homeAddress.invoice_secondLine ??
              data.homeAddress.secondLine ??
              "",
          },
        ],
        // Contact Details
        contacts: {
          email: data.contactDetails.email.trim(),
          phone: data.contactDetails.phoneNumber?.trim() ?? "",
        },
        indicators: {
          advertising: data.contactDetails.vodafoneThirdAdv ?? false,
          personalData: data.contactDetails.vodafoneDataPermission ?? false,
        },
        // Payment Details
        payment: {
          iban: data.paymentDetails.iban.value.replace(/\s/g, "").toUpperCase(),
          accountHolder:
            data.personalInformation.firstName.trim() +
            " " +
            data.personalInformation.lastName.trim(),
        },
        // Select Plan
        subscription: data.selectPlan.plan.pricePlanId,
        // Create Password
        password: data.createPassword.password,
      };
      const call = CallHelper.createCall(
        config.network.endpoints.subscriber.subscribe,
        "post",
        JSON.stringify(body)
      );
      const res = await fetchHelper(call.url, {
        method: call.method,
        cache: "no-cache",
        headers: call.headers,
        body: call.body,
      });
      if (res.status === 202) {
        //Succesful
        resolve({});
      } else {
        const error = new Error("HTTP Error " + res?.status);
        reject(error);
      }
    } catch (error) {
      if (ConfigHelper.isDevelopmentMode()) {
        Logger.w("Error during call: ", error);
      }
      reject(error);
    }
  });
}
