import React from "react";

import { ReactComponent as RedLogo } from "../../images/vf-logo-red.svg";
import { ReactComponent as WhiteLogo } from "../../images/vf-logo-white.svg";
import { ReactComponent as InvertedLogo } from "../../images/vf-logo-inverted.svg";
import ImageVariants from "../image-variants";

const VFLogo = (props) => {
  const variants = {
    white: WhiteLogo,
    inverted: InvertedLogo,
    default: RedLogo,
  };
  return <ImageVariants variants={variants} {...props} />;
};

export default VFLogo;
