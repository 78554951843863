import Card from "../../../components/card";
import NavBar from "../../../components/navbar";

import pageStyle from "../../../styles/page.module.css";
import Footer from "../../../components/footer";
import useNetwork from "../../../components/network/use-network";
import { useReducer } from "react";
import { useContext } from "react";
import ConfigContext from "../../../contexts/config-context";
import Button from "../../../components/button";
import { CallHandler } from "../../../components/call-handler";
import { ErrorPage } from "../newSubscription/errorPage";
import { testError } from "../../../components/network/test";

/**
 * Show a navbar, content div, footer and switch between Content and Suspense spinner
 * @returns error page test component
 */
export default function ErrorPageTest() {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { checkNetworkCall, startNetworkCall } = useNetwork();
  const { config } = useContext(ConfigContext);
  const startCall = (status) => {
    startNetworkCall(
      "errorPageTest",
      () => testError(config, status),
      false,
      false,
      forceUpdate
    );
    forceUpdate();
  };

  const errorCall = checkNetworkCall("errorPageTest", false, false);

  if (errorCall?.hasError()) {
    const error = errorCall.read();

    return <ErrorPage status={error.status} />;
  }
  return errorCall?.isPending() ? (
    <CallHandler call={errorCall} />
  ) : (
    <div className={pageStyle.page} data-testid="fetchTestPage">
      <NavBar />

      <div className={`${pageStyle.content}`}>
        <h2>Error page test</h2>
        <h3> Refresh the page to remove the error page</h3>
        <Card>
          <Button
            data-link-id="400-error-button"
            onClick={() => startCall(400)}
          >
            Error 400 (Bad Request)
          </Button>
          <Button
            data-link-id="401-error-button"
            onClick={() => startCall(401)}
          >
            Error 401 (Unauthorized User)
          </Button>
          <Button
            data-link-id="404-error-button"
            onClick={() => startCall(404)}
          >
            Error 404 (Page Not Found)
          </Button>
          <Button
            data-link-id="generic-error-button"
            onClick={() => startCall()}
          >
            Generic Error (418, I'm A Teapot)
          </Button>
          <Button
            data-link-id="generic-error-button"
            onClick={() => startCall(500)}
          >
            Error 500 (Internal Server Error)
          </Button>
        </Card>
      </div>
      <Footer />
    </div>
  );
}
