import Helpers from "../components/helpers";
import Logger from "../components/logger/logger";
import EventsHelper from "../components/tracking/events-helper";
import ConfigHelper from "../config/config-helper";

const BRIDGES = { mp: "MobilePlans", mpo: "MobilePlansInlineOperations" };
const FAKE_CALLBACKS_STATUS = "FakeOnenumberCallbacks";
const PROFILE_DOWNLOAD = "notifyProfileDownload";
const CANCEL = "notifyCanceledPurchase";
const CANCEL_BIS = "notifyCancelledPurchase";
const CREATE_PURCHASE_METADATA = "createPurchaseMetaData";
const PROFILE_ACTIVATION_COMPLETE_SCRIPT = "profileActivationCompleteScript";
const REGISTRATION_CHANGED_SCRIPT = "registrationChangedScript";
const PROFILE_ACTIVATION_COMPLETE_CALLBACK_NAME =
  "vfMpActivationCompletedCallback";
const REGISTRATION_CHANGED_CALLBACK_NAME = "vfMpRegistrationChangedCallback";
const MOBILEPLANS_EVENT = "MPEvent";

const URL_PAR_MARKET = "market";
const URL_PAR_LOCATION = "location";
const URL_PAR_EID = "eid";
const URL_PAR_ICCIDS = "iccids";
const URL_PAR_IMEI = "imei";
const URL_PAR_TRID = "transactionId";

export default class MobilePlans {
  static getMpBridge() {
    return this.getBridge("mp");
  }
  static getMpoBridge() {
    return this.getBridge("mpo");
  }

  static getBridge(key) {
    const BRIDGE = BRIDGES[key];
    if (BRIDGE) {
      let bridge = window[BRIDGE];
      Logger.d(
        `Tried to get bridge "${key}"("${BRIDGE}") using window: ${!!bridge}`
      );
      if (!bridge) {
        //try some exotic way to get it
        try {
          // eslint-disable-next-line
          bridge = key === "mp" ? MobilePlans : MobilePlansInlineOperations;
        } catch (error) {
          //skip this method and try an other one
        }
        Logger.d(
          `Tried to get bridge "${key}"("${BRIDGE}") using direct addressing: ${!!bridge}`
        );
        if (!bridge) {
          try {
            // eslint-disable-next-line no-undef
            bridge = global[BRIDGE];
          } catch (err) {
            //skip this method and try another one
          }
          Logger.d(
            `Tried to get bridge "${key}"("${BRIDGE}") using global object: ${!!bridge}`
          );
        }
        if (!bridge) {
          try {
            // eslint-disable-next-line
            bridge = eval(BRIDGE);
          } catch (err) {
            //cannot find bridge
          }
          Logger.d(
            `Tried to get bridge "${key}"("${BRIDGE}") using eval: ${!!bridge}`
          );
        }
      }
      return bridge;
    } else {
      Logger.w(
        `Something wrong, cannot find the specifications for bridge "${key}"("${BRIDGE}")`
      );
    }
  }

  static hasCallbacks() {
    const bridgeMp = this.getMpBridge();
    const bridgeMpo = this.getMpoBridge();
    const result =
      bridgeMp &&
      bridgeMpo &&
      (bridgeMp[CANCEL] || bridgeMp[CANCEL_BIS]) &&
      bridgeMpo[PROFILE_DOWNLOAD] &&
      bridgeMp[CREATE_PURCHASE_METADATA];
    //generate some debug information
    const debugMessage = [];
    debugMessage.push(
      `MobilePlans callback object ${BRIDGES.mp} ${
        bridgeMp ? "" : "NOT "
      }found.`
    );
    debugMessage.push(
      `MobilePlans callback object ${BRIDGES.mpo} ${
        bridgeMpo ? "" : "NOT "
      }found.`
    );
    debugMessage.push(
      `MobilePlans callback function ${BRIDGES.mp}.${CANCEL} ${
        bridgeMp?.[CANCEL] ? "" : "NOT "
      }found.`
    );
    debugMessage.push(
      `MobilePlans callback function ${BRIDGES.mp}.${CANCEL_BIS} ${
        bridgeMp?.[CANCEL_BIS] ? "" : "NOT "
      }found.`
    );
    debugMessage.push(
      `MobilePlans callback function ${
        BRIDGES.mp
      }.${CREATE_PURCHASE_METADATA} ${
        bridgeMp?.[CREATE_PURCHASE_METADATA] ? "" : "NOT "
      }found.`
    );
    debugMessage.push(
      `MobilePlans callback function ${BRIDGES.mpo}.${PROFILE_DOWNLOAD} ${
        bridgeMpo?.[PROFILE_DOWNLOAD] ? "" : "NOT "
      }found.`
    );
    const message = `MobilePlans hasCallbacks returns ${!!result}: ${debugMessage.join(
      "; "
    )}`;
    EventsHelper.debugEvent(message);
    Logger.d(message);
    //return the result
    return result;
  }

  static hasFakeCallbacks() {
    const bridgeMp = this.getMpBridge();
    const bridgeMpo = this.getMpoBridge();
    const result =
      this.hasCallbacks() &&
      bridgeMp &&
      bridgeMp[FAKE_CALLBACKS_STATUS] &&
      bridgeMpo &&
      bridgeMpo[FAKE_CALLBACKS_STATUS];
    Logger.d(
      `Tried to check if windows has FAKE callbacks, got ${JSON.stringify(
        bridgeMp
      )} and ${JSON.stringify(bridgeMpo)}, result = ${JSON.stringify(result)}`
    );
    return result;
  }

  static toggleSimulation(force) {
    if (!ConfigHelper.isCallbackSimulatingEnabled()) {
      EventsHelper.debugEvent(
        "Configuration prevented the toggleSimulation feature"
      );
    } else {
      EventsHelper.debugEvent(
        "Switching " + (force ? "ON" : "OFF") + " the callbacks simulation"
      );
    }
    if (!this.hasCallbacks() && force !== false) {
      Logger.d("Windows helper, exposing fake callbacks");
      window[BRIDGES.mp] = {};
      window[BRIDGES.mpo] = {};
      let bridgeMp = this.getMpBridge();
      let bridgeMpo = this.getMpoBridge();
      bridgeMp[CANCEL] = (...args) => {
        this.debugMessage(CANCEL, "should close the webview", ...args);
        this.toggleSimulation(false);
        window.location.assign("/");
      };
      bridgeMp[CANCEL_BIS] = (...args) => {
        this.debugMessage(CANCEL_BIS, "should close the webview", ...args);
        this.toggleSimulation(false);
        window.location.assign("/");
      };
      bridgeMp[CREATE_PURCHASE_METADATA] = () => {
        return {};
      };
      // assure the name is the same of the function stored in the window object
      bridgeMpo[PROFILE_DOWNLOAD] = (...args) => {
        this.debugMessage(PROFILE_DOWNLOAD, "", ...args);
        setTimeout(
          () =>
            //try to call the activation callback
            window[bridgeMpo[PROFILE_ACTIVATION_COMPLETE_SCRIPT]](
              JSON.stringify({
                activationResult: "success",
                iccid: "1234567890",
              })
            ),
          2000
        );
      };
      bridgeMp[FAKE_CALLBACKS_STATUS] = true;
      bridgeMpo[FAKE_CALLBACKS_STATUS] = true;

      window.MobilePlansUserAccount = {
        new: "MobilePlansUserAccount.new",
      };
      window.MobilePlansPurchaseInstrument = {
        new: "MobilePlansPurchaseInstrument.new",
      };
      window.MobilePlansLineType = {
        new: "MobilePlansLineType.new",
        bailed: "MobilePlansLineType.bailed",
      };
      window.MobilePlansMoDirectStatus = {
        complete: "MobilePlansMoDirectStatus.complete",
        cancelled: "MobilePlansLineType.bailed",
      };
    } else if (this.hasFakeCallbacks() && force !== true) {
      Logger.d("Windows helper, removing fake callbacks (if any)");
      window[BRIDGES.mp] = undefined;
      window[BRIDGES.mpo] = undefined;
      window.MobilePlansUserAccount = undefined;
      window.MobilePlansPurchaseInstrument = undefined;
      window.MobilePlansLineType = undefined;
      window.MobilePlansMoDirectStatus = undefined;
    }
    Logger.d(
      `Windows Helper, callbacks are: ${JSON.stringify(
        window[BRIDGES.mp]
      )} ${JSON.stringify(window[BRIDGES.mpo])}`
    );
  }

  static cancelPurchase() {
    const purchaseMetaData = this.getPurchaseMetadata(false);
    if (this.getMpBridge()?.[CANCEL_BIS]) {
      Logger.d(
        `Trying to call windows callback ${
          BRIDGES.mp
        }.${CANCEL_BIS}  with params (${JSON.stringify(purchaseMetaData)})`
      );
      this.getMpBridge()[CANCEL_BIS](purchaseMetaData);
    } else {
      Logger.d(
        `Trying to call windows callback ${
          BRIDGES.mp
        }.${CANCEL}  with params (${JSON.stringify(purchaseMetaData)})`
      );
      this.getMpBridge()[CANCEL](purchaseMetaData);
    }
  }

  static notifyPurchase(activationCode, planName = "Vodafone OneNumber") {
    const purchaseMetaData = this.getPurchaseMetadata(true);
    purchaseMetaData.planName = planName;
    const message = `Trying to call windows callback ${
      BRIDGES.mpo
    }.${PROFILE_DOWNLOAD} with params (${JSON.stringify(
      purchaseMetaData
    )}, ${JSON.stringify(activationCode)})`;
    EventsHelper.debugEvent(message);
    Logger.d(message);

    //expose profile activation callback
    window[PROFILE_ACTIVATION_COMPLETE_CALLBACK_NAME] = function (
      activationArgs
    ) {
      EventsHelper.debugEvent(
        "Received activation callback with " + JSON.stringify(activationArgs)
      );
      const { activationResult, iccid } = JSON.parse(activationArgs);
      if (activationResult?.toLowerCase?.() !== "success") {
        Logger.d(`Received error in ${PROFILE_DOWNLOAD}`, {
          activationResult,
          iccid,
        });
      } else {
        Logger.d("Profile activation completed", {
          activationResult,
          iccid,
        });
      }

      const event = new CustomEvent(MOBILEPLANS_EVENT, {
        detail: { activationResult, iccid },
      });

      window.dispatchEvent(event);
    };

    EventsHelper.debugEvent(
      "Registering activation callback with name " +
        PROFILE_ACTIVATION_COMPLETE_CALLBACK_NAME +
        ": " +
        !!window[PROFILE_ACTIVATION_COMPLETE_CALLBACK_NAME]
    );

    //expose registration changed callback for debug events
    window[REGISTRATION_CHANGED_CALLBACK_NAME] = function (registrationArgs) {
      EventsHelper.debugEvent(
        "Received registration changed callback with " +
          JSON.stringify(registrationArgs)
      );
      const { networkRegistrationState, iccid } = JSON.parse(registrationArgs);
      EventsHelper.debugEvent(
        `NetworkRegistration for iccid "${iccid}" changed to "${networkRegistrationState}"`
      );
    };
    const mpo = this.getMpoBridge();
    mpo[PROFILE_ACTIVATION_COMPLETE_SCRIPT] =
      PROFILE_ACTIVATION_COMPLETE_CALLBACK_NAME;
    mpo[REGISTRATION_CHANGED_SCRIPT] = REGISTRATION_CHANGED_CALLBACK_NAME;

    EventsHelper.debugEvent(
      "Triggering installation by calling " +
        BRIDGES.mpo +
        "." +
        PROFILE_DOWNLOAD +
        "..."
    );
    this.getMpoBridge()[PROFILE_DOWNLOAD](purchaseMetaData, activationCode);
  }

  /**
   * Check if the url is pointing to the windows path
   * @param {string} url
   */
  static checkWindowsPath(url) {
    // const path = url?.split("?")[0];
    // const pathMatcher = new RegExp(
    //   "/" + RoutingConfiguration.getWindowsPath() + "/"
    // );
    // return pathMatcher.test(path);
    return true;
  }

  static checkUrlParametersBeforeLogin(url) {
    //check that the pars are ok.
    //NOTE: iccids is optional, eid is optional for us because it should be handled in the flow
    //NOTE: mandatory ones are country, imei and transaction id
    const market = Helpers.getUrlParameter(URL_PAR_MARKET, url);
    const location = Helpers.getUrlParameter(URL_PAR_LOCATION, url);
    const imei = Helpers.getUrlParameter(URL_PAR_IMEI, url);
    const transactionId = Helpers.getUrlParameter(URL_PAR_TRID, url);
    const eid = Helpers.getUrlParameter(URL_PAR_EID, url);
    const iccids = Helpers.getUrlParameter(URL_PAR_ICCIDS, url);

    const result = !!(imei && location && market && transactionId);
    Logger.v(
      "Windows helper checking URL parameters...",
      url || window.location.toString(),
      JSON.stringify({
        eid,
        iccids,
        imei,
        location,
        market,
        transactionId,
        result,
      })
    );
    return result;
  }

  static getPrimaryDelay() {
    let val = 30;
    let result;
    try {
      val = Number(val);
      if (val && !isNaN(val)) {
        result = val;
      }
    } catch (error) {
      //do nothing
    }
    return result;
  }

  static getSecondaryDelay() {
    let val = 60;
    let result;
    try {
      val = Number(val);
      if (val && !isNaN(val)) {
        result = val;
      }
    } catch (error) {
      //do nothing
    }
    return result;
  }

  static getTertiaryDelay() {
    let val = 15;
    let result;
    try {
      val = Number(val);
      if (val && !isNaN(val)) {
        result = val;
      }
    } catch (error) {
      //do nothing
    }
    return result;
  }

  static getPurchaseDelay(countryOrToken) {
    let val = 15;
    let result;
    try {
      val = Number(val);
      if (val && !isNaN(val)) {
        result = val;
      }
    } catch (error) {
      //do nothing
    }
    return result;
  }

  static debugMessage(method, additionalText, ...params) {
    //create debug message
    const text = `DEBUG FAKE WINDOWS: ${method} called with ${
      params ? JSON.stringify(params) : "no parameters"
    }${additionalText ? ", " + additionalText : ""}.`;
    Logger.d(text);
    window.alert(text);
  }

  /**
   * Returns the correct purchase metadata to be used in the callbacks.
   * Based on our HLD, this will ALWAYS be the same one
   */
  static getPurchaseMetadata(purchase) {
    const bridgeMp = this.getMpBridge();
    const purchaseMetaData = bridgeMp[CREATE_PURCHASE_METADATA]();
    /* eslint-disable no-undef */
    if (purchase) {
      //Create metadata object to be used after succesful purchase
      // purchaseMetaData.userAccount = "Existing";
      // purchaseMetaData.purchaseInstrument = "Existing";
      // purchaseMetaData.line = "New";
      // purchaseMetaData.moDirectStatus = "Complete";
      purchaseMetaData.userAccount = MobilePlansUserAccount.new;
      purchaseMetaData.purchaseInstrument = MobilePlansPurchaseInstrument.new;
      purchaseMetaData.lineType = MobilePlansLineType.new;
      purchaseMetaData.modirectStatus = MobilePlansMoDirectStatus.complete;
      purchaseMetaData.planName = "Vodafone OneNumber";
    } else {
      //Cancelled purchase
      purchaseMetaData.userAccount = MobilePlansUserAccount.bailed;
      purchaseMetaData.purchaseInstrument =
        MobilePlansPurchaseInstrument.bailed;
      purchaseMetaData.modirectStatus = MobilePlansMoDirectStatus.complete;
      purchaseMetaData.lineType = MobilePlansLineType.bailed;
      purchaseMetaData.planName = "";
    }
    /* eslint-enable no-undef */
    return purchaseMetaData;
  }

  static copyAddressToClipboard(getMessage) {
    //get the address
    const link = getMessage("windows-copy-link");
    // Helper.copyToClipboard(link);
    return link;
  }

  //REFERENCE: SAMPLE CODE FROM MICROSOFT AS TESTED IN ON 72.9
  /* eslint-disable no-irregular-whitespace */
  //     if (typeof MobilePlans == "undefined") {
  //                   console.log('mobile plans undefined');
  //                   log('mobile plans undefined');
  //         }
  //         //var timer = setInterval('getSIMProfile()',5000);
  //         var btn = document.getElementById("installbtn");
  //         btn.onclick = function(){
  //             getSIMProfile();
  //         }
  //
  //         function getSIMProfile() {
  //             //clearInterval(timer);
  //             installProfile(**********************************REPLACE WITH VALID ACTIVATION CODE**************);
  //         }
  //         function installProfile(profile) {
  //             var purchaseMetaData = MobilePlans.createPurchaseMetaData();
  //             purchaseMetaData.userAccount = MobilePlansUserAccount.new;
  //             purchaseMetaData.purchaseInstrument = MobilePlansPurchaseInstrument.new;
  //             purchaseMetaData.lineType = MobilePlansLineType.new;
  //             purchaseMetaData.modirectStatus = MobilePlansMoDirectStatus.complete;
  //             purchaseMetaData.planName = "TravelfyPlan";
  //             //MobilePlansInlineOperations.registrationChangedScript = "onRegistrationChanged";
  //             MobilePlansInlineOperations.profileActivationCompleteScript = "onActivationComplete";
  //             MobilePlansInlineOperations.notifyProfileDownload(purchaseMetaData, profile);
  //         }
  //         function onActivationComplete(activationArgs) {
  //             var activationObj = JSON.parse(activationArgs);
  //             console.log(activationObj);
  //
  //             if(activationObj.activationResult == MobilePlansActivationError.success)
  //                 Log('Activation Success');
  //         }
  /* eslint-enable no-irregular-whitespace */
}
