import { useContext } from "react";
import Button from "../components/button";
import LoadingSpinner from "../components/loading-spinner";
import { ThemeContext } from "../contexts/theme-context";
import style from "../styles/loading-spinner.module.css";

export default function FullPageLoading({ testPage }) {
  const { theme, setTheme } = useContext(ThemeContext);
  return (
    <div
      className={style.fullPageBackground}
      data-testid={testPage ? "testLoadingPage" : "fullpageSpinner"}
    >
      {/* <LoadingSpinner variant={theme === "dark" ? "white" : "red"} /> 
      Always use red spinner as per specifications on zeplin */}
      <LoadingSpinner variant={"red"} />
      {testPage && (
        <Button
          style={{ visibility: "hidden" }}
          onClick={() => {
            setTheme(theme === "dark" ? "light" : "dark");
          }}
          data-link-id="switch-theme"
          data-testid="switch-theme"
        />
      )}
    </div>
  );
}
