import Logger from "../logger/logger";

/**
 * Wraps a fetch call to check for result.ok
 * @param  {...any} args args to be passed to fetch
 * @returns
 * @throws an error from fetch OR an error containing the result properties if !result.ok
 */
export default async function fetchHelper(...args) {
  //do stuff
  Logger.v("Starting fetch...", ...args);
  let result = null;
  try {
    result = await fetch(...args);
  } catch (err) {
    if (err.name === "TypeError") err.name = "NetworkError";
    Logger.v("Error running fetch: ", err.toString());
    throw err;
  }
  Logger.v("Fetch result: ", { ok: result?.ok, status: result?.status });
  if (result?.ok) {
    Logger.v("Fetch call was ok...", ...args, result);
    return result;
  } else {
    const error = new Error(
      `Error during fetch ${result?.status}: ${result?.statusText || "N/A"}`
    );
    error.status = result?.status;
    error.statusText = result?.statusText;
    error.ok = result?.ok;
    try {
      error.body = await result.json();
    } catch (e) {
      try {
        error.body = await result.text();
      } catch (e) {}
    }
    Logger.v(
      "Fetch returned a non-ok result...",
      error.message,
      error.status,
      error.ok,
      error.statusText,
      error.body
    );
    throw error;
  }
}
