import Button from "./button";

export default function DownloadButton(props) {
  const { href, download, ...rest } = props;

  // Use target for EdgeHTML compatibility
  return (
    <Button href={href} download={download || true} target="_self" {...rest} />
  );
}
