import { useContext } from "react";
import FlowContext from "../../../contexts/flow-context";
import { ErrorPage as JasmineErrorPage } from "../../jasmine/newSubscription/errorPage";
import { ErrorPage as SiegfriedErrorPage } from "../../siegfried/newSubscription/errorPage";

export function ErrorPage({ children, ...props }) {
  const { flow } = useContext(FlowContext);
  const errorPages = {
    jas: <JasmineErrorPage {...props}>{children}</JasmineErrorPage>,
    sf: <SiegfriedErrorPage {...props}>{children}</SiegfriedErrorPage>,
  };

  return errorPages[flow] ?? errorPages.sf;
}
